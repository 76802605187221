import round from '../helpers/round'

const values = {
  mobile: 375,
  desktop: 1440
}

export const vw = (size, mockup = 'mobile') => {
  return `${round((size / values[mockup]) * 100, 4)}vw`
}
