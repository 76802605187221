import inDOM from 'dom-helpers/canUseDOM'
import get from 'lodash/get'
import defer from 'lodash/defer'
import { routeContent } from '../actions'
import { ActionType } from 'redux-promise-middleware'
import isNumber from 'lodash/isNumber'
import { pageContentActionCreator } from '../slices/content/page'
// import { updateScroll } from 'redux-first-router'
// import { setPayYOffset } from '../../components/useSmoothScroll'

let scrollTo

export default store => next => action => {
  if (inDOM) {
    if (action.type === routeContent.toString()) {
      scrollTo = get(action, ['meta', 'scrollTo'])
    } else if (action.type === `${pageContentActionCreator}_${ActionType.Fulfilled}`) {
      if (scrollTo || scrollTo === 0) {
        defer(() => {
          const offset = isNumber(scrollTo) ? scrollTo : get(document.querySelectorAll(scrollTo), [0, 'offsetTop'], 0)
          window.scrollTo({
            top: offset,
            left: 0,
            behavior: 'smooth'
          })
        })
      } else {
        // We will do the scroll update in the page after the transition]
        // updateScroll()
        // setPayYOffset(window.pageYOffset)
      }
    }
  }
  return next(action)
}
