import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import RichText from '../RichText'
import theme from '../../style/theme'
import { sliceMarginStyles, span } from '../../style/span'

const RichTextSlice = forwardRef(({ className, slice, tag = 'div', serializers }, ref) => {
  const { text, columns } = slice
  const classes = useStyles()

  return (
    <section className={cn(classes.content, className)}>
      <RichText ref={ref} content={text} tag={tag} serializers={serializers} columns={columns} />
    </section>
  )
})

const useStyles = createUseStyles({
  content: {
    extend: [sliceMarginStyles],
    margin: [0, 'auto'],
    padding: [0, span(1)],
    [theme.breakpoints.up('md')]: {
      padding: [0, span(3, 'md')],
      maxWidth: span(22, 'md'),
      width: '100%',
      marginLeft: 0
    }
  }
}, { name: 'RichTextSlice' })

export default RichTextSlice
