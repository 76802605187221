import React, { useState, useCallback } from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { getAllowedPostcodes } from '../../redux/slices/content'
import theme from '../../style/theme'
import { vw } from '../../style/vw'

const PostcodeValidator = ({ isValid, setValid }) => {
  const classes = useStyles()
  const [postcode, setPostcode] = useState('')
  const allowedPostcodes = useSelector(getAllowedPostcodes).replace(/\s/g, '').split(',')

  const stopDefault = useCallback((e) => { e.preventDefault() }, [])

  const onChange = useCallback((e) => {
    const inputValue = e.target.value
    setPostcode(inputValue)
    if (inputValue.length >= 4 && allowedPostcodes.includes(inputValue)) {
      setValid(true)
    } else {
      if (isValid) {
        setValid(false)
      }
    }
  }, [isValid, allowedPostcodes, setValid])

  let validationText = 'Please enter your postcode to confirm that we can deliver to your area.'
  let validationClass = ''
  if (postcode.length >= 4) {
    if (isValid) {
      validationText = 'Good news! We can deliver to your area.'
      validationClass = 'valid'
    } else {
      validationText = 'Unfortunately we are unable to deliver to your area. Check back soon!'
      validationClass = 'invalid'
    }
  }

  return (
    <form className={cn(classes.form, validationClass)} onSubmit={stopDefault}>
      <div className={classes.inputWrapper}>
        <label htmlFor='postcode' className='srOnly'>Postcode</label>
        <input
          id='postcode'
          className={classes.input}
          required
          name='postcode'
          type='text'
          placeholder='2000'
          maxLength='4'
          value={postcode}
          onChange={onChange}
        />
      </div>
      <div className={classes.validationText}>
        {validationText}
      </div>
    </form>
  )
}

const useStyles = createUseStyles({
  form: {
    minHeight: 56,
    display: 'flex',
    alignItems: 'stretch',
    borderColor: theme.colors.primary,
    color: theme.colors.primary,
    border: [1, 'solid', theme.colors.primary],
    '&.valid': {
      borderColor: theme.colors.success,
      color: theme.colors.success,
      '& input': {
        borderColor: theme.colors.success,
        color: theme.colors.success
      }
    },
    '&.invalid': {
      borderColor: theme.colors.error,
      color: theme.colors.error,
      '& input': {
        borderColor: theme.colors.error,
        color: theme.colors.error
      }
    }
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  input: {
    display: 'block',
    border: 'none',
    backgroundImage: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    fontFamily: theme.fonts.body,
    fontWeight: theme.fonts.bodyFontWeight,
    lineHeight: 1,
    borderRight: [1, 'solid'],
    padding: [vw(10), vw(18)],
    fontSize: vw(18),
    width: vw(80),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(18, 'desktop'),
      width: vw(80, 'desktop'),
      padding: [vw(10, 'desktop'), vw(18, 'desktop')]
    },
    '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus &:-webkit-autofill, &:-webkit-autofill:hover &:-webkit-autofill:focus': {
      textFillColor: theme.colors.primary,
      boxShadow: '0 0 0px 1000px transparent inset',
      transition: 'background-color 5000s ease-in-out 0s',
      caretColor: theme.colors.primary
    },
    '&:focus, &:active': {
      outline: 'none'
    }
  },
  validationText: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    lineHeight: 1.5,
    padding: [10, 20],
    fontSize: vw(12),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(12, 'desktop')
    }
  }
}, { name: 'PostcodeValidator' })

export default PostcodeValidator
