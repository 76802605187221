import React, { useState, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { formatPrice } from '../../../helpers/format'

const useStyles = createUseStyles({
  h2: {
    fontSize: 30,
    marginTop: 60,
    lineHeight: 40
  },
  dfWidgetBox: {
    padding: 0,
    width: 500,
    '& h3': {
      margin: 0,
      fontWeight: 700
    },
    '& .active': {
      background: '#38524F',
      '& h3, & span': {
        color: '#fff'
      }
    }
  },
  dfSubsSection: {
    padding: 20,
    border: '1px solid #38524F',
    background: '#F1EFEA',
    cursor: 'pointer',
    position: 'relative',
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
    '& h3': {
      fontSize: '1.5vw',
      color: '#38524F'
    },
    dfUl: {
      ul: {
        fontSize: 15,
        paddingLeft: 20,
        lineHeight: 25,
        fontWeight: 300,
        color: '#38524F',
        textTransform: 'uppercase'
      }
    },
    '& span': {
      fontWeight: 700,
      fontFamily: 'Morion'
    },
    '& ul': {
      color: '#38524F',
      paddingLeft: 20,
      textTransform: 'uppercase',
      fontSize: '0.9722vw'
    },
    '&.active ul': {
      color: '#fff',
      paddingLeft: 20,
      textTransform: 'uppercase',
      fontSize: '0.9722vw'
    },
    '&.active h3': {
      color: '#fff'
    }
  },
  dfOneTime: {
    padding: 20,
    background: '#F1EFEA',
    color: '#38524F',
    cursor: 'pointer',
    position: 'relative',
    border: '1px solid #38524F',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
    '& h3': {
      fontSize: '1.5vw',
      color: '#38524F'
    },
    '& span': {
      position: 'absolute',
      top: 0,
      right: 0,
      padding: '14px 20px',
      fontWeight: 700,
      fontFamily: 'Morion',
      lineHeight: '2.2vw'
    }
  },
  dfAddToCart: {
    '& button': {
      border: '1px solid #38524F',
      background: '#fff',
      padding: '8px 30px',
      marginTop: 20,
      fontSize: 18,
      textTransform: 'uppercase',
      color: '#38524F'
    }
  },
  dfProductWrapper: {
    marginBottom: 40,
    regPrice: {
      right: 0,
      textDecoration: 'line-through',
      fontSize: 15
    }
  },
  regPrice: {
    position: 'absolute',
    fontSize: 15,
    top: 20,
    textDecoration: 'line-through',
    right: 20
  },
  discountPrice: {
    position: 'absolute',
    fontSize: 25,
    top: 12,
    right: 85
  },
  frequencySelect: {
    borderRadius: 0,
    border: '0px solid #fff',
    padding: '10px 20px 10px 20px',
    background: '#f5e6e1',
    color: '#38524F',
    marginTop: '20px',
    fontSize: '0.9722vw',
    textTransform: 'uppercase',
    outline: 'none'
  },
  '@media (min-width: 1081px) and (max-width: 1200px)': {
    dfWidgetBox: {
      width: 410
    }
  },
  '@media (max-width: 1080px)': {
    frequencySelect: {
      fontSize: '15px'
    },
    dfSubsSection: {
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      '& h3': {
        fontSize: '27px'
      },
      '&.active ul': {
        fontSize: '15px'
      },
      '& ul': {
        fontSize: '15px'
      }
    },
    dfOneTime: {
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
      '& h3': {
        fontSize: '27px',
        color: '#38524F'
      },
      '& ul': {
        fontSize: '15px'
      },
      '& span': {
        position: 'absolute',
        top: 0,
        right: 0,
        padding: '23px 20px',
        fontWeight: 700,
        fontFamily: 'Morion',
        lineHeight: '2.2vw'
      }
    },
    dfWidgetBox: {
      padding: 0,
      width: '100%'
    },
    discountPrice: {
      position: 'relative',
      fontSize: 25,
      top: 0,
      right: '0px'
    }
  },
  '@media (max-width: 850px)': {
    dfOneTime: {
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
      '& h3': {
        fontSize: '27px',
        color: '#38524F'
      },
      '& ul': {
        fontSize: '15px'
      },
      '& span': {
        position: 'absolute',
        top: 0,
        right: 0,
        padding: '28px 20px',
        fontWeight: 700,
        fontFamily: 'Morion',
        lineHeight: '2.2vw'
      },
      frequencySelect: {
        fontSize: '20px'
      }
    },
    dfSubsSection: {
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      '& h3': {
        fontSize: '27px'
      },
      '&.active ul': {
        fontSize: '20px'
      },
      '& ul': {
        fontSize: '20px'
      }
    }
  },
  '@media (max-width: 575px)': {
    frequencySelect: {
      fontSize: '15px'
    },
    dfSubsSection: {
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      '& h3': {
        fontSize: '27px'
      },
      '&.active ul': {
        fontSize: '15px'
      },
      '& ul': {
        fontSize: '15px'
      }
    },
    dfOneTime: {
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
      '& h3': {
        fontSize: '27px',
        color: '#38524F'
      },
      '& ul': {
        fontSize: '15px'
      },
      '& span': {
        lineHeight: '22px'
      }
    },
    dfWidgetBox: {
      padding: 0,
      width: '100%'
    },
    discountPrice: {
      position: 'relative',
      fontSize: 25,
      top: 0,
      right: '0px'
    },
    regPrice: {
      top: 28,
      right: 20
    }
  },
  '@media (max-width: 340px)': {
    dfSubsSection: {
      '& h3': {
        fontSize: '20px'
      }
    },
    regPrice: {
      top: 20
    }
  }
})

function calculateDiscountedPrice (basePrice, planDescription) {
  const discountType = planDescription.split('=')[0]
  const discountValue = parseFloat(planDescription.split('=')[1])

  switch (discountType) {
    case 'PERCENTAGE':
      return basePrice - (basePrice * (discountValue / 100))
    case 'FIXED_PRICE':
      return discountValue
    case 'AMOUNT_OFF':
      return basePrice - discountValue
    default:
      return basePrice
  }
}

function SubscriptionWidget ({ onSelectionChange, price, sellingPlans }) {
  const classes = useStyles()
  const [isSubscription, setIsSubscription] = useState(true)
  const [frequency, setFrequency] = useState('Weekly')
  const subsSectionRef = useRef(null)
  const [discountedPrice, setDiscountedPrice] = useState(price)

  const updateVariantSelection = (sellingPlanId) => {
    onSelectionChange(sellingPlanId)
  }

  const handleSubscriptionChange = (subscribed) => {
    setIsSubscription(subscribed)
    const sellingPlanId = subscribed ? sellingPlans.find(plan => plan.name === frequency)?.id.split('/').pop() : null
    updateVariantSelection(sellingPlanId)
  }

  const handleFrequencyChange = (event) => {
    const newFrequency = event.target.value
    setFrequency(newFrequency)

    const sellingPlanId = isSubscription ? sellingPlans.find(plan => plan.name === newFrequency)?.id.split('/').pop() : null
    updateVariantSelection(sellingPlanId)

    const selectedPlan = sellingPlans.find(plan => plan.name === newFrequency)
    if (selectedPlan) {
      const newDiscountedPrice = calculateDiscountedPrice(price, selectedPlan.description)
      setDiscountedPrice(newDiscountedPrice)
    }
  }

  useEffect(() => {
    // Initialize discounted price on component mount
    if (sellingPlans.length > 0) {
      const initialPlan = sellingPlans[0]
      setDiscountedPrice(calculateDiscountedPrice(price, initialPlan.description))
    }
  }, [sellingPlans, price])

  useEffect(() => {
    // Check if the subscription section exists and selling plans are available
    if (subsSectionRef.current && sellingPlans.length > 0) {
      // Programmatically click the subscription section
      subsSectionRef.current.click()

      // Set the initial selling plan ID based on the default frequency ('Weekly')
      const initialPlanId = sellingPlans.find(plan => plan.name === frequency)?.id.split('/').pop()
      updateVariantSelection(initialPlanId)
    }
  }, [sellingPlans])

  if (!sellingPlans.length) {
    // console.log('Selling Plans in Widget: ', sellingPlans)
    return null
  }

  return (
    <div className={classes.dfProductWrapper}>
      <div className={classes.dfWidgetBox}>
        <div
          ref={subsSectionRef} className={`${classes.dfSubsSection} ${isSubscription ? 'active' : ''}`}
          onClick={() => handleSubscriptionChange(true)}
        >
          <h3>Subscribe + Save</h3>
          <span className={classes.regPrice}>{formatPrice(price)}</span>
          <span className={classes.discountPrice}>${discountedPrice}</span>
          <div className={classes.df_freq}>
            <select onChange={handleFrequencyChange} value={frequency} className={classes.frequencySelect}>
              {sellingPlans.map(plan => (
                <option key={plan.id} value={plan.name}>{plan.name}</option>
              ))}
            </select>
          </div>
          <div className={classes.dfUl}>
            <ul>
              <li>No commitment, cancel anytime</li>
              <li>Free shipping Australia wide</li>
              <li>100% money back guarantee</li>
            </ul>
          </div>
        </div>
        <div
          className={`${classes.dfOneTime} ${!isSubscription ? 'active' : ''}`}
          onClick={() => handleSubscriptionChange(false)}
        >
          <h3>Buy One Time</h3>
          <span>{formatPrice(price)}</span>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionWidget
