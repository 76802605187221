import { oneLineTrim } from 'common-tags'

export const unquotedBodyFace = 'Questa Sans'
export const bodyFace = `"${unquotedBodyFace}"`
export const body = `${bodyFace}, "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif`
export const bodyFontWeight = 350

export const unquotedHeadingsFace = 'Morion'
export const headingsFace = `"${unquotedHeadingsFace}"`
export const headings = `${headingsFace}, "Arial Narrow", Arial, sans-serif`
export const headingsFontWeight = 700

export const unquotedScriptFace = 'Jimmy Script'
export const scriptFace = `"${unquotedScriptFace}"`
export const script = `${scriptFace}, sans-serif`

export const preload = [
  require('./questa-sans-regular.woff2'),
  require('./morion-bold.woff2'),
  require('./morion-regular.woff2'),
  require('./jimmy-script-regular.woff2')
].filter(item => !item.startsWith('data:'))

export const faces = [{
  fontFamily: scriptFace,
  src: oneLineTrim`
    url('${require('./jimmy-script-regular.woff2')}') format('woff2'),
    url('${require('./jimmy-script-regular.woff')}') format('woff')`,
  fontWeight: 'normal',
  fontStyle: 'normal'
}, {
  fontFamily: bodyFace,
  src: oneLineTrim`
    url('${require('./questa-sans-regular.woff2')}') format('woff2'),
    url('${require('./questa-sans-regular.woff')}') format('woff')`,
  fontWeight: 'normal',
  fontStyle: 'normal'
}, {
  fontFamily: headingsFace,
  src: oneLineTrim`
    url('${require('./morion-bold.woff2')}') format('woff2'),
    url('${require('./morion-bold.woff')}') format('woff')`,
  fontWeight: headingsFontWeight,
  fontStyle: 'normal'
}, {
  fontFamily: headingsFace,
  src: oneLineTrim`
    url('${require('./morion-regular.woff2')}') format('woff2'),
    url('${require('./morion-regular.woff')}') format('woff')`,
  fontWeight: 'normal',
  fontStyle: 'normal'
}]
