import React, { forwardRef, useEffect, useRef, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import map from 'lodash/map'
import find from 'lodash/find'
import { getAnchorPoints } from '../../redux/slices/content'
import { useSelector } from 'react-redux'
import theme from '../../style/theme'
import { vw } from '../../style/vw'
import { span } from '../../style/span'
import { getPageYOffset, scrollbar } from '../useSmoothScrollbar'
import forEach from 'lodash/forEach'
import position from 'dom-helpers/position'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import detectIt from 'detect-it'
import last from 'lodash/last'
import useThrottle from '../../hooks/useThrottle'
import useWindowResize from '../../hooks/useWindowResize'
import gsap from 'gsap'

const indicatorOffset = 200

const ScrollIndicator = forwardRef(({ className }, ref) => {
  const classes = useStyles()
  const anchorPoints = useSelector(getAnchorPoints)
  const progressRef = useRef()
  const sections = useRef()
  const titleRef = useRef()

  const setupSection = useCallback(() => {
    sections.current = []
    if (isEmpty(anchorPoints) || detectIt.primaryInput === 'touch') {
      return
    }
    const elements = document.querySelectorAll(map(anchorPoints, ap => `#${ap.anchor_id}`).join(','))
    const topOffset = position(elements[0]).top
    forEach(anchorPoints, (ap, i) => {
      const element = elements[i]
      const { top } = position(element)
      sections.current.push({
        index: sections.current.length,
        id: ap.anchor_id,
        title: ap.title,
        pageNumber: ap.page_number,
        top: top - topOffset - (i === 0 ? 0 : indicatorOffset)
      })
    })
    forEach(sections.current, (section, i) => {
      section.bottom = get(sections.current[i + 1], ['top'], 0)
    })
  }, [anchorPoints])

  useEffect(() => {
    setupSection()
  }, [setupSection])

  useWindowResize(useThrottle(setupSection, 200, {}, [anchorPoints]))

  useEffect(() => {
    if (isEmpty(anchorPoints) || detectIt.primaryInput === 'touch') {
      return
    }
    var animationFrame
    const tick = () => {
      let section = find(sections.current, x => x.top <= getPageYOffset() && (x.bottom >= getPageYOffset()))
      if (!section) { section = last(sections.current) }
      // const bottom = section.bottom || scrollbar.scrollHeight - window.innerHeight
      const bottom = section.bottom || scrollbar.limit.y
      var percent = section.index / sections.current.length
      percent += (getPageYOffset() - section.top) / (bottom - section.top) * (1 / sections.current.length)

      gsap.set(titleRef.current.children, { opacity: 0 })
      gsap.set(titleRef.current.children[sections.current.indexOf(section)], { opacity: 1 })

      const translated = (1 - percent) * 100
      progressRef.current.style.transform = `translate3d(${translated}%,0,0)`
      animationFrame = window.requestAnimationFrame(tick)
    }
    animationFrame = window.requestAnimationFrame(tick)
    return () => {
      window.cancelAnimationFrame(animationFrame)
    }
  }, [anchorPoints])

  if (isEmpty(anchorPoints)) return null

  return (
    <div className={classes.container}>
      <span ref={titleRef} className={classes.titleContainer}>
        {anchorPoints.map((x, i) => (
          <span key={i} className={classes.title}>{x.title}</span>
        ))}
      </span>
      <div className={classes.pageNumberContainer}>
        {map(anchorPoints, (ap, i) => (
          <div key={i} className={classes.pageNumber}>
            {ap.page_number}
          </div>
        ))}
      </div>
      <div className={classes.progressContainer}>
        <svg viewBox={`0 0 ${anchorPoints.length * 80} 20`} className={classes.progressSvg}>
          <defs>
            <pattern id='progressLines' viewBox='0,0,4,40' width='5' height='40' patternUnits='userSpaceOnUse' patternTransform='rotate(30)'>
              <line x1='0' y1='0' x2='0' y2='40' strokeWidth={1} stroke='currentColor' />
            </pattern>
          </defs>
          <g ref={progressRef} className={classes.progress} fill='url(#progressLines)'>
            <rect width={anchorPoints.length * 80} x={0} y={1} height={18} strokeWidth={2} stroke='currentColor' />
          </g>
          {map(anchorPoints, (ap, i) => (
            <rect key={i} width={80} height={18} x={i * 80} y={1} strokeWidth={2} stroke='currentColor' fill='none' />
          ))}
        </svg>
      </div>
    </div>
  )
})

const useStyles = createUseStyles({
  container: {
    whiteSpace: 'nowrap',
    display: 'none',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    transform: 'rotate(-90deg)',
    height: span(1, 'md'),
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  pageNumberContainer: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  pageNumber: {
    textAlign: 'right',
    width: vw(40, 'desktop'),
    fontFamily: theme.fonts.headings,
    fontWeight: theme.fonts.headingsFontWeight,
    fontSize: vw(8, 'desktop'),
    lineHeight: 1,
    marginBottom: vw(2, 'desktop')
  },
  progressContainer: {
    position: 'relative',
    display: 'flex'
  },
  progressSvg: {
    height: vw(10, 'desktop')
  },
  progress: {
    position: 'absolute',
    top: -1,
    right: 1,
    bottom: 0,
    backgroundColor: 'red',
    transform: 'translate(100%, 0)'
  },
  box: {
    position: 'relative',
    border: [1, 'solid', 'currentColor'],
    borderWidth: [0, 1, 1, 1],
    '&.first': {
      borderTopWidth: 1
    },
    [theme.breakpoints.up('md')]: {
      width: vw(10, 'desktop'),
      height: vw(40, 'desktop')
    }
  },
  titleContainer: {
    fontFamily: theme.fonts.headings,
    fontWeight: 'normal',
    fontSize: vw(14, 'desktop'),
    marginBottom: vw(12, 'desktop'),
    position: 'relative',
    height: '1.5em'
  },
  title: {
    position: 'absolute',
    left: '50%',
    top: 0,
    transform: 'translate(-50%, 0)',
    opacity: 0,
    transition: 'opacity 0.35s ease-in-out'
  }
}, { name: 'ScrollIndicator' })

export default ScrollIndicator
