import inDOM from 'dom-helpers/canUseDOM'
import get from 'lodash/get'
import { ActionType } from 'redux-promise-middleware'
import { pageContentActionCreator } from '../../slices/content/page'

export default () => {
  if (!inDOM) {
    return next => action => next(action)
  }

  // If the user clicks another link while one is loading, it is possible for the responses to come back in the wrong
  // order so the page URL won't match the content. This middleware remembers which request was the most recently
  // dispatched one and attaches an `arrivedToLate` meta property to the fulfilled action of late responses so the
  // reducer can ignore those.
  let requestCounter = 0
  return next => action => {
    if (action.type === pageContentActionCreator.toString()) {
      requestCounter += 1
      action.meta = action.meta || {}
      action.meta.raceRequestIdentifier = requestCounter
    }
    if (action.type === `${pageContentActionCreator}_${ActionType.Fulfilled}`) {
      if (get(action, ['meta', 'raceRequestIdentifier']) !== requestCounter) {
        action.meta.arrivedTooLate = true
      }
    }
    return next(action)
  }
}
