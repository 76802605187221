import React, { useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import noise from './noise.png'
import { useScrollListener } from '../useSmoothScrollbar'

function Noise () {
  const classes = useStyles()
  // const ref = useNoiseShader()
  const ref = useRef()

  // TODO: Fix this, add this to all sections
  // useEffect(() => {
  //   const sections = document.querySelectorAll('section')
  //   gsap.set(sections, { zIndex: 11, position: 'relative' })
  // }, [])

  const onScroll = useCallback(({ y }) => {
    if (ref.current) {
      ref.current.style.transform = `translate3d(0,${y}px,0)`
    }
  }, [])

  useScrollListener(onScroll)

  return (
    <>
      {/* <div className={classes.shaderNoise} ref={ref} /> */}
      <div className={classes.noise} ref={ref} />
    </>
  )
}

const useStyles = createUseStyles({
  // shaderNoise: {
  //   width: '100%',
  //   height: '100vh',
  //   position: 'fixed',
  //   left: 0,
  //   top: 0,
  //   pointerEvents: 'none',
  //   display: 'block',
  //   zIndex: 1, // theme.zIndex.noise,
  //   '& > canvas': {
  //     opacity: 0.3
  //   }
  // }
  noise: {
    width: '100%',
    height: '100vh',
    position: 'fixed',
    left: 0,
    top: 0,
    pointerEvents: 'none',
    display: 'block',
    zIndex: 1, // theme.zIndex.noise,
    overflow: 'hidden',
    '&:after': {
      content: '""',
      width: 'calc(100% + 20rem)',
      height: 'calc(100% + 20rem)',
      backgroundPosition: '50%',
      position: 'absolute',
      left: '-10rem',
      top: '-10rem',
      backgroundImage: `url(${noise})`,
      animation: '$noise 1s steps(2) infinite'
    }
  },
  '@keyframes noise': {
    ...theme.noiseAnimation
  }
}, { name: 'Noise' })

export default Noise
