import contentMiddleware from './content'
import layoutMiddleware from './layout'
import googleTagsMiddleware from './googleTags'
import scrollMiddleware from './scroll'
import rejectedPromiseMiddleware from './rejectedPromise'
import pageTransitionMiddleware from './pageTransition'
import cartMiddleware from './cart'
import cookieMiddleware from './cookie'
import promiseMiddleware from 'redux-promise-middleware'

export default [
  contentMiddleware,
  layoutMiddleware,
  googleTagsMiddleware,
  scrollMiddleware,
  rejectedPromiseMiddleware,
  pageTransitionMiddleware,
  cartMiddleware,
  cookieMiddleware,
  promiseMiddleware
]
