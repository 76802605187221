import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import ResponsiveImage from '../ResponsiveImage'
import { sliceMarginStyles } from '../../style/span'
import theme from '../../style/theme'

const ImageSlice = ({
  className,
  slice: { image }
}) => {
  const classes = useStyles()
  return image
    ? (
      <section className={cn(className, classes.wrapper)}>
        <ResponsiveImage className={classes.image} {...image} parallax={theme.parallax} />
      </section>
    ) : null
}

const useStyles = createUseStyles({
  wrapper: {
    extend: [sliceMarginStyles],
    width: '100%'
  },
  image: {
    '& picture::before': {
      display: 'block',
      content: '""',
      paddingTop: '106.6%',
      [theme.breakpoints.up('md')]: {
        paddingTop: '65.97%'
      }
    }
  }
}, { name: 'ImageSlice' })

export default ImageSlice
