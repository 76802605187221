import React, { useEffect, forwardRef, useRef } from 'react'
import get from 'lodash/get'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import round from '../helpers/round'
import defaultImage from '../images/spacer'
import { quart } from '../style/eases'
import { span } from '../style/span'
import theme from '../style/theme'
import { vw } from '../style/vw'
import ParallaxElement from './ParallaxElement'

const Picture = forwardRef(({ classes, alt, sizes, disableLazy }, ref) => {
  const hasWebp = !!get(sizes, [0, 'webpUrl'])
  const srcset = key => sizes.map(item => (`${item[key]} ${item.width}w`)).join()
  const srcName = disableLazy ? 'srcSet' : 'data-srcset'
  return (
    <picture>
      {hasWebp && <source {...{ [srcName]: srcset('webpUrl') }} type='image/webp' />}
      {sizes && <source {...{ [srcName]: srcset('url') }} />}
      <img
        ref={ref}
        data-sizes='auto'
        alt={alt}
        className={cn((sizes && !disableLazy && 'lazyload') || (disableLazy && 'lazyloaded'), classes.image)}
        src={defaultImage}
      />
    </picture>
  )
})

const NoScript = ({ classes, alt, sizes }) => {
  if (sizes) {
    return (
      <noscript>
        <img
          src={sizes[sizes.length - 1].url}
          srcSet={sizes.map(item => (`${item.url} ${item.width}w`)).join()}
          className={`${classes.image} lazyloaded`}
          alt={alt}
        />
      </noscript>
    )
  }
  return null
}

const ResponsiveImage = React.forwardRef(function ResponsiveImage (props, ref) {
  const { disableLazy, aspect, children, className, alt, sizes, palette, style, parallax, title, onImageLoaded } = props
  const classes = useStyles({ aspect, palette })
  const pictureRef = useRef()

  useEffect(() => {
    if (onImageLoaded) {
      const onLoaded = (e) => {
        if (pictureRef.current === e.target) {
          onImageLoaded(e)
        }
      }
      document.addEventListener('lazyloaded', onLoaded)
      return () => {
        document.removeEventListener('lazyloaded', onLoaded)
      }
    }
  }, [])

  return (
    <div className={cn(classes.container, className)} ref={ref} style={style}>
      {parallax ? (
        <ParallaxElement {...parallax} className={classes.parallax}>
          <Picture classes={classes} alt={alt} sizes={sizes} disableLazy={disableLazy} ref={pictureRef} />
        </ParallaxElement>
      ) : (
        <Picture classes={classes} alt={alt} sizes={sizes} disableLazy={disableLazy} ref={pictureRef} />
      )}
      <NoScript classes={classes} alt={alt} sizes={sizes} />
      {children}
      {title && <span className={classes.caption}>{title}</span>}
    </div>
  )
})

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    width: '100%',
    display: 'block',
    overflow: 'hidden',
    '& picture::before': {
      display: 'block',
      content: ({ aspect }) => aspect ? '""' : undefined,
      paddingTop: ({ aspect }) => aspect ? `${round(100 / aspect)}%` : undefined
    }
  },
  image: {
    position: 'absolute',
    opacity: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transition: `opacity 0.5s ${quart.out}`,
    willChange: 'opacity',
    objectFit: 'cover',
    fontFamily: '"object-fit: cover;"', // object-fit polyfill
    '&.lazyloaded': {
      opacity: 1
    }
  },
  link: {
    textDecoration: 'none'
  },
  parallax: {
    height: '100%'
  },
  caption: {
    position: 'absolute',
    display: 'block',
    color: ({ palette }) => get(palette, ['dominant', 'title']),
    paddingLeft: vw(16),
    bottom: span(1),
    left: span(1),
    fontSize: vw(12),
    [theme.breakpoints.up('md')]: {
      paddingLeft: vw(16, 'desktop'),
      bottom: span(1, 'md'),
      left: span(1, 'md'),
      fontSize: vw(12, 'desktop')
    },
    '&:after': {
      position: 'absolute',
      display: 'block',
      content: '""',
      top: '50%',
      transform: 'translate(0, -50%)',
      left: 0,
      width: vw(8),
      height: vw(8),
      borderRadius: '50%',
      border: [1, 'solid', 'currentColor'],
      [theme.breakpoints.up('md')]: {
        fontSize: vw(12, 'desktop'),
        width: vw(8, 'desktop'),
        height: vw(8, 'desktop')
      }
    }
  }
}, { name: 'ResponsiveImage' })

export default ResponsiveImage
