/* eslint-env browser */
import inDOM from 'dom-helpers/canUseDOM'
import theme from './theme'

const supportsTextStroke = !inDOM || (() => {
  const el = document.createElement('a')
  el.style.cssText = '-webkit-text-stroke:red;'
  return !!el.style.length
})()

export default function outlineText (color = theme.colors.text, width = 1) {
  return {
    '-webkit-text-stroke': `${width}px ${color}`,
    textShadow: supportsTextStroke
      ? ''
      : `${width}px ${width}px 0 ${color}, -${width}px -${width}px 0 ${color}, ${width}px -${width}px 0 ${color}, -${width}px ${width}px 0 ${color}, ${width}px ${width}px 0 ${color}`

  }
}
