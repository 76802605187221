import React, { forwardRef, useRef, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import cn from 'classnames'
import {
  incrementLineItemQuantity,
  decrementLineItemQuantity,
  removeFromCart
} from '../../redux/actions'
import { isCartBusy } from '../../redux/slices/cart'
import { getProductDefaultsByHandle } from '../../redux/slices/content'
import { formatPrice, formatQuantity } from '../../helpers/format'
import { useHoverCursor } from '../Cursor'
import ResponsiveImage from '../ResponsiveImage'
import { span } from '../../style/span'
import { vw } from '../../style/vw'
import theme from '../../style/theme'

const LineItem = forwardRef(({ lineItem }, ref) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const busy = useSelector(isCartBusy)
  const removeButtonRef = useRef()
  const incrementRef = useRef()
  const decrementRef = useRef()
  useHoverCursor(removeButtonRef)
  useHoverCursor(incrementRef)
  useHoverCursor(decrementRef)

  const { title, product, variant, quantity, image } = lineItem
  const { slug } = product
  const { id, price, comparePrice } = variant

  const productDefaultsByHandle = useSelector(getProductDefaultsByHandle)
  const defaultProduct = productDefaultsByHandle[slug]

  const handleIncrement = useCallback(() => {
    if (busy) return
    dispatch(incrementLineItemQuantity(id))
  }, [busy]) // eslint-disable-line

  const handleDecrement = useCallback(() => {
    if (busy) return
    dispatch(decrementLineItemQuantity(id))
  }, [busy]) // eslint-disable-line

  const handleRemove = useCallback(() => {
    if (busy) return
    dispatch(removeFromCart(id))
  }, [busy]) // eslint-disable-line

  if (quantity === 0) return null

  return (
    <div className={classes.container}>
      <ResponsiveImage {...get(defaultProduct, ['image'], image)} alt={title} className={classes.image} />
      <div className={classes.details}>
        <div className={classes.title}>{get(defaultProduct, ['title'], title)}</div>
        <div className={classes.price}>
          <div className={classes.unitPrice}>
            {comparePrice && <span className={cn(classes.priceFigure, classes.comparePrice)}>{formatPrice(parseFloat(comparePrice.amount))}</span>}
            <span className={cn(classes.priceFigure, classes.actualPrice)}>{formatPrice(parseFloat(price.amount))}</span>
            {get(defaultProduct, ['subtitle']) && <span className={classes.subtitle}>{get(defaultProduct, ['subtitle'])}</span>}
          </div>
          <div className={classes.totalPrice}>
            <span className={classes.priceFigure}>{formatPrice(quantity * parseFloat(price.amount))}</span>
          </div>
        </div>
        <div className={classes.quantity}>
          <button className={classes.adjustButton} onClick={handleDecrement} aria-label='Decrement Product' ref={decrementRef}>
            <span>-</span>
          </button>
          <span className={classes.quantityLabel}>{formatQuantity(quantity)}</span>
          <button className={classes.adjustButton} onClick={handleIncrement} aria-label='Increment Product' ref={incrementRef}>
            <span>+</span>
          </button>
          <button className={classes.removeButton} onClick={handleRemove} aria-label='Remove Product' ref={removeButtonRef}>Remove</button>
        </div>
      </div>
    </div>
  )
})

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    color: theme.colors.primary,
    display: 'flex',
    alignItems: 'center',
    marginBottom: span(1),
    paddingBottom: span(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(30, 'desktop'),
      paddingBottom: vw(30, 'desktop')
    },
    '&:after': {
      content: '""',
      backgroundColor: theme.colors.border,
      height: 1,
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      [theme.breakpoints.up('md')]: {
        left: 0,
        right: 0
      }
    },
    '&:last-child': {
      marginBottom: 0,
      paddingBottom: 0,
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
        paddingBottom: 0
      },
      '&:after': {
        display: 'none'
      }
    }
  },
  image: {
    height: vw(100),
    width: vw(100),
    marginRight: vw(20),
    [theme.breakpoints.up('md')]: {
      height: vw(100, 'desktop'),
      width: vw(100, 'desktop'),
      marginRight: vw(20, 'desktop')
    }
  },
  details: {
    flex: 1
  },
  title: {
    extend: [theme.global[theme.headingsSelector], theme.global.h6],
    marginBottom: 8,
    [theme.breakpoints.up('md')]: {
      extend: [theme.global[theme.headingsSelector], theme.global.h5],
      marginBottom: '8px !important'
    }
  },
  price: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: 8,
    [theme.breakpoints.up('md')]: {
      marginBottom: 8
    }
  },
  priceFigure: {
    extend: [theme.global[theme.headingsSelector], theme.global.h6],
    lineHeight: 1,
    marginRight: 10,
    '&:last-child': {
      marginRight: 0
    },
    [theme.breakpoints.up('md')]: {
      fontSize: vw(16, 'desktop'),
      marginBottom: 8
    }
  },
  comparePrice: {
    textDecoration: 'line-through'
  },
  subtitle: {
    fontSize: vw(12),
    textTransform: 'uppercase',
    opacity: 0.5,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(12, 'desktop'),
      marginBottom: 8
    }
  },
  quantity: {
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    color: theme.colors.primary,
    opacity: 0.5,
    minWidth: vw(30),
    minHeight: vw(30),
    background: 'none',
    outline: 'none',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: vw(12),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(12, 'desktop'),
      minWidth: vw(30, 'desktop'),
      minHeight: vw(30, 'desktop')
    }
  },
  quantityLabel: {
    composes: '$button',
    border: [1, 'solid', theme.colors.primary],
    borderLeft: 'none',
    borderRight: 'none',
    margin: 0
  },
  adjustButton: {
    composes: '$button',
    border: [1, 'solid', theme.colors.primary],
    cursor: 'pointer',
    '&:hover': {
      opacity: 1
    }
  },
  removeButton: {
    color: theme.colors.primary,
    textDecoration: 'underline',
    background: 'none',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    opacity: 0.5,
    marginLeft: vw(10),
    textTransform: 'uppercase',
    fontFamily: theme.fonts.body,
    fontWeight: theme.fonts.bodyFontWeight,
    fontSize: vw(12),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(12, 'desktop'),
      marginLeft: vw(10, 'desktop'),
      '&:hover': {
        opacity: 1
      }
    }
  }
}, { name: 'LineItem' })

export default LineItem
