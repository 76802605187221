import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import range from 'lodash/range'

const Placeholder = ({ className, type, aspect = (360 / 460), width, height, lines = 1, align }) => {
  const classes = useStyles({ aspect, width })
  return range(lines).map((_, i) => <div key={i} className={cn(className, classes.placeholder, type, align)} />)
}

const useStyles = createUseStyles({
  placeholder: {
    backgroundColor: '#ced4da',
    position: 'relative',
    overflow: 'hidden',
    height: ({ height }) => height || '1em',
    width: ({ width }) => width || '100%',
    minHeight: 16,
    '&.multiline:nth-child(1n)': {
      width: '80%'
    },
    '&.multiline:nth-child(2n)': {
      width: '40%'
    },
    '&.image': {
      height: 'auto',
      '&:after': {
        content: '""',
        display: 'block',
        width: '100%',
        paddingTop: ({ aspect }) => aspect ? `${(1 / aspect) * 100}%` : '0'
      }
    },
    '&.center': {
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: '50%',
      zIndex: 1,
      width: '500%',
      marginLeft: '-250%',
      animation: '$placeholderAnimation 0.8s linear infinite',
      background: 'linear-gradient(100deg, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.35) 50%, rgba(255, 255, 255, 0) 54%) 50% 50%'
    }
  },
  '@keyframes placeholderAnimation': {
    '0%': {
      transform: 'translate3d(-30%, 0, 0)'
    },
    '100%': {
      transform: 'translate3d(30%, 0, 0);'
    }
  }
}, { name: 'Placeholder' })

export default Placeholder
