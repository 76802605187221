import composeReduxMiddlewares from '../../../helpers/composeReduxMiddlewares'
import raceMiddleware from './race'
import serverErrorMiddleware from './serverError'
import pagesMiddleware from './pages'

export default composeReduxMiddlewares(
  raceMiddleware,
  serverErrorMiddleware,
  pagesMiddleware
)
