import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import theme from '../../../style/theme'
import { vw } from '../../../style/vw'

const TableRow = ({ label, quantity, weight }) => {
  const classes = useStyles()
  return (
    <>
      <div className={cn(classes.itemName, classes.columnLabel)}>
        <span>{label}</span>
      </div>
      <div className={cn(classes.itemQty, classes.columnLabel)}>
        <span>{quantity}</span>
      </div>
      <div className={cn(classes.itemWeight, classes.columnLabel)}>
        <span>{weight}</span>
      </div>
    </>
  )
}

const ProductTable = ({ tableContent }) => {
  const classes = useStyles()
  return (
    <div className={classes.productTable}>
      <div className={classes.tableHeader}>
        <div className={cn(classes.itemName, classes.columnLabel)}>
          <span>Included in this box</span>
        </div>
        <div className={cn(classes.itemQty, classes.columnLabel)}>
          <span>Qty</span>
        </div>
        <div className={cn(classes.itemWeight, classes.columnLabel)}>
          <span>Weight</span>
        </div>
      </div>
      <div className={classes.tableRows}>
        {tableContent.map(productRow => <TableRow key={productRow.id} {...productRow} />)}
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  productTable: {

  },
  tableGrid: {
    display: 'grid',
    gridGap: 5,
    gridRowGap: 10,
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    [theme.breakpoints.up('md')]: {
      gridGap: 10,
      gridRowGap: 10,
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'
    }
  },
  tableHeader: {
    composes: '$tableGrid',
    borderBottom: [1, 'solid', theme.colors.primary],
    paddingBottom: 10,
    marginBottom: 15,
    fontSize: vw(12),
    lineHeight: 1.2,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(12, 'desktop')
    }
  },
  tableRows: {
    composes: '$tableGrid',
    textTransform: 'uppercase',
    fontSize: vw(14),
    lineHeight: 1.2,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'desktop')
    }
  },
  itemName: {
    gridColumn: 'span 7',
    paddingRight: 8,
    [theme.breakpoints.up('md')]: {
      gridColumn: 'span 6'
    }
  },
  itemQty: {
    gridColumn: 'span 2',
    [theme.breakpoints.up('md')]: {
      gridColumn: 'span 1'
    }
  },
  itemWeight: {
    gridColumn: 'span 3',
    [theme.breakpoints.up('md')]: {
      gridColumn: 'span 2'
    }
  }
})

export default ProductTable
