import React, { useMemo } from 'react'
import Slider from '../Slider'
import Tile from '../Slider/Tile'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import map from 'lodash/map'
import { resolveLink, resolveInternalLink } from '../../helpers/resolveLink'
import get from 'lodash/get'

const SliderSlice = ({ className, slice, page }) => {
  const { title, link, slides, pages, size } = slice
  const pageId = page.id

  const tiles = useMemo(() => {
    if (!isEmpty(slides)) {
      return map(slides, slide => ({
        ...slide,
        url: resolveLink(get(slide.link, [0]))
      }))
    }
    return map(filter(pages, page => page.id !== pageId), page => {
      const { title, subtitle, image } = page
      return {
        title,
        subtitle,
        image,
        url: resolveInternalLink(page)
      }
    })
  }, [slides, pages])

  if (isEmpty(tiles)) return null

  return (
    <Slider title={title} link={resolveLink(get(link, [0]))}>
      {tiles.map(({ image, title, url, subtitle }, i) => (
        <Tile key={i} image={image} title={title} subTitle={subtitle} link={url} size={size} />
      ))}
    </Slider>
  )
}

export default SliderSlice
