import { createAction } from '@reduxjs/toolkit'

export const checkoutCart = createAction('cart/checkoutCart')
export const clearCart = createAction('cart/clearCart')

export const addToCart = createAction('cart/addToCart', (variantId, quantity = 1, sellingPlanId = null) => ({
  payload: {
    variantId,
    quantity,
    ...(sellingPlanId && { selling_plan: sellingPlanId })
  }
}))

export const incrementLineItemQuantity = createAction('cart/incrementLineItemQuantity', variantId => ({
  payload: variantId
}))

export const decrementLineItemQuantity = createAction('cart/decrementLineItemQuantity', variantId => ({
  payload: variantId
}))

export const removeFromCart = createAction('cart/removeFromCart', variantId => ({
  payload: variantId
}))

export const cartActionCreator = createAction('cart/cartActionCreator', promise => ({
  meta: { scope: 'cart' },
  payload: promise
}))

export const saveShopifyCheckoutId = createAction('cart/saveShopifyCheckoutId', (promise, id) => ({
  meta: { id },
  payload: promise
}))

export const removeShopifyCheckoutId = createAction('cart/removeShopifyCheckoutId', promise => ({
  payload: promise
}))
