import { useEffect } from 'react'

const useWindowResize = (callback) => {
  useEffect(() => {
    window.addEventListener('resize', callback, { passive: true })
    return () => {
      window.removeEventListener('resize', callback)
    }
  }, [callback])
}

export default useWindowResize
