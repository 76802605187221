import React, { useMemo } from 'react'
import map from 'lodash/map'
import each from 'lodash/each'
import filter from 'lodash/filter'
import { useSelector } from 'react-redux'
import { getPageContent, getPageSlices } from '../redux/slices/content'
import ErrorBoundary from './ErrorBoundary'

import BackgroundColor from './Slices/BackgroundColor'
import RichText from './Slices/RichText'
import ImageSlice from './Slices/ImageSlice'
import HomepageHeroSlice from './Slices/HomepageHeroSlice'
import TwoImageCopySlice from './Slices/TwoImageCopySlice'
import HeadlineSlice from './Slices/HeadlineSlice'
import LargeTextSlice from './Slices/LargeTextSlice'
import AnchorPoint from './Slices/AnchorPoint'
import QuoteSlice from './Slices/QuoteSlice'
import PageHeroWithPageNumberSlice from './Slices/PageHeroWithPageNumberSlice'
import ProductSlider from './Slices/ProductSlider'
import SliderSlice from './Slices/SliderSlice'
import PageHero from './Slices/PageHero'
import RecipeIngredientsSlice from './Slices/RecipeIngredientsSlice'
import ProductListSlice from './Slices/ProductListSlice'
import PageListSlice from './Slices/PageListSlice'
import AccordionSlice from './Slices/AccordionSlice'
import GuideSlice from './Slices/GuideSlice'
import ShopProducts from './Slices/ShopProducts'
import theme from '../style/theme'

const sliceComponentSelector = {
  rich_text: RichText,
  image_slice: ImageSlice,
  page_hero: PageHero,
  homepage_hero_slice: HomepageHeroSlice,
  two_image_copy_slice: TwoImageCopySlice,
  headline: HeadlineSlice,
  large_text_slice: LargeTextSlice,
  anchor_point: AnchorPoint,
  quote_slice: QuoteSlice,
  page_hero_with_number_slice: PageHeroWithPageNumberSlice,
  product_slider_slice: ProductSlider,
  slider_slice: SliderSlice,
  recipe_ingredients_slice: RecipeIngredientsSlice,
  product_list_slice: ProductListSlice,
  page_list_slice: PageListSlice,
  accordion_slice: AccordionSlice,
  guide_slice: GuideSlice,
  shop_products_slice: ShopProducts
}

const noBottomMargin = (slice, nextSlice) => {
  if (!slice || !nextSlice) return false
  if (slice.type === 'page_hero_with_number_slice' && nextSlice.type === 'image_slice') return true
  return false
}

function groupSlices (page, slices) {
  const rawGroups = []
  let currentGroup

  each(slices, (slice, sliceIndex) => {
    if (slice.type === 'change_background_color' || !currentGroup) {
      let backgroundColor
      if (slice.type === 'change_background_color') {
        backgroundColor = slice.backgroundColor
      }
      if (!currentGroup || backgroundColor !== currentGroup.backgroundColor) {
        const borderColor = theme.getBorderColor(!backgroundColor ? page.color : backgroundColor)
        currentGroup = { slices: [], sliceTypes: [], backgroundColor, borderColor }
        rawGroups.push(currentGroup)
      }
    }
    const Component = sliceComponentSelector[slice.type]
    if (Component) {
      currentGroup.slices.push(
        <ErrorBoundary key={`slice-${sliceIndex}`}>
          <Component slice={slice} page={page} noBottomMargin={noBottomMargin(slice, slices[sliceIndex + 1])} />
        </ErrorBoundary>
      )
      currentGroup.sliceTypes.push(slice)
    }
  })

  const nonEmptyGroups = filter(rawGroups, ({ slices }) => slices.length > 0)

  // If we filtered out some groups, there may now be two groups in a row, with the same color,
  // that should be merged into a single group.
  const groups = []
  each(nonEmptyGroups, (group) => {
    const prevGroup = groups[groups.length - 1]
    if (prevGroup && prevGroup.backgroundColor === group.backgroundColor) {
      // Merge the groups
      prevGroup.slices = [
        ...prevGroup.slices,
        group.slices
      ]
    } else {
      groups.push(group)
    }
  })

  return groups
}

const Page = ({ className }) => {
  const slices = useSelector(getPageSlices)
  const page = useSelector(getPageContent)

  const sliceComponents = useMemo(() => {
    const groups = groupSlices(page, slices)
    return map(groups, ({ backgroundColor, borderColor, slices, sliceTypes }, groupIndex) => {
      if (!backgroundColor) {
        return slices
      }

      return (
        <BackgroundColor key={`group-${groupIndex}`} backgroundColor={backgroundColor} borderColor={borderColor}>
          {slices}
        </BackgroundColor>
      )
    })
  }, [slices])

  return sliceComponents
}

export default Page
