import React, { useRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import isEmpty from 'lodash/isEmpty'
import ResponsiveImage from '../ResponsiveImage'
import theme from '../../style/theme'
import { span } from '../../style/span'
import { vw } from '../../style/vw'
import Link from '../Link'
import detectIt from 'detect-it'
import { useHoverCursor } from '../Cursor'

const Tile = ({ className, title, image, subTitle, link, size }) => {
  const classes = useStyles()
  const ref = useRef()
  useHoverCursor(ref, !isEmpty(link))

  return (
    <Link className={cn(classes.tile, { withLink: !isEmpty(link) }, size)} nonLinkTag='div' link={link} ref={ref}>
      <div>
        {image && (
          <ResponsiveImage {...image} className={classes.image} aspect={500 / 700}>
            <div className={classes.overlay} />
          </ResponsiveImage>
        )}
        <div className={classes.content}>
          {title && <div className={classes.title}>{title}</div>}
          {subTitle && <div className={classes.subTitle}>{subTitle}</div>}
        </div>
      </div>
    </Link>
  )
}

const useStyles = createUseStyles({
  tile: {
    display: 'block',
    whiteSpace: 'normal',
    color: theme.colors.textSecondary,
    width: span(9),
    position: 'relative',
    margin: [0, span(1), 0, 0],
    transform: 'rotate(-2deg)',
    [theme.breakpoints.up('md')]: {
      width: span(9, 'md'),
      margin: [0, 0, 0, span(1, 'md')]
    },
    ...(detectIt.primaryInput === 'touch' ? {} : {
      '&.withLink': {
        '& $image img': {
          transition: 'transform 1.6s ease-in-out'
        },
        '&:hover $image img': {
          transform: 'scale(1.1)'
        }
      }
    }),
    '&.large': {
      [theme.breakpoints.up('md')]: {
        width: span(11, 'md')
      }
    }
  },
  image: {
    '& span': {
      transform: 'translateZ(0)'
    }
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    transform: 'translateZ(0)',
    background: 'radial-gradient(50% 50% at 50% 50%, #C4C4C4 0%, rgba(255, 255, 255, 0) 100%)',
    mixBlendMode: 'multiply'
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: span(1),
    transform: 'translateZ(0)',
    [theme.breakpoints.up('md')]: {
      padding: span(1, 'md')
    }
  },
  title: {
    fontFamily: theme.fonts.headings,
    fontWeight: theme.fonts.headingsFontWeight,
    textAlign: 'center',
    fontSize: vw(20),
    lineHeight: 1,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(45, 'desktop')
    }
  },
  subTitle: {
    fontSize: vw(34),
    fontFamily: theme.fonts.script,
    textAlign: 'center',
    textTransform: 'lowercase',
    transform: 'rotate(-4.5deg)',
    [theme.breakpoints.up('md')]: {
      fontSize: vw(34, 'desktop')
    }
  }
}, { name: 'Tile' })

export default Tile
