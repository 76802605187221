import inDOM from 'dom-helpers/canUseDOM'
import { fetchJson } from '../../api'
import { serverErrorActionCreator } from '../../slices/content/page'
import { getGlobalContentApiUrl } from '../../slices/config'

async function fetchServerErrorContent (store, error) {
  const api = getGlobalContentApiUrl(store.getState())
  const content = await fetchJson(api, 'error-page-500')
  return {
    error,
    content
  }
}

export default store => next => action => {
  if (!inDOM && action.type === serverErrorActionCreator.toString()) {
    action.payload = fetchServerErrorContent(store, action.payload)
  }
  return next(action)
}
