import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import {
  getPageId,
  getPageSlices,
  getPageBackgroundColor
} from '../redux/slices/content'
import Footer from './Footer'
import Header from './Header'
import theme from '../style/theme'
import Sidebar from './Sidebar'

import { useSmoothScrollbar } from './useSmoothScrollbar'

import Slices from './Slices'
import CookiesBanner from './CookiesBanner'
import Noise from './Noise'
import ColorContext from './ColorContext'
import detectIt from 'detect-it'

const Main = ({ classes }) => {
  const slices = useSelector(getPageSlices)
  const { ref: scrollRef } = useSmoothScrollbar()
  const id = useSelector(getPageId)

  // DO NOT REMOVE THE USELESS DIV, it is there so that smooth-scrollbar does not crash when it unmounts.
  // See https://github.com/idiotWu/react-smooth-scrollbar/issues/9
  return (
    <main className={classes.main} ref={scrollRef}>
      <div>
        <Noise />
        <Slices slices={slices} id={id} key={id} />
        <Footer />
      </div>
    </main>
  )
}

const Page = () => {
  const backgroundColor = useSelector(getPageBackgroundColor)
  const borderColor = theme.getBorderColor(backgroundColor)
  const colorContextValue = useMemo(() => ({ backgroundColor, borderColor }), [backgroundColor, borderColor])
  const classes = useStyles({ backgroundColor, borderColor })

  return (
    <ColorContext.Provider value={colorContextValue}>
      <div className={classes.container}>
        <div className={classes.curtain} id='curtain' />
        <Header />
        <Sidebar />
        <Main classes={classes} />
        <CookiesBanner />
      </div>
    </ColorContext.Provider>
  )
}

const useStyles = createUseStyles({
  container: {
    backgroundColor: ({ backgroundColor = theme.colors.backgroundColors.default.background }) => backgroundColor,
    color: ({ backgroundColor }) => theme.getForegroundColor(backgroundColor),
    borderColor: ({ borderColor }) => borderColor
  },
  curtain: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: theme.colors.primary,
    transform: 'translateY(100%)',
    zIndex: theme.zIndex.pageLoadingBar,
    opacity: 0
  },
  main: {
    ...(detectIt.primaryInput === 'touch' ? {} : {
      overflowY: 'auto',
      overflowX: 'hidden',
      height: '100vh',
      width: '100%'
    })
  }
}, { name: 'App' })

export default Page
