import React, { useRef, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import {
  getMenuItems,
  getSubMenuItems,
  getAcknowledgement,
  getFooterPartnersLabel,
  getFooterPartners,
  getPageBackgroundColor
} from '../redux/slices/content'
import Link, { LinkWithCursorHoverEffect } from './Link'
import { resolveLink } from '../helpers/resolveLink'
import { span } from '../style/span'
import theme from '../style/theme'
import { vw } from '../style/vw'
import SplitWordsAnimation from './SplitWordsAnimation'
import gsap from 'gsap'
import { useHoverCursor } from './Cursor'
import Color from 'color'
import { oneLine } from 'common-tags'

function MenuItem ({ item, animate }) {
  const classes = useStyles()
  const ref = useRef()
  useHoverCursor(ref)
  return (
    <li>
      <span className={classes.pageNumber}>{item.page_number}</span>
      <Link className={classes.mainMenuLink} link={resolveLink(item.link)} ref={ref}>
        <SplitWordsAnimation>
          <span>
            {item.link.text}
          </span>
        </SplitWordsAnimation>
      </Link>
    </li>
  )
}

function Menu ({ showPartners, reverse, animate }) {
  const classes = useStyles()
  const menu = useSelector(getMenuItems)
  const submenu = useSelector(getSubMenuItems)
  const acknowledgement = useSelector(getAcknowledgement)
  const partnerLabel = useSelector(getFooterPartnersLabel)
  const partners = useSelector(getFooterPartners)
  const submenuNavRef = useRef()
  const foregroundColor = Color(theme.getForegroundColor(useSelector(getPageBackgroundColor)))

  useEffect(() => {
    if (animate && submenuNavRef.current) {
      gsap.to(submenuNavRef.current.children, { opacity: 1, y: 0, duration: 1, stagger: 0.1 })
    }
  }, [animate])

  return (
    <div className={cn(classes.container, { reverse })}>
      <nav className={classes.mainNav}>
        <ul className={classes.mainMenuLinks}>
          {menu && menu.map((item, i) => (
            <MenuItem key={i} item={item} />
          ))}
        </ul>
      </nav>
      <nav className={classes.submenuNav} ref={submenuNavRef}>
        <ul className={classes.submenuLinks}>
          {submenu && submenu.map((item, i) => <li key={i}><LinkWithCursorHoverEffect className={classes.subMenuLink} link={resolveLink(item.link[0])} /></li>)}
        </ul>
        <p className={classes.acknowledgement}>
          {acknowledgement}
        </p>
        {showPartners && (
          <div className={classes.partners}>
            <span className={classes.label}>{partnerLabel}</span>
            {partners && partners.map((partner, i) => {
              const image = partner.image
              return (
                <LinkWithCursorHoverEffect key={i} link={resolveLink(partner.link[0])}>
                  <svg viewBox={`0 0 ${partner.image.aspect * 100} 100`} className={classes.partnerImage}>
                    <defs>
                      <filter id='colorChange'>
                        <feColorMatrix
                          type='matrix'
                          values={oneLine(`${foregroundColor.red() / 255}     0     0     0     0
                                           0     ${foregroundColor.green() / 255}     0     0     0
                                           0     0     ${foregroundColor.blue() / 255}     0     0
                                           0     0     0     1     0`)}
                        />
                      </filter>
                    </defs>
                    <image href={partner.image.sizes[0].url} width='100%' x='0' y='0' filter='url(#colorChange)' />
                  </svg>
                </LinkWithCursorHoverEffect>
              )
            })}
          </div>
        )}
      </nav>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'stretch'
    },
    '&.reverse': {
      flexDirection: 'row-reverse'
    }
  },
  mainNav: {
    [theme.breakpoints.up('md')]: {
      paddingRight: span(1, 'md')
    }
  },
  mainMenuLinks: {
    listStyle: 'none',
    margin: [0, 0, vw(50), span(1)],
    padding: 0,
    lineHeight: 1,
    [theme.breakpoints.up('md')]: {
      margin: [0, 0, 0, span(2, 'md')]
    },
    '& > li:not(:last-child)': {
      marginBottom: vw(20),
      [theme.breakpoints.up('md')]: {
        marginBottom: vw(50, 'desktop')
      }
    },
    '& > li': {
      position: 'relative'
    }
  },
  pageNumber: {
    fontFamily: theme.fonts.headings,
    fontWeight: theme.fonts.headingsFontWeight,
    position: 'absolute',
    fontSize: vw(12),
    display: 'block',
    top: vw(4),
    left: span(-1),
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: span(1, 'md'),
      height: 1,
      backgroundColor: 'currentColor',
      display: 'none'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: vw(16, 'desktop'),
      top: vw(8, 'desktop'),
      left: span(-2, 'md'),
      paddingTop: vw(32, 'desktop'),
      '&:after': {
        display: 'block'
      }
    }
  },
  mainMenuLink: {
    display: 'inline-block',
    fontFamily: theme.fonts.headings,
    fontWeight: theme.fonts.headingsFontWeight,
    fontSize: vw(36),
    textDecoration: 'none',
    [theme.breakpoints.up('md')]: {
      fontSize: vw(54, 'desktop')
    }
  },
  submenuNav: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: span(7, 'md'),
      paddingLeft: span(1, 'md')
    },
    '& > *': {
      opacity: 0,
      transform: 'translate(0, 100px)'
    }
  },
  submenuLinks: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& > li': {
      width: '50%',
      display: 'block',
      marginBottom: vw(40),
      [theme.breakpoints.up('md')]: {
        marginBottom: vw(100, 'desktop')
      }
    }
  },
  subMenuLink: {
    fontFamily: theme.fonts.headings,
    fontSize: vw(16),
    letterSpacing: '0.02em',
    textDecoration: 'none',
    [theme.breakpoints.up('md')]: {
      fontSize: vw(24, 'desktop')
    }
  },
  acknowledgement: {
    fontSize: vw(12),
    lineHeight: 1.4,
    marginTop: vw(40),
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      fontSize: vw(14, 'desktop')
    }
  },
  partners: {
    display: 'flex',
    marginTop: vw(50),
    [theme.breakpoints.up('md')]: {
      marginTop: vw(100, 'desktop')
    }
  },
  label: {
    opacity: 0.5,
    fontSize: vw(14),
    marginRight: vw(20),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'desktop'),
      marginRight: vw(20, 'desktop')
    }
  },
  partnerImage: {
    width: vw(140),
    [theme.breakpoints.up('md')]: {
      width: vw(140, 'desktop')
    }
  }
}, { name: 'Menu' })

export default Menu
