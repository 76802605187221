import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { sliceMarginStyles, span } from '../../style/span'
import ResponsiveImage from '../ResponsiveImage'
import theme from '../../style/theme'
import { vw } from '../../style/vw'
import Heading from '../Heading'
import ColorContext from '../ColorContext'

const GuideSlice = ({ className, slice }) => {
  const { title, image } = slice
  const { borderColor } = useContext(ColorContext)
  const classes = useStyles({ borderColor })

  return (
    <section className={classes.section}>
      <div className={classes.wrapper}>
        <div className={classes.titleContainer}>
          <Heading key={title} text={title} className={classes.title} />
        </div>
        <ResponsiveImage {...image} />
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    extend: [sliceMarginStyles],
    borderColor: ({ borderColor }) => borderColor,
    borderBottom: [1, 'solid']
  },
  wrapper: {
    margin: [0, span(1)],
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      margin: [0, span(1, 'md')]
    }

  },
  titleContainer: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      width: span(3, 'md'),
      height: vw(700, 'desktop')
    }
  },
  title: {
    display: 'block',
    whiteSpace: 'pre',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: 0,
      left: 0,
      textAlign: 'right',
      whiteSpace: 'pre-wrap',
      transform: 'rotate(-90deg) translate(-100%, 0)',
      transformOrigin: 'top left',
      height: span(3, 'md'),
      width: vw(700, 'desktop'),
      margin: 0
    }
  },
  image: {
  }
}, { name: 'GuideSlice' })

export default GuideSlice
