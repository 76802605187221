import React, { useState, forwardRef, useCallback, useRef, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'
import cn from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import {
  isCartEmpty,
  getCartLineItems,
  getCartLineItemsCount,
  getCartSubtotalPriceAmount,
  getCartDiscount,
  getCartLineItemsSubtotalPrice
} from '../../redux/slices/cart'
import { toggleCartDialog, isCartDialogOpen } from '../../redux/slices/layout'
import {
  getCartEmptyCopy,
  getCartCouponCopy,
  getCartTitle
} from '../../redux/slices/content'
import LineItem from './LineItem'
import RichText from '../RichText'
import { formatPrice } from '../../helpers/format'
import useOutsideClickListener from '../../hooks/useOutsideClickListener'
import CartFooter from './CartFooter'
import theme from '../../style/theme'
import { vw } from '../../style/vw'
import { span, spanWithoutCalc } from '../../style/span'
import { expo, quad, back } from '../../style/eases'
import noise from '../Noise/noise.png'
import { useHoverCursor } from '../Cursor'

const CartDialog = forwardRef((props, ref) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const open = useSelector(isCartDialogOpen)
  const emptyCartCopy = useSelector(getCartEmptyCopy)
  const couponCopy = useSelector(getCartCouponCopy)
  const title = useSelector(getCartTitle)
  const lineItems = useSelector(getCartLineItems)
  const lineItemCount = useSelector(getCartLineItemsCount)
  const totalAmount = useSelector(getCartSubtotalPriceAmount)
  const lineItemsSubtotalPrice = useSelector(getCartLineItemsSubtotalPrice)
  const discount = useSelector(getCartDiscount)
  const empty = useSelector(isCartEmpty)

  const [postcodeValid, setPostcodeValid] = useState(null)

  const handleClose = useCallback(() => {
    dispatch(toggleCartDialog())
  }, [])
  const containerRef = useOutsideClickListener(open, handleClose)

  const closeButtonRef = useRef()
  useHoverCursor(closeButtonRef)

  const lineItemsRef = useRef()
  const couponRef = useRef()
  const totalsRef = useRef()
  const cartFooterRef = useRef()
  const timelineRef = useRef()

  useEffect(() => {
    if (lineItemsRef.current && !timelineRef.current) {
      timelineRef.current = gsap.timeline()
    }
  }, [])

  useEffect(() => {
    if (timelineRef.current) {
      timelineRef.current = gsap.timeline()
      timelineRef.current.to([...lineItemsRef.current.children, couponRef.current, totalsRef.current], {
        xPercent: 0,
        stagger: 0.06,
        delay: 0.15,
        duration: 1,
        ease: 'expo.out',
        onComplete: () => {
          timelineRef.current.to(cartFooterRef.current, {
            yPercent: 0,
            opacity: 1,
            duration: 0.5,
            ease: 'expo.out'
          })
        }
      })
      gsap.set([...lineItemsRef.current.children, couponRef.current, totalsRef.current], { xPercent: 105 })
      gsap.set(cartFooterRef.current, { yPercent: 100, opacity: 0 })
    }
  }, [lineItemCount])

  useEffect(() => {
    if (timelineRef.current) {
      if (open) {
        timelineRef.current.play()
      } else {
        timelineRef.current.reverse()
      }
    }
  }, [open])

  return (
    <section className={cn(classes.panel, { open })} ref={containerRef}>
      <div className={classes.container}>
        <div className={classes.cartMain}>
          <header className={classes.header}>
            <h4 className={classes.title}>
              {title}
              <div className={cn(classes.lineItemCount, { show: lineItemCount, small: lineItemCount >= 10 })}>
                <span>{lineItemCount}</span>
              </div>
            </h4>
            <button
              className={classes.closeButton}
              aria-label='close'
              onClick={handleClose}
              ref={closeButtonRef}
            >
              <span>Close</span>
            </button>
          </header>
          {!empty && (
            <>
              <div className={classes.products} ref={lineItemsRef}>
                {lineItems.map(lineItem => (<LineItem key={lineItem.id} lineItem={lineItem} />))}
              </div>
              {couponCopy && !isEmpty(couponCopy.text) && (
                <div className={classes.coupon} ref={couponRef}>
                  <RichText content={couponCopy.text} />
                </div>
              )}
              <div className={classes.cartTotals} ref={totalsRef}>
                {discount ? (
                  <>
                    <div className={cn(classes.totalsFigure, classes.subtotal)}>
                      <span className={classes.figureLabel}>Subtotal</span>
                      <span className={classes.price}>{formatPrice(lineItemsSubtotalPrice)}</span>
                    </div>
                    <div className={cn(classes.totalsFigure, classes.discount)}>
                      <span className={classes.figureLabel}>Discount</span>
                      <span className={classes.price}>{formatPrice(discount)}</span>
                    </div>
                    <div className={cn(classes.totalsFigure, classes.total)}>
                      <span className={classes.figureLabel}>Total</span>
                      <span className={classes.price}>{formatPrice(totalAmount)}</span>
                    </div>
                  </>
                ) : (
                  <div className={cn(classes.totalsFigure, classes.total)}>
                    <span className={classes.figureLabel}>Total</span>
                    <span className={classes.price}>{formatPrice(totalAmount)}</span>
                  </div>
                )}
              </div>
            </>
          )}
          {empty && (
            <div className={classes.empty}>
              {emptyCartCopy && !isEmpty(emptyCartCopy.text) ? (
                <RichText content={emptyCartCopy.text} />
              ) : (
                <span>Your cart is empty!</span>
              )}
            </div>
          )}
        </div>
        <div ref={cartFooterRef}>
          <CartFooter
            cartIsEmpty={empty}
            postcodeValid={postcodeValid}
            setPostcodeValid={setPostcodeValid}
          />
        </div>
      </div>
    </section>
  )
})

const useStyles = createUseStyles({
  panel: {
    position: 'fixed',
    transform: 'translate3d(100%,0,0)',
    transition: `transform 1s ${expo.out}`,
    top: 0,
    bottom: 0,
    right: 0,
    zIndex: theme.zIndex.cart,
    background: 'white',
    borderLeft: [1, 'solid', theme.colors.primary],
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      width: span(10, 'md')
    },
    '&:after': {
      content: '""',
      width: 'calc(100% + 20rem)',
      height: 'calc(100% + 20rem)',
      backgroundPosition: '50%',
      position: 'absolute',
      left: '-10rem',
      top: '-10rem',
      backgroundImage: `url(${noise})`,
      animation: '$noise 1s steps(2) infinite',
      opacity: 0.6,
      zIndex: -1
    },
    '&.open': {
      transform: 'translate3d(0,0,0)'
    }
  },
  '@keyframes noise': {
    ...theme.noiseAnimation
  },
  container: {
    height: '100%',
    maxHeight: `calc(100% - ${spanWithoutCalc(2)})`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    margin: [span(1), span(1)],
    color: theme.colors.primary,
    [theme.breakpoints.up('md')]: {
      margin: [span(1, 'md'), span(1, 'md')],
      maxHeight: `calc(100% - ${spanWithoutCalc(2, 'md')})`
    }
  },
  cartMain: {
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    flex: '1'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 42,
    [theme.breakpoints.up('md')]: {
      marginBottom: 50
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 auto',
    marginBottom: 0
  },
  closeButton: {
    cursor: 'pointer',
    extend: [theme.global[theme.headingsSelector], theme.global.h6],
    marginBottom: 0
  },
  lineItemCount: {
    marginLeft: vw(10, 'desktop'),
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    color: theme.colors.white,
    backgroundColor: theme.colors.primary,
    height: vw(26, 'desktop'),
    width: vw(26, 'desktop'),
    borderRadius: '50%',
    transform: 'scale(0)',
    fontSize: vw(16, 'desktop'),
    transition: `transform 0.25s ${quad.out}`,
    '&.show': {
      transition: `transform 0.5s ${back.out}`,
      transform: 'scale(1)'
    },
    '&.small': {
      fontSize: vw(10),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(10, 'desktop')
      }
    },
    '& span': {
      fontFamily: theme.fonts.body,
      fontWeight: theme.fonts.bodyFontWeight,
      lineHeight: 1,
      paddingBottom: '0.2em'
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  products: {
    flex: 1,
    overflowY: 'auto',
    borderBottom: [1, 'solid', theme.colors.border],
    marginBottom: span(1),
    paddingBottom: span(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(30, 'desktop'),
      paddingBottom: vw(30, 'desktop')
    }
  },
  coupon: {
    marginBottom: 30,
    padding: [30],
    border: [1, 'solid', theme.colors.border],
    fontFamily: theme.fonts.headings,
    fontWeight: theme.fonts.headingsFontWeight,
    lineHeight: 1.4,
    fontSize: vw(12),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(12, 'desktop')
    }
  },
  empty: {
    // extend: [theme.global[theme.headingsSelector], theme.global.h5]
  },
  cartTotals: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  totalsFigure: {

  },
  figureLabel: {
    extend: [theme.global[theme.headingsSelector], theme.global.h6],
    marginRight: 8
  },
  price: {
    display: 'inline-block',
    extend: [theme.global[theme.headingsSelector], theme.global.h4]
  }
}, { name: 'CartDialog' })

export default CartDialog
