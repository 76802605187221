import React from 'react'
import { createUseStyles } from 'react-jss'
import { sliceMarginStyles } from '../../style/span'

const List = ({ className, children }) => {
  const classes = useStyles()

  return (
    <section className={classes.section}>
      {children}
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    extend: [sliceMarginStyles]
  }
}, { name: 'List' })

export default List
