import inDOM from 'dom-helpers/canUseDOM'
import { ActionType } from 'redux-promise-middleware'
import {
  transitionContentDelayed,
  transitionComplete
} from '../actions'
import { pageContentActionCreator as pageAction } from '../slices/content/page'
import gsap from 'gsap'
import delay from 'lodash/delay'
import { updateScroll } from 'redux-first-router'
import { setPageYOffset, scrollbar } from '../../components/useSmoothScrollbar'
import afterFrame from '../../helpers/afterFrame'
import { getStateStorage } from './PauseableSessionStorage'

var pendingContentFulfilledAction
var transitioningOut = false

const updateScrollPosition = () => {
  if (scrollbar) {
    const storage = getStateStorage()
    const scrollState = storage.read(window.history.state || {}, null)
    if (scrollState) {
      const [, y] = scrollState
      setPageYOffset(y)
    } else {
      setPageYOffset(0)
    }
  } else {
    updateScroll()
  }
}

const transitionOut = (store) => {
  getStateStorage().pause()

  transitioningOut = true
  const curtain = document.getElementById('curtain')
  const tl = gsap.timeline()
  gsap.set(curtain, { opacity: 1 })
  tl.fromTo(curtain, { y: '100%' }, {
    y: '0%',
    duration: 0.8,
    ease: 'quart.in',
    onComplete: () => {
      transitioningOut = false
      delay(() => { store.dispatch(transitionComplete()) }, 0.2)
    }
  })
}

const transitionIn = () => {
  // Before we transition in, we need to update the scroll position
  updateScrollPosition()

  const curtain = document.getElementById('curtain')
  const tl = gsap.timeline()
  tl.to(curtain, {
    y: '-100%',
    ease: 'expo.out',
    duration: 1,
    delay: 0.2,
    onComplete: () => {
      gsap.set(curtain, { opacity: 0 })
      getStateStorage().resume()
    }
  })
}

export default store => next => action => {
  if (inDOM) {
    if (action.type === pageAction.toString()) {
      transitionOut(store)
    }

    if (action.type === `${pageAction}_${ActionType.Fulfilled}`) {
      // If we are no longer transition out then we just need to pass the action on
      if (transitioningOut) {
        pendingContentFulfilledAction = action
        return next(transitionContentDelayed())
      } else {
        afterFrame(transitionIn)
      }
    }

    if (action.type === transitionComplete.toString()) {
      if (pendingContentFulfilledAction) {
        const result = next(pendingContentFulfilledAction)
        pendingContentFulfilledAction = null
        afterFrame(transitionIn)
        return result
      }
    }
  }
  return next(action)
}
