import inDOM from 'dom-helpers/canUseDOM'
import { ActionType } from 'redux-promise-middleware'
import { rehydrated, queryStringChangeCreator, transitionComplete } from '../../actions'

import { pageContentActionCreator } from '../../slices/content/page'
import { pagesLoadMoreActionCreator } from '../../slices/content/pages'

import { fetchPages } from '../../api'

import { GetPageListSlice, getPagesQueryStringArguments } from '../../slices/content/selectors'

export const PAGE_SIZE = 5

export default store => next => action => {
  if (!inDOM) {
    return next(action)
  }
  const ret = next(action)
  const pageListSlice = GetPageListSlice(store.getState())
  if (pageListSlice) {
    const slug = pageListSlice.parent.slug
    const { page } = getPagesQueryStringArguments(store.getState())
    if (action.type === rehydrated.toString() ||
      action.type === `${pageContentActionCreator}_${ActionType.Fulfilled}` ||
      action.type === transitionComplete.toString()) {
      fetchPages(store.dispatch, store.getState, pageListSlice.id, { slug, page: 0, limit: PAGE_SIZE * (page + 1) })
    }

    if (action.type === pagesLoadMoreActionCreator.toString()) {
      store.dispatch(queryStringChangeCreator(store.getState(), { page: page + 1 }))
      fetchPages(store.dispatch, store.getState, pageListSlice.id, { slug, page: page + 1, limit: PAGE_SIZE }, true)
    }
  }
  return ret
}
