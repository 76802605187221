/* eslint-env browser */
import merge from 'lodash/merge'
import get from 'lodash/get'
import find from 'lodash/find'
import map from 'lodash/map'
import remove from 'lodash/remove'
import forEach from 'lodash/forEach'
import { ActionType } from 'redux-promise-middleware'
import {
  rehydrated,
  clearCart,
  addToCart,
  incrementLineItemQuantity,
  decrementLineItemQuantity,
  removeFromCart,
  cartActionCreator
} from '../actions'
import { getCartId, getCartLineItems, isCartCompleted } from '../slices/cart'
import {
  fetchCart,
  sendCreateCart,
  sendUpdateCartLineItems
} from '../api'

const cartStorageKey = 'discoveredFoodsCart1'

function getLocalStorageObject (key) {
  const raw = localStorage.getItem(key)
  return raw ? JSON.parse(raw) : undefined
}

function getLocalStorageCart (getState) {
  return getLocalStorageObject(cartStorageKey)
}

function updateLocalStorageCart (getState) {
  const id = getCartId(getState())
  if (!id) {
    return
  }
  const cart = merge(getLocalStorageCart(getState) || {}, { id })
  localStorage.setItem(cartStorageKey, JSON.stringify(cart))
}

function removeLocalStorageCart (getState) {
  localStorage.removeItem(cartStorageKey)
}

function alterCartLineItemsQuantity (dispatch, getState, items) {
  const lineItems = map(getCartLineItems(getState()), ({ variant, quantity }) => ({
    variantId: variant.id,
    quantity
  }))

  forEach(items, ({ variantId, numberToAdd }) => {
    const lineItem = find(lineItems, { variantId })
    if (lineItem) {
      lineItem.quantity += numberToAdd
      if (lineItem.quantity <= 0) {
        remove(lineItems, { variantId })
      }
    } else if (numberToAdd > 0) {
      lineItems.push({ variantId, quantity: numberToAdd })
    }
  })

  sendUpdateCartLineItems(dispatch, getState, lineItems)
}

export default store => next => action => {
  if (action.type === addToCart.toString()) {
    const id = getCartId(store.getState())
    const { variantId, quantity } = action.payload
    if (id) {
      alterCartLineItemsQuantity(store.dispatch, store.getState, [{ variantId, numberToAdd: quantity }])
    } else {
      sendCreateCart(store.dispatch, store.getState, [{ variantId, quantity: quantity }])
    }
  }
  if (action.type === incrementLineItemQuantity.toString()) {
    alterCartLineItemsQuantity(store.dispatch, store.getState, [{ variantId: action.payload, numberToAdd: 1 }])
  }
  if (action.type === decrementLineItemQuantity.toString()) {
    alterCartLineItemsQuantity(store.dispatch, store.getState, [{ variantId: action.payload, numberToAdd: -1 }])
  }
  if (action.type === removeFromCart.toString()) {
    const lineItems = getCartLineItems(store.getState())
    const quantity = get(find(lineItems, ({ variant }) => get(variant, ['id']) === action.payload), ['quantity'])
    if (quantity) {
      alterCartLineItemsQuantity(store.dispatch, store.getState, [{ variantId: action.payload, numberToAdd: -quantity }])
    }
  }

  const ret = next(action)

  if (action.type === rehydrated.toString()) {
    const cart = getLocalStorageCart(store.getState)
    const id = get(cart, ['id'])
    if (id) {
      fetchCart(store.dispatch, store.getState, id)
    }
  }

  if (action.type === `${cartActionCreator.toString()}_${ActionType.Fulfilled}`) {
    const cartId = getCartId(store.getState())
    if (cartId) {
      const completed = isCartCompleted(store.getState())
      if (!completed) {
        updateLocalStorageCart(store.getState)
      } else {
        removeLocalStorageCart(store.getState)
        store.dispatch(clearCart())
      }
    } else {
      // null returned from GET means the cart does not exist
      removeLocalStorageCart(store.getState)
    }
  }
  return ret
}
