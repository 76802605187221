import React from 'react'
import { createUseStyles } from 'react-jss'

const AnchorPoint = ({ className, slice }) => {
  const { title, anchor_id: anchorId, page_number: pageNumber } = slice
  const classes = useStyles()

  return (
    <section className={classes.section} id={anchorId} data-page-number={pageNumber} data-title={title} />
  )
}

const useStyles = createUseStyles({
  section: {
    height: 0
  }
}, { name: 'AnchorPoint' })

export default AnchorPoint
