import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import get from 'lodash/get'
import { getProducts } from '../../redux/slices/content'
import List from '../List'
import Tile from '../List/Tile'
import { resolveLink } from '../../helpers/resolveLink'
import ColorContext from '../ColorContext'

const ProductListSlice = ({ className }) => {
  const products = useSelector(getProducts)
  const classes = useStyles()
  const { borderColor } = useContext(ColorContext)

  return (
    <List>
      {products.map(({ title, image, link, copy, coming_soon: comingSoon }, i) => (
        <Tile key={i} title={title} image={image} copy={copy} showLink link={resolveLink(get(link, [0]))} borderColor={borderColor}>
          {comingSoon && <span className={cn(classes.link, classes.comingSoon)}>Coming Soon</span>}
        </Tile>
      ))}
    </List>
  )
}

const useStyles = createUseStyles({
  comingSoon: {
    opacity: 0.2
  }
}, { name: 'ProductListSlice' })

export default ProductListSlice
