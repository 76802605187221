import React, { useRef } from 'react'
import get from 'lodash/get'
import RouterLink from 'redux-first-router-link'
import toUrl from 'redux-first-router-link/dist/toUrl'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import compact from 'lodash/compact'
import { getRoutesMap } from '../redux/slices/location'
import { isExternalUrl, isPathAbsolute } from '../helpers/url'
import { useHoverCursor } from './Cursor'

// RouterLink's propTypes say its tagName prop should only be a string but there's no reason for it not to allow a
// component. Silence those warnings...
if (process.env.NODE_ENV !== 'production') {
  delete RouterLink.WrappedComponent.propTypes.tagName
}
const AWithRef = ({ withRef, withHref, ...props }) => <a {...props} ref={withRef} href={withHref} />

function fixExternalLinks ({ target, rel } = {}) {
  if (!target) {
    target = '_blank'
  }

  if (target !== '_self') {
    rel = 'noopener noreferrer' // https://mathiasbynens.github.io/rel-noopener/
  }

  return { target, rel }
}

const isRoutedLink = url => typeof url === 'object' || !(
  isExternalUrl(url) ||
  url.substr(0, 1) === '#' ||
  url.substr(0, 8) === '/static/'
)

const sanitizeUrl = (url) => {
  if (!url || typeof url === 'object') {
    return url
  }
  if (!isPathAbsolute(url)) {
    // Fix the absolute urls, there is an issue in redux-first-router-link that causes and infinite loop
    // when you have a relative url
    url = 'https://' + url
  }
  if (isExternalUrl(url)) {
    try {
      const targetUrl = new URL(url)
      const baseUrl = new URL(process.env.REACT_APP_CANONICAL_URL_BASE)
      if (targetUrl.host === baseUrl.host) {
        if (targetUrl.search) {
          return `${targetUrl.pathname}${targetUrl.search}`
        }
        return targetUrl.pathname
      }
    } catch (e) {
      console.warn(e)
    }
  }
  return url
}

const Link = React.forwardRef(function Link ({
  children,
  defaultText,
  link,
  to,
  nonLinkTag = React.Fragment,
  disabled = false,
  ...rest
}, ref) {
  const routesMap = useSelector(getRoutesMap)
  // Need to handle missing and empty links gracefully
  const hasLink = !!(to || get(link, ['url']))
  const url = sanitizeUrl(to || get(link, ['url']))
  const Tag = hasLink ? (
    isRoutedLink(url) ? RouterLink : 'a'
  ) : nonLinkTag
  const props = {}
  if (Tag !== React.Fragment) {
    Object.assign(props, rest)
  }
  if (Tag === RouterLink) {
    props.tagName = AWithRef
    props.withRef = ref
  } else {
    props.ref = ref
  }
  if (hasLink) {
    if (Tag === RouterLink) {
      props.to = url
      // Because we’re passing tagName to RouterLink, it will not set an href for us on the <a> so we need to pass the
      // href in as a prop that will be passed through by RouterLink - it captures the url, to and href for itself.
      props.withHref = toUrl(props.to, routesMap)
      if (props.target === '_blank') {
        // Routed links shouldn’t open in a new window. This can happen if CMS authors input an absolute url into the
        // CMS as an external link instead of an internal link.
        delete props.target
      }
    } else {
      props.href = url
      Object.assign(props, fixExternalLinks(link || { url: to, ...rest }))
    }
  }
  if (disabled) {
    Object.assign(props, { onClick: (e) => e.preventDefault() })
    delete props.href
  }
  const hasChildren = !isEmpty(compact(React.Children.toArray(children)))
  return (
    <Tag {...props}>
      {hasChildren ? children : get(link, ['text']) || defaultText}
    </Tag>
  )
})

export const LinkWithCursorHoverEffect = ({ link, ...props }) => {
  const ref = useRef()
  useHoverCursor(ref, !!link)
  return <Link link={link} {...props} ref={ref} />
}

export default Link
