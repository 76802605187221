import React, { forwardRef, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import get from 'lodash/get'
import theme from '../../style/theme'
import { span } from '../../style/span'
import { ReactComponent as InstagramIcon } from '../../images/instagram.svg'
import Link from '../Link'
import { getSocialLinks } from '../../redux/slices/content'
import { useSelector } from 'react-redux'
import { vw } from '../../style/vw'
import ScrollIndicator from './ScrollIndicator'
import { useHoverCursor } from '../Cursor'

function getSocialIcon (url) {
  if (url.indexOf('instagram') >= 0) {
    return InstagramIcon
  }
}

const SocialLink = ({ link }) => {
  const Icon = getSocialIcon(get(link, ['url']))
  const srOnly = get(link, ['text'])
  const classes = useStyles()
  const ref = useRef()
  useHoverCursor(ref)
  return (
    <Link className={classes.socialLink} link={link} ref={ref}>
      {srOnly && <span className='srOnly'>{srOnly}</span>}
      {<Icon className={classes.icon} />}
    </Link>
  )
}

const Sidebar = forwardRef(({ className }, ref) => {
  const classes = useStyles()
  const socialLinks = useSelector(getSocialLinks)
  return (
    <aside className={classes.aside} ref={ref}>
      <div className={classes.scrollIndicatorContainer}>
        <ScrollIndicator />
      </div>
      <div className={classes.socialLinks}>
        {socialLinks && socialLinks.map((link, i) => <SocialLink key={i} link={link} />)}
      </div>
    </aside>
  )
})

const useStyles = createUseStyles({
  aside: {
    color: theme.colors.white,
    mixBlendMode: 'difference',
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: span(1),
    zIndex: theme.zIndex.sidebar,
    display: 'flex',
    flexDirection: 'column',
    pointerEvents: 'none',
    [theme.breakpoints.up('md')]: {
      width: span(1, 'md'),
      right: span(1, 'md')
    }
  },
  scrollIndicatorContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  socialLinks: {
    pointerEvents: 'all',
    flexGrow: 0,
    display: 'none',
    position: 'absolute',
    bottom: 0,
    right: 0,
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  socialLink: {
    display: 'block',
    width: vw(22),
    margin: [0, 0, span(1), 'auto'],
    [theme.breakpoints.up('md')]: {
      margin: [0, 0, span(1, 'md'), 'auto'],
      width: vw(22, 'desktop')
    }
  }
}, { name: 'Sidebar' })

export default Sidebar
