/* global fetch */
/* global localStorage */
import React, { useCallback, useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import cn from 'classnames'
// import { addToCart } from '../../../redux/actions/cart'
import { isCartBusy } from '../../../redux/slices/cart'
import { getCartBuyButtonLabels, getProductDefaultsByHandle } from '../../../redux/slices/content'
import { openCartDialog, isCurrentBreakpointMobile } from '../../../redux/slices/layout'
import { createUseStyles } from 'react-jss'
import theme from '../../../style/theme'
import { vw } from '../../../style/vw'
import { formatQuantity } from '../../../helpers/format'
import { useHoverCursor } from '../../Cursor'

const BuyButton = ({ product, variantId, className, text, quantity, setQuantity, sellingPlanId, ...rest }) => {
  const dispatch = useDispatch()
  const busy = useSelector(isCartBusy)
  const labels = useSelector(getCartBuyButtonLabels)
  const addToCartLabel = get(useSelector(getProductDefaultsByHandle), [get(product, ['slug']), 'add_to_cart_label'], labels.addToCartLabel)
  const initialText = !get(product, ['availableForSale'], false) ? labels.outOfStockLabel : (text || addToCartLabel)
  const [state, setState] = useState(initialText)
  // const [quantity, setQuantity] = useState(1)
  const buttonRef = useRef()
  const incrementRef = useRef()
  const decrementRef = useRef()
  const clicked = useRef()
  const classes = useStyles()
  const isMobile = useSelector(isCurrentBreakpointMobile)
  const [isAddedToCart, setIsAddedToCart] = useState(false)
  const [isAddingToCart, setIsAddingToCart] = useState(false)

  useHoverCursor(buttonRef)
  useHoverCursor(incrementRef)
  useHoverCursor(decrementRef)

  const disable = state !== initialText || !product.availableForSale

  const handleIncrement = useCallback(() => {
    if (quantity < 99) setQuantity(quantity + 1)
  }, [quantity, setQuantity])

  const handleDecrement = useCallback(() => {
    if (quantity > 1) setQuantity(quantity - 1)
  }, [quantity, setQuantity])

  const handleCartClick = () => {
    const cartId = localStorage.getItem('cartId')
    let cartUrl = 'https://discovered-wildfoods.myshopify.com/pages/food-cart'

    if (cartId) {
      cartUrl += `?cartId=${cartId}`

      // Append selling plan IDs as query parameters
      const cartLines = JSON.parse(localStorage.getItem('cartLines') || '[]')
      cartLines.forEach((line, index) => {
        // Ensure that each line item ID and selling plan ID is unique and correctly paired
        cartUrl += `&spId${index}=${line.sellingPlanId}&lineId${index}=${encodeURIComponent(line.lineItemId)}`
      })
    }

    // console.log(cartUrl)
    window.location.href = cartUrl
  }

  const onClick = useCallback(async () => {
    if (!disable) {
      setIsAddingToCart(true)
      clicked.current = true

      const base64VariantId = Buffer.from(`gid://shopify/ProductVariant/${variantId}`).toString('base64')
      const base64SellingPlanId = sellingPlanId ? Buffer.from(`gid://shopify/SellingPlan/${sellingPlanId}`).toString('base64') : null

      const lineItem = {
        merchandiseId: base64VariantId,
        quantity: quantity
      }
      if (base64SellingPlanId) {
        lineItem.sellingPlanId = base64SellingPlanId
      }

      const existingCartId = localStorage.getItem('cartId')

      const data = {
        cartId: existingCartId,
        lines: [lineItem]
      }

      try {
        const response = await fetch('https://api-server.discoveredfoods.com/api/addToCart', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data)
        })

        // Handle response
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }
        const responseData = await response.json()
        // console.log('Response from server:', responseData)

        const existingCartLines = JSON.parse(localStorage.getItem('cartLines')) || []
        // console.log('Before Loop - Existing Cart Lines:', existingCartLines)

        if (responseData && responseData.data && responseData.data.cartLinesAdd && responseData.data.cartLinesAdd.cart) {
          const newCartId = responseData.data.cartLinesAdd.cart.id
          localStorage.setItem('cartId', newCartId)

          responseData.data.cartLinesAdd.cart.lines.edges.forEach(edge => {
            const newCartLine = {
              lineItemId: edge.node.id,
              sellingPlanId: sellingPlanId
            }

            const existingLineIndex = existingCartLines.findIndex(line => line.lineItemId === newCartLine.lineItemId)

            // console.log('existing line index -- ' + existingLineIndex)

            if (existingLineIndex === -1) {
              existingCartLines.push(newCartLine)
              // console.log('adding new line')
            } else {
              existingCartLines[existingLineIndex].quantity += newCartLine.quantity
              // console.log('adding quantity')
            }

            // console.log('Mid Loop - Existing Cart Lines:', existingCartLines)
            // console.log('New cart line:', JSON.stringify(newCartLine, null, 2))
          })

          // console.log('After Loop - Existing Cart Lines:', existingCartLines)

          // Store the updated array back in localStorage
          localStorage.setItem('cartLines', JSON.stringify(existingCartLines))

          setIsAddedToCart(true)
          setTimeout(() => setIsAddedToCart(false), 10000)
          setIsAddingToCart(false)
        }
      } catch (error) {
        console.error('Error adding to cart:', error)
        setIsAddingToCart(false)
      }
    }
  }, [disable, variantId, quantity, sellingPlanId, isMobile])

  useEffect(() => {
    if (clicked.current) {
      if (busy) {
        // This locks the width so that it does not change when the text changes
        setState(labels.addingToCartLabel)
      } else {
        setState(labels.addedToCartLabel)
        setState(initialText)
        clicked.current = false
        // On mobile we may have  a popup that show when they add the item to the cart
        // and this will interfere with the card dialog if we try and open it
        dispatch(openCartDialog())
      }
    }
  }, [busy])

  // When isAddedToCart changes to true, set it back to false after 3 seconds
  useEffect(() => {
    if (isAddedToCart) {
      const timer = setTimeout(() => {
        setIsAddedToCart(false)
      }, 600000)

      return () => clearTimeout(timer) // Clear the timer if the component unmounts
    }
  }, [isAddedToCart])

  return (
    <div className={cn(classes.container, className)}>
      <div className={classes.quantitySelector}>
        <button className={classes.adjustButton} onClick={handleDecrement} aria-label='Decrement Product'>
          <span>-</span>
        </button>
        <span className={classes.quantityLabel}>{formatQuantity(quantity)}</span>
        <button className={classes.adjustButton} onClick={handleIncrement} aria-label='Increment Product'>
          <span>+</span>
        </button>
      </div>
      <button
        onClick={onClick}
        className={cn(classes.button, { disable })}
        {...rest}
      >
        <div className={cn(classes.buttonText, classes.buyText)}>{initialText}</div>
      </button>
      {isAddingToCart && (
        <div className={classes.loader1}>
          <div className='ripple' />
        </div>
      )}
      {isAddedToCart && (
        <div className={classes.addedtocarttxt}>
          <p>Product Added to Cart <a href='#' onClick={handleCartClick}>View Cart</a></p>
        </div>
      )}
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    display: 'flex'
  },
  addedtocarttxt: {
    '& p': {
      margin: 0,
      fontSize: '0.8vw',
      textTransform: 'uppercase',
      paddingLeft: '5px',
      paddingRight: '5px',
      paddingTop: '0',
      position: 'absolute',
      bottom: '-1vw',
      left: 0,
      background: '#424e4b3b'
    },
    '& a': {
      margin: 0,
      fontSize: '0.8vw',
      textDecoration: 'underline'
    }
  },
  quantitySelector: {
    display: 'flex',
    alignItems: 'stretch',
    marginRight: 20
  },
  quantityButton: {
    color: theme.colors.primary,
    minWidth: 37,
    minHeight: 37,
    background: 'none',
    outline: 'none',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: vw(14),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'desktop')
    }
  },
  quantityLabel: {
    composes: '$quantityButton',
    border: [1, 'solid', theme.colors.primary],
    borderLeft: 'none',
    borderRight: 'none',
    margin: 0
  },
  adjustButton: {
    composes: '$quantityButton',
    border: [1, 'solid', theme.colors.primary],
    cursor: 'pointer',
    '&:hover': {
      opacity: 1
    }
  },
  button: {
    display: 'inline-block',
    minWidth: 96,
    textDecoration: 'none',
    padding: 0,
    margin: 0,
    overflow: 'hidden',
    position: 'relative',
    cursor: 'pointer',
    border: [1, 'solid', theme.colors.primary],
    '&.disable': {
      opacity: 0.2,
      cursor: 'not-allowed'
    }
  },
  buttonText: {
    textTransform: 'uppercase',
    display: 'block',
    fontFamily: theme.fonts.body,
    fontWeight: theme.fonts.bodyFontWeight,
    fontSize: vw(14),
    lineHeight: 'calc(22/18)',
    textAlign: 'center',
    padding: [10, 32],
    color: theme.colors.primary,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'desktop')
    }
  },
  buyText: {
    composes: '$buttonText',
    '&.hide': {
      visibility: 'hidden'
    }
  },
  buyingText: {
    composes: '$buttonText',
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    textAlign: 'center',
    transition: 'none',
    '&.hide': {
      display: 'none'
    }
  },
  // Loader styles
  loader1: {
    margin: '0px',
    boxSizing: 'border-box',
    height: '32px',
    width: '32px',
    position: 'relative',
    top: '20px',
    left: '23px',
    '& .ripple': {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: '170px',
      right: '170px',
      margin: 'auto',
      height: '32px',
      width: '32px',
      '&:before, &:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        boxSizing: 'border-box',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        margin: 'auto',
        height: '32px',
        width: '32px',
        border: '2px solid #38524F', // Replaced $border with its value
        borderRadius: '50%',
        opacity: 1,
        animation: '$loader1Ripple 3s cubic-bezier(0.075, 0.82, 0.165, 1) infinite'
      },
      '&:after': {
        animation: '$loader1Ripple2 3s cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s infinite'
      }
    }
  },
  '@keyframes loader1Ripple': {
    '0%': {
      transform: 'translate3d(0, 0, 0) scale(0)',
      opacity: 1
    },
    '20%': {
      opacity: 1
    },
    '100%': {
      transform: 'translate3d(0, 0, 0) scale(1.5)',
      opacity: 0
    }
  },
  '@keyframes loader1Ripple2': {
    '0%': {
      transform: 'translate3d(0, 0, 0) scale(0)',
      opacity: 1
    },
    '20%': {
      opacity: 1
    },
    '100%': {
      transform: 'translate3d(0, 0, 0) scale(1)',
      opacity: 0
    }
  },
  '@media (max-width: 600px)': {
    addedtocarttxt: {
      '& p': {
        margin: 0,
        fontSize: '15px',
        textTransform: 'uppercase',
        paddingLeft: '5px',
        paddingRight: '5px',
        paddingTop: '0px',
        position: 'absolute',
        bottom: '-36px',
        left: 0,
        background: '#424e4b3b'
      },
      '& a': {
        margin: 0,
        fontSize: '15px',
        textDecoration: 'underline'
      }
    }
  }
})

export default BuyButton
