import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { sliceMarginStyles, span, sliceMarginSpan } from '../../style/span'
import theme from '../../style/theme'
import { vw } from '../../style/vw'
import SplitWordsAnimation from '../SplitWordsAnimation'
import ColorContext from '../ColorContext'

const QuoteSlice = ({ className, slice }) => {
  const { text } = slice
  const { borderColor = 'currentColor' } = useContext(ColorContext)
  const classes = useStyles({ borderColor })

  return (
    <section className={classes.section}>
      <SplitWordsAnimation linesOnly>
        <blockquote className={classes.quote}>{text}</blockquote>
      </SplitWordsAnimation>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    extend: [sliceMarginStyles],
    borderBottom: ({ borderColor }) => `1px solid ${borderColor}`
  },
  quote: {
    margin: [0, span(1), span(sliceMarginSpan)],
    fontFamily: theme.fonts.headings,
    fontWeight: theme.fonts.headingsFontWeight,
    fontSize: vw(36),
    lineHeight: 1.1,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(72, 'desktop'),
      margin: [0, span(3, 'md'), span(sliceMarginSpan, 'md')],
      maxWidth: span(16, 'md')
    }
  }
}, { name: 'QuoteSlice' })

export default QuoteSlice
