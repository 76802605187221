/* global localStorage */
import React, { useRef, useCallback, forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import Link from './Link'
import theme from '../style/theme'
import { span } from '../style/span'
import { vw } from '../style/vw'
import { quad, back } from '../style/eases'
import { useStickyCursor, useHoverCursor } from './Cursor'
import { useDispatch, useSelector } from 'react-redux'
import {
  isMenuOpen,
  openMenu,
  closeMenu,
  // toggleCartDialog,
  isCartDialogOpen,
  isCurrentBreakpointMobile
} from '../redux/slices/layout'
import { getCartLineItemsCount } from '../redux/slices/cart'
import useReversingLottieAnimation from '../hooks/useReversingLottieAnimation'
import logoData from '../animation/logo.json'

const aspectRatio = logoData.w / logoData.h

const Header = forwardRef((props, ref) => {
  const menuOpen = useSelector(isMenuOpen)
  const classes = useStyles({ menuOpen })
  const linkRef = useRef()
  const menuRef = useRef()
  const dispatch = useDispatch()
  const logoRef = useReversingLottieAnimation(logoData, true, 0.7)
  const cartBtnRef = useRef()
  const isCartOpen = useSelector(isCartDialogOpen)
  const lineItems = useSelector(getCartLineItemsCount)
  const isMobile = useSelector(isCurrentBreakpointMobile)

  useStickyCursor(linkRef)
  useHoverCursor(menuRef)
  useHoverCursor(cartBtnRef)

  const toggleMenu = useCallback(() => {
    dispatch(menuOpen ? closeMenu() : openMenu())
  }, [menuOpen])

  // const handleOpenMiniCart = useCallback(() => {
  //   dispatch(toggleCartDialog())
  // }, [])

  const handleCartClick = () => {
    const cartId = localStorage.getItem('cartId')
    let cartUrl = 'https://discovered-wildfoods.myshopify.com/pages/food-cart'

    if (cartId) {
      cartUrl += `?cartId=${cartId}`

      // Append selling plan IDs as query parameters
      const cartLines = JSON.parse(localStorage.getItem('cartLines') || '[]')
      cartLines.forEach((line, index) => {
        // Ensure that each line item ID and selling plan ID is unique and correctly paired
        cartUrl += `&spId${index}=${line.sellingPlanId}&lineId${index}=${encodeURIComponent(line.lineItemId)}`
      })
    }

    // console.log(cartUrl)
    window.location.href = cartUrl
  }

  return (
    <header className={cn(classes.header, { blend: !menuOpen })} ref={ref}>
      <Link to='/' ref={linkRef}>
        <span className={classes.logo} ref={logoRef} />
      </Link>
      <div className={classes.menuItems}>
        <button className={cn(classes.menuButton, classes.cartButton)} onClick={handleCartClick} aria-label='Open Cart' ref={cartBtnRef}>
          <span>{isCartOpen ? 'Cart' : 'Cart'}</span>
          {!isMobile && (
            <div className={cn(classes.lineItems, { show: lineItems, small: lineItems >= 10 })}>
              <span>{lineItems}</span>
            </div>
          )}
        </button>
        <button className={classes.menuButton} ref={menuRef} onClick={toggleMenu}>
          <span>{menuOpen ? 'Open' : 'Menu'}</span>
        </button>
      </div>
    </header>
  )
})

const useStyles = createUseStyles({
  header: {
    pointerEvents: 'none',
    zIndex: theme.zIndex.header,
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    margin: [span(1), span(1), 0],
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.colors.white,
    [theme.breakpoints.up('md')]: {
      margin: [span(1, 'md'), span(1, 'md'), 0]
    },
    '&.blend': {
      mixBlendMode: 'difference'
    },
    '& > *': {
      pointerEvents: 'all'
    }
  },
  menuButton: {
    color: 'currentColor',
    fontFamily: theme.fonts.headings,
    fontWeight: theme.fonts.headingsFontWeight,
    fontSize: vw(16),
    letterSpacing: '0.02em',
    [theme.breakpoints.up('md')]: {
      fontSize: vw(16, 'desktop')
    }
  },
  logo: {
    display: 'block',
    height: vw(30),
    width: vw(30 * aspectRatio),
    [theme.breakpoints.up('md')]: {
      height: vw(30, 'desktop'),
      width: vw(30 * aspectRatio, 'desktop')
    }
  },
  menuItems: {
    display: 'flex',
    alignItems: 'center'
  },
  cartButton: {
    display: 'flex',
    alignItems: 'center',
    marginRight: span(0.75),
    visibility: ({ menuOpen }) => menuOpen ? 'hidden' : 'visible',
    [theme.breakpoints.up('md')]: {
      marginRight: span(0.5)
    }
  },
  lineItems: {
    display: 'none',
    marginLeft: vw(6),
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    color: theme.colors.primary,
    backgroundColor: theme.colors.white,
    height: vw(24),
    width: vw(24),
    borderRadius: '50%',
    transform: 'scale(0)',
    fontSize: vw(12),
    transition: `transform 0.25s ${quad.out}`,
    '&.show': {
      display: 'flex',
      transition: `transform 0.5s ${back.out}`,
      transform: 'scale(1)'
    },
    '&.small': {
      fontSize: vw(10),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(10, 'desktop')
      }
    },
    '& span': {
      fontFamily: theme.fonts.body,
      fontWeight: theme.fonts.bodyFontWeight,
      lineHeight: 1,
      paddingBottom: '0.1em'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: vw(6, 'desktop'),
      height: vw(24, 'desktop'),
      width: vw(24, 'desktop'),
      fontSize: vw(12, 'desktop')
    }
  }
}, { name: 'Header' })

export default Header
