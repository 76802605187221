import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import { vw } from '../../style/vw'
import { span, sliceMarginStyles } from '../../style/span'
import get from 'lodash/get'
import BadgeElementsBlock from '../RichText/BadgeElementsBlock'
import SplitWordsAnimation from '../SplitWordsAnimation'

const HeadlineSlice = ({ className, slice }) => {
  const { title, page_number: pageNumber, badges } = slice
  const badge = get(badges, [0])
  const classes = useStyles()
  return (
    <section className={classes.section}>
      {pageNumber && <span className={classes.pageNumber}>{pageNumber}</span>}
      <SplitWordsAnimation>
        <h3 className={classes.title}>
          {title}
          {badge && <BadgeElementsBlock className={classes.icon} node={badge} />}
        </h3>
      </SplitWordsAnimation>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    extend: [sliceMarginStyles],
    position: 'relative',
    marginLeft: span(1),
    [theme.breakpoints.up('md')]: {
      marginLeft: span(2, 'md')
    }
  },
  title: {
    fontSize: vw(48),
    fontFamily: theme.fonts.headings,
    fontWeight: theme.fonts.headingsFontWeight,
    lineHeight: 0.98,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(160, 'desktop'),
      width: span(18, 'md')
    },
    '& p': {
      display: 'inline'
    }
  },
  pageNumber: {
    display: 'none',
    position: 'absolute',
    left: 0,
    top: 0,
    fontFamily: theme.fonts.headings,
    fontWeight: theme.fonts.headingsFontWeight,
    fontSize: vw(24),
    [theme.breakpoints.up('md')]: {
      left: span(-1, 'md'),
      display: 'block',
      fontSize: vw(24, 'desktop')
    }
  },
  icon: {
    float: 'left',
    padding: [0, 0, '0.2em']
  }
}, { name: 'HeadlineSlice' })

export default HeadlineSlice
