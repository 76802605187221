import { createAction } from '@reduxjs/toolkit'
import makeFetchSlice from '../makeFetchSlice'

export const pagesContentActionCreator = createAction('pages/content', (payload, loadMore) => ({
  payload,
  meta: { scope: 'pages', loadMore }
}))

export const pagesLoadMoreActionCreator = createAction('pages/load_more', (payload) => ({
  payload,
  meta: { scope: 'pages' }
}))

export const serverErrorActionCreator = createAction('pages/serverError')

const pagesSlice = makeFetchSlice('pages', pagesContentActionCreator, serverErrorActionCreator)

export default pagesSlice.reducer
