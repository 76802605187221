import React, { useMemo, forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import BlockContent from '@sanity/block-content-to-react'
import map from 'lodash/map'
import merge from 'lodash/merge'
import Link from '../Link'
import { resolveLink } from '../../helpers/resolveLink'
import BadgeElementsBlock from './BadgeElementsBlock'
import theme from '../../style/theme'
import { vw } from '../../style/vw'

const serializers = {
  marks: {
    link: ({ mark, children }) => (
      <Link to={mark.href} target={mark.open_in_new_window ? '_blank' : undefined}>{children}</Link>
    ),
    internal_link: ({ mark, children }) => (
      <Link link={resolveLink(mark)}>{children}</Link>
    ),
    badgeElement: ({ mark, children }) => {
      return <BadgeElementsBlock node={mark} />
    },
    label: ({ mark, children }) => {
      return <label>{children}</label>
    }
  }
}

const RichText = forwardRef(({ className, content, tag = 'div', serializers: extraSerializers, columns = 1, children }, ref) => {
  const classes = useStyles({ columns })
  const Component = tag
  const allSerializers = useMemo(() => {
    if (extraSerializers) {
      return merge({}, serializers, extraSerializers)
    }
    return serializers
  }, [extraSerializers])
  const blocks = useMemo(() => map(content, block => ({
    _type: block.type, // BlockContent expects `_type` prop, not `type`.
    ...block
  })))
  return (
    <Component className={cn(className, classes.container)} ref={ref}>
      <BlockContent blocks={blocks} serializers={allSerializers} />
      {children}
    </Component>
  )
})

const useStyles = createUseStyles({
  container: {
    [theme.breakpoints.up('md')]: {
      columnCount: ({ columns }) => columns,
      columnGap: vw(24, 'desktop')
    },
    '& *:last-child': {
      marginBottom: 0
    },
    '& ol': {
      counterReset: 'list-counter',
      padding: 0,
      margin: 0,
      listStyle: 'none',
      marginLeft: '2em'
    },
    '& ol > li': {
      counterIncrement: 'list-counter',
      position: 'relative',
      margin: [vw(32), 0],
      [theme.breakpoints.up('md')]: {
        margin: [vw(50, 'desktop'), 0]
      },
      '&:last-child': {
        marginBottom: 0
      }
    },
    '& ol > li::before': {
      content: 'counter(list-counter) ". "',
      position: 'absolute',
      left: '-1.5em',
      top: '-0.2em',
      fontFamily: theme.fonts.headings,
      fontWeight: theme.fonts.headingsFontWeight,
      fontSize: vw(24),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(24, 'desktop')
      }
    },
    '& label': {
      opacity: 0.6
    }
  }
}, { name: 'RichText' })

export default RichText
