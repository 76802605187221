import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import theme from '../../style/theme'
import ColorContext from '../ColorContext'

const BackgroundColor = ({ className, backgroundColor, borderColor, children }) => {
  const classes = useStyles({ backgroundColor, borderColor })
  const colorContextValue = useMemo(() => ({ backgroundColor, borderColor }), [backgroundColor, borderColor])

  return (
    <ColorContext.Provider value={colorContextValue}>
      <div className={cn(classes.backgroundColor, className)}>
        {children}
      </div>
    </ColorContext.Provider>
  )
}

const useStyles = createUseStyles({
  backgroundColor: {
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    color: ({ backgroundColor }) => theme.getForegroundColor(backgroundColor),
    borderColor: ({ borderColor }) => borderColor,
    '&:after': {
      content: '""',
      clear: 'both',
      display: 'table'
    }
  }
}, { name: 'BackgroundColor' })

export default BackgroundColor
