import React, { useRef, useEffect, useCallback, useContext } from 'react'
import { createUseStyles } from 'react-jss'
import map from 'lodash/map'
import cn from 'classnames'
import theme from '../../style/theme'
import { vw } from '../../style/vw'
import { span, sliceMarginStyles } from '../../style/span'
import ResponsiveImage from '../ResponsiveImage'
import { getPageYOffset, useScrollListener } from '../useSmoothScrollbar'
import detectIt from 'detect-it'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import afterFrame from '../../helpers/afterFrame'
import ColorContext from '../ColorContext'

const LargeTextSlice = ({ className, slice }) => {
  const { text, images } = slice
  const { borderColor } = useContext(ColorContext)
  const classes = useStyles({ borderColor })
  const containerRef = useRef()
  const contentRef = useRef()
  const imageContainerRef = useRef()

  useEffect(() => {
    if (detectIt.primaryInput !== 'touch' && containerRef.current) {
      const tl = gsap.timeline()
      const scrollTrigger = ScrollTrigger.create({
        trigger: containerRef.current,
        pin: false,
        start: 'top bottom',
        end: 'bottom top',
        scrub: 1,
        animation: tl,
        markers: false
      })
      tl.fromTo(imageContainerRef.current.children, { y: '50%' }, { y: '-150%', ease: 'none' })
      return () => {
        tl.kill()
        scrollTrigger.kill()
      }
    }
  }, [])

  const locals = useRef({
    margin: 0,
    containerRefOffset: 0,
    contentRefOffset: 0,
    windowHeight: 0
  })

  const onScroll = useCallback(() => {
    const { margin, containerRefOffset, contentRefOffset, windowHeight } = locals.current

    if (contentRef.current && containerRefOffset) {
      const top = containerRefOffset.offsetTop - getPageYOffset()
      const bottom = (containerRefOffset.offsetTop + containerRefOffset.offsetHeight) - getPageYOffset()

      const contentDiff = contentRefOffset.offsetHeight + margin - windowHeight
      const scrollLength = containerRefOffset.offsetHeight - windowHeight

      const distance = (top / scrollLength) * contentDiff

      if (top <= 0 && bottom >= windowHeight) {
        contentRef.current.style.transform = `translate3d(0,${-top + distance}px,0)`
      } else {
        if (top > 0) {
          contentRef.current.style.transform = 'translate3d(0,0,0)'
        } else {
          const d = (containerRefOffset.offsetHeight - windowHeight)
          contentRef.current.style.transform = `translate3d(0,${d - contentDiff}px,0)`
        }
      }
    }
    afterFrame(() => {
      if (contentRef.current) {
        const contentStyle = window.getComputedStyle(contentRef.current)
        locals.current.margin = parseInt(contentStyle.marginTop) + parseInt(contentStyle.marginBottom)
        locals.current.containerRefOffset = {
          offsetTop: containerRef.current.offsetTop,
          offsetHeight: containerRef.current.offsetHeight
        }
        locals.current.contentRefOffset = {
          offsetTop: contentRef.current.offsetTop,
          offsetHeight: contentRef.current.offsetHeight
        }
        locals.current.windowHeight = window.innerHeight
      }
    })
  }, [])
  useScrollListener(onScroll)

  return (
    <section className={classes.section} ref={containerRef}>
      <div className={classes.imageContainer} ref={imageContainerRef}>
        {map(images, (image, i) => (
          <div key={i} className={cn(classes.image, { right: i % 2 === 0 })}>
            <ResponsiveImage {...image} aspect={0.76} />
          </div>
        ))}
      </div>
      <div className={classes.content} ref={contentRef}>{text}</div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    extend: [sliceMarginStyles],
    position: 'relative',
    borderColor: ({ borderColor }) => borderColor,
    border: [0, 'solid'],
    borderWidth: [1, 0],
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      height: '300vh'
    }
  },
  content: {
    position: 'relative',
    margin: [vw(70), span(1)],
    fontSize: vw(50),
    fontFamily: theme.fonts.headings,
    fontWeight: theme.fonts.headingsFontWeight,
    lineHeight: 1.15,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      margin: [vw(150, 'desktop'), span(3, 'md')],
      fontSize: vw(160, 'desktop'),
      lineHeight: 1
    }
  },
  imageContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: [0, span(2, 'md')]
    }
  },
  image: {
    '&.right': {
      marginLeft: 'auto'
    },
    [theme.breakpoints.up('md')]: {
      width: span(7, 'md'),
      margin: [vw(60, 'desktop'), 0]
    }
  }
}, { name: 'LargeTextSlice' })

export default LargeTextSlice
