import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import Menu from './Menu'
import { span } from '../style/span'
import theme from '../style/theme'
import { useSelector } from 'react-redux'
import { getFooterCopyright, getFooterLinks } from '../redux/slices/content'
import Link from './Link'
import { resolveLink } from '../helpers/resolveLink'
import { vw } from '../style/vw'
import { useInView } from 'react-intersection-observer'
import ColorContext from './ColorContext'

function Footer () {
  const { borderColor } = useContext(ColorContext)
  const classes = useStyles({ borderColor })
  const copyright = useSelector(getFooterCopyright)
  const footerLinks = useSelector(getFooterLinks)
  const [inViewRef, inView] = useInView({ threshold: 0.5, triggerOnce: true })

  return (
    <footer className={classes.footer} ref={inViewRef}>
      <Menu showPartners animate={inView} />
      <div className={classes.linksContainer}>
        <span>{copyright}</span>
        <ul className={classes.links}>
          {footerLinks && footerLinks.map((link, i) => (<li key={i}><Link className={classes.link} link={resolveLink(link.link[0])} /></li>))}
        </ul>
      </div>
    </footer>
  )
}

const useStyles = createUseStyles({
  footer: {
    borderColor: ({ borderColor }) => borderColor,
    borderTop: '1px solid',
    padding: [span(3), span(1)],
    [theme.breakpoints.up('md')]: {
      padding: span(3, 'md')
    }
  },
  linksContainer: {
    textAlign: 'center',
    marginTop: vw(80),
    fontSize: vw(14),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'desktop'),
      marginTop: vw(100, 'desktop')
    },
    '& span': {
      display: 'block',
      margin: 'auto',
      maxWidth: vw(220),
      [theme.breakpoints.up('md')]: {
        opacity: 0.5,
        maxWidth: '100%'
      }
    }
  },
  links: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
    marginTop: vw(28),
    [theme.breakpoints.up('md')]: {
      marginTop: vw(12, 'desktop')
    }
  },
  link: {
    fontSize: vw(14),
    margin: [0, vw(8)],
    [theme.breakpoints.up('md')]: {
      opacity: 0.5,
      fontSize: vw(14, 'desktop'),
      margin: [0, vw(8, 'desktop')]
    },
    '&:hover': {
      opacity: 1
    }
  }
}, { name: 'Footer' })

export default Footer
