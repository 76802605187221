import { createSlice } from '@reduxjs/toolkit'
import { ActionType } from 'redux-promise-middleware'
import get from 'lodash/get'
import forEach from 'lodash/forEach'

export default (name, action, errorAction, listPropertyName = 'pages', initialState = {}, reducers = {}) => createSlice({
  name: name,
  initialState: { ...initialState },
  reducers: { ...reducers },
  extraReducers: builder => {
    builder = builder
      .addCase(`${action}_${ActionType.Pending}`, (state, action) => {
        state.busy = true
      })
      .addCase(`${action}_${ActionType.Fulfilled}`, (state, action) => {
        if (get(action, ['meta', 'arrivedTooLate'])) { // See race middleware for details
          return
        }
        const loadMore = get(action, ['meta', 'loadMore'])
        state.busy = false
        state.error = undefined
        if (loadMore && state.content[listPropertyName]) {
          forEach(action.payload[listPropertyName], item => {
            state.content[listPropertyName].push(item)
          })
          state.page = action.payload.page
          state.limit = action.payload.limit
          state.total = action.payload.total
        } else {
          state.content = action.payload
        }
      })
      .addCase(`${action}_${ActionType.Rejected}`, (state, action) => {
        state.busy = false
        state.error = action.payload
      })
    if (errorAction) {
      builder = builder
        .addCase(`${errorAction}_${ActionType.Fulfilled}`, (state, action) => {
          state.busy = false
          state.error = action.payload.error
          state.content = action.payload.content
        })
        .addCase(`${errorAction}_${ActionType.Rejected}`, (state, action) => {
          state.busy = false
          state.error = action.payload
          state.content = {
            type: 'errorPage',
            title: 'Server Error'
          }
        })
    }
    builder = builder.addDefaultCase((state, action) => { return state })
    return builder
  }
})
