import { createSlice } from '@reduxjs/toolkit'

const cookieSlice = createSlice({
  name: 'cookie',
  initialState: {
    accepted: undefined
  },
  reducers: {
    setCookieAccepted: (state, action) => {
      state.accepted = action.payload
    },
    acceptCookie: (state, action) => {
      state.accepted = true
    }
  }
})

export const { setCookieAccepted, acceptCookie } = cookieSlice.actions

export const isCookieAccepted = state => state.cookie.accepted

export default cookieSlice.reducer
