import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import animations, { aspectRatios } from '../../animation'
import { useInView } from 'react-intersection-observer'
import composeRefs from '../../helpers/composeRefs'
import useReversingLottieAnimation from '../../hooks/useReversingLottieAnimation'

const BadgeElementsBlock = ({ className, node }) => {
  const animationData = animations[node.element]
  const [inViewRef, inView] = useInView({ threshold: 1, triggerOnce: true })
  const animationRef = useReversingLottieAnimation(animationData, inView)
  const ref = composeRefs(inViewRef, animationRef)
  const aspect = aspectRatios[node.element] || 1
  const classes = useStyles({ aspect })

  if (!animationData) {
    return null
  }
  return (
    <span className={cn(classes.element, className)} ref={ref} />
  )
}

const useStyles = createUseStyles({
  element: ({ aspect }) => ({
    display: 'inline-block',
    height: '1em',
    width: `${aspect}em`,
    '&::after': {
      content: '"\\A"',
      whiteSpace: 'pre'
    }
  })
}, { name: 'BadgeElementsBlock' })

export default BadgeElementsBlock
