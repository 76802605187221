import mapValues from 'lodash/mapValues'
import stamp from './stamp.json'
import compass from './compass.json'
import downArrow from './downArrow.json'
import binoculars from './binoculars.json'

const animations = {
  stamp,
  compass,
  downArrow,
  binoculars
}

export const aspectRatios = mapValues(animations, ({ w, h }) => w / h)

export default animations
