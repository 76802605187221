import React, { useEffect, useRef, useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { sliceMarginStyles, span } from '../../style/span'
import cn from 'classnames'
import SplitWordsAnimation from '../SplitWordsAnimation'
import { vw } from '../../style/vw'
import theme from '../../style/theme'
import downArrowData from '../../animation/downArrow.json'
import useReversingLottieAnimation from '../../hooks/useReversingLottieAnimation'
import gsap from 'gsap'
import outlineText from '../../style/outlineText'
import { useSelector } from 'react-redux'
import { getPageBackgroundColor } from '../../redux/slices/content'
import ColorContext from '../ColorContext'

const iconAspect = downArrowData.w / downArrowData.h

const PageHeroWithPageNumberSlice = ({ className, slice, noBottomMargin }) => {
  const { title, page_number: pageNumber, copy } = slice
  const { borderColor } = useContext(ColorContext)
  const backgroundColor = useSelector(getPageBackgroundColor)
  const classes = useStyles({ backgroundColor, borderColor })
  const iconRef = useReversingLottieAnimation(downArrowData, true, 0.8)
  const dividerRef = useRef()
  useEffect(() => {
    if (dividerRef.current) {
      gsap.to(dividerRef.current, { scale: 1, duration: 1.2, ease: 'sine.out' })
    }
  }, [])

  return (
    <section className={cn(classes.section, !noBottomMargin && classes.sectionBottomMargin)}>
      <div className={classes.pageNumberContainer}>
        <span className={cn(classes.pageNumber, classes.pageNumberColor)}>{pageNumber}</span>
        <p className={classes.copy}>{copy}</p>
      </div>
      <div className={classes.titleContainer}>
        <div className={classes.divider} ref={dividerRef} />
        <SplitWordsAnimation>
          <h1 className={classes.title}>{title}</h1>
        </SplitWordsAnimation>
        <p className={classes.copyMobile}>{copy}</p>
        <span ref={iconRef} className={classes.downArrowIcon} />
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    display: 'flex',
    borderColor: ({ borderColor }) => borderColor,
    borderBottom: [1, 'solid'],
    [theme.breakpoints.up('md')]: {
      minHeight: '100vh'
    }
  },
  sectionBottomMargin: {
    extend: [sliceMarginStyles]
  },
  pageNumberContainer: {
    display: 'none',
    flexDirection: 'column',
    borderColor: ({ borderColor }) => borderColor,
    borderRight: [1, 'solid'],
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      width: span(14, 'md'),
      padding: [vw(150, 'desktop'), span(1, 'md'), span(1, 'md')]
    }
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: [vw(120), span(1), vw(40)],
    [theme.breakpoints.up('md')]: {
      width: span(12, 'md'),
      padding: [vw(150, 'desktop'), span(2, 'md'), span(1, 'md'), span(1, 'md')]
    }
  },
  divider: {
    height: 1,
    backgroundColor: 'currentColor',
    flexGrow: 0,
    flexShrink: 0,
    width: span(10),
    transform: 'scale(0)',
    marginBottom: vw(20),
    [theme.breakpoints.up('md')]: {
      width: span(8, 'md'),
      marginBottom: vw(40, 'desktop')
    }
  },
  title: {
    lineHeight: 1,
    flexGrow: 1,
    fontSize: vw(64),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(110, 'desktop')
    }
  },
  downArrowIcon: {
    display: 'block',
    width: vw(80),
    height: vw(80),
    flexGrow: 0,
    flexShrink: 0,
    '& svg *': {
      fill: 'currentColor',
      stroke: 'currentColor'
    },
    [theme.breakpoints.up('md')]: {
      width: vw(112, 'desktop'),
      height: vw(iconAspect * 112, 'desktop')
    }
  },
  pageNumberColor: ({ backgroundColor }) => ({
    ...outlineText(theme.getForegroundColor(backgroundColor), 1)
  }),
  pageNumber: {
    color: 'transparent',
    display: 'block',
    fontFamily: theme.fonts.headings,
    fontWeight: theme.fonts.headingsFontWeight,
    lineHeight: 1,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(340, 'desktop')
    }
  },
  copy: {
    flexGrow: 0,
    flexShrink: 0,
    fontFamily: theme.fonts.headings,
    fontWeight: theme.fonts.headingsFontWeight,
    lineHeight: 1.4,
    fontSize: vw(16),
    width: span(10),
    [theme.breakpoints.up('md')]: {
      width: span(6, 'md'),
      fontSize: vw(16, 'desktop')
    }
  },
  copyMobile: {
    composes: '$copy',
    display: 'block',
    marginBottom: vw(32),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}, { name: 'PageHeroWithPageNumberSlice' })

export default PageHeroWithPageNumberSlice
