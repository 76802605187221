import React, { useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import Flickity from 'react-flickity-component'
import gsap from 'gsap'
import { LinkWithCursorHoverEffect } from '../Link'
import isEmpty from 'lodash/isEmpty'
import theme from '../../style/theme'
import { span, sliceMarginStyles } from '../../style/span'
import { vw } from '../../style/vw'
import { useDragCursor } from '../Cursor'
import Heading from '../Heading'

const preventVerticalScrolling = (flickity) => {
  // This will prevent the vertical scrolling when scrolling in flickity
  flickity.on('dragStart', () => { document.ontouchmove = e => e.preventDefault() })
  flickity.on('dragEnd', () => { document.ontouchmove = () => true })
}

const flickityOptions = {
  cellAlign: 'left',
  pageDots: false,
  contain: true,
  freeScroll: true
}

const Slider = ({ className, children, title, link, ...rest }) => {
  const classes = useStyles()
  const containerRef = useRef()
  const setFlickityRef = useCallback((flickity) => {
    if (flickity) {
      gsap.set(flickity.element, { display: 'block', overflow: 'visible' })
      // Flickity consumes the mouse move events so we need to create a customer event so the custom mouse
      // cursor continues to follow the mouse cursor
      flickity.on('dragMove', (event, pointer, moveVector) => {
        const dragMoveEvent = new window.CustomEvent('flickity-dragMove', { detail: event })
        window.dispatchEvent(dragMoveEvent)
      })
      preventVerticalScrolling(flickity)
    }
  }, [])

  useDragCursor(containerRef)

  return (
    <section className={classes.section}>
      {title && <Heading text={title} className={classes.mobileTitle} />}
      {!isEmpty(link) && <LinkWithCursorHoverEffect link={link} className={classes.link} />}
      <div ref={containerRef}>
        <Flickity
          options={flickityOptions}
          disableImagesLoaded
          reloadOnUpdate
          static
          className={classes.flickity}
          flickityRef={setFlickityRef}
          {...rest}
        >
          {title && (
            <div className={classes.titleContainer}>
              <Heading key={title} text={title} className={classes.title} />
            </div>
          )}
          {children}
        </Flickity>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    extend: [sliceMarginStyles],
    padding: [0, span(1)],
    overflowX: 'hidden',
    [theme.breakpoints.up('md')]: {
      padding: [0, span(1, 'md')]
    }
  },
  flickity: {
    outline: 'none',
    whiteSpace: 'nowrap',
    display: 'flex',
    padding: [20, 0],
    '& > *': {
      flexShrink: 0
    }
  },
  mobileTitle: {
    whiteSpace: 'pre-wrap',
    display: 'block',
    lineHeight: 1.1,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  titleContainer: {
    display: 'none',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      width: span(3, 'md'),
      height: vw(700, 'desktop')
    }
  },
  title: {
    whiteSpace: 'pre-wrap',
    position: 'absolute',
    top: 0,
    left: 0,
    textAlign: 'right',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      transform: 'rotate(-90deg) translate(-100%, 0)',
      transformOrigin: 'top left',
      height: span(3, 'md'),
      width: vw(700, 'desktop'),
      margin: 0,
      display: 'block'
    }
  },
  link: {
    display: 'inline-block',
    textTransform: 'uppercase',
    textDecoration: 'none',
    position: 'relative',
    margin: [0, 0, vw(40), 0],
    ...theme.link(),
    [theme.breakpoints.up('md')]: {
      margin: [0, 0, vw(40, 'desktop'), span(4, 'md')]
    }
  }
}, { name: 'Slider' })

export default Slider
