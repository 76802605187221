import { useCallback } from 'react'
import throttle from 'lodash/throttle'

const defaultOptions = {
  leading: false,
  trailing: true
}

const useThrottle = (fn, wait = 100, options = defaultOptions, dependencies = []) => {
  const throttled = throttle(fn, wait, options)
  return useCallback(throttled, dependencies)
}

export default useThrottle
