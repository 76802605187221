import { createSlice } from '@reduxjs/toolkit'

const previewSlice = createSlice({
  name: 'preview',
  initialState: false,
  reducers: {
    requestPreviewSession: (state, action) => {
      return true
    }
  }
})

export const { requestPreviewSession } = previewSlice.actions

export default previewSlice.reducer
