
import SessionStorage from 'redux-first-router-restore-scroll/dist/SessionStorage'

class PauseableSessionStorage extends SessionStorage {
  paused = false

  pause () {
    this.paused = true
  }

  resume () {
    this.paused = false
  }

  save (location, key, value) {
    if (!this.paused) {
      return super.save(location, key, value)
    }
  }
}

let storage

export function getStateStorage () {
  if (!storage) {
    storage = new PauseableSessionStorage()
  }
  return storage
}
