import { createAction } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import get from 'lodash/get'
import sumBy from 'lodash/sumBy'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import map from 'lodash/map'
import omit from 'lodash/omit'
import groupBy from 'lodash/groupBy'
import reduce from 'lodash/reduce'
import first from 'lodash/first'
import makeFetchSlice from './makeFetchSlice'
import { getCartCheckoutLabel } from './content'
import { cartActionCreator } from '../actions/cart'

function optimisticallyUpdateQuantity (state, variantId, { numberToAdd, quantity }) {
  const lineItems = get(state, ['content', 'lineItems'])
  if (!lineItems || !find(lineItems, ({ variant }) => variant.id === variantId)) {
    return state.content
  }
  return {
    ...omit(state.content, [
      'lineItems' // ,
      // // Leave price calculations to the server - delete the incorrect totals for now
      // 'lineItemsSubtotalPrice',
      // 'subtotalPrice',
      // 'totalPrice'
    ]),
    lineItems: map(lineItems, lineItem => {
      if (lineItem.variant.id === variantId) {
        return {
          ...lineItem,
          quantity: Math.max(0, numberToAdd ? lineItem.quantity + numberToAdd : quantity)
        }
      }
      return lineItem
    })
  }
}

export const serverErrorActionCreator = createAction('page/serverError')

const cartReducers = {
  clearCart: (state, action) => {
    state.content = null
  },
  incrementLineItemQuantity: (state, action) => {
    state.content = optimisticallyUpdateQuantity(state, action.payload, { numberToAdd: 1 })
  },
  decrementLineItemQuantity: (state, action) => {
    state.content = optimisticallyUpdateQuantity(state, action.payload, { numberToAdd: -1 })
  },
  removeFromCart: (state, action) => {
    state.content = optimisticallyUpdateQuantity(state, action.payload, { quantity: 0 })
  }
}
const cartInitialState = {
  content: null
}

const cartSlice = makeFetchSlice('cart', cartActionCreator, serverErrorActionCreator, 'cart', cartInitialState, cartReducers)

export const getCartId = state => get(state, ['cart', 'content', 'id'])
export const isCartCompleted = state => get(state, ['cart', 'content', 'completedAt'])
export const getCartLineItemsCount = state => sumBy(getCartLineItems(state), 'quantity')
export const getCartCheckoutUrl = state => get(state, ['cart', 'content', 'checkoutUrl'])
export const getCartTotalPriceAmount = state => get(state, ['cart', 'content', 'totalPrice', 'amount'])
export const getCartSubtotalPriceAmount = state => get(state, ['cart', 'content', 'subtotalPrice', 'amount'])
export const getCartLineItemsSubtotalPrice = state => get(state, ['cart', 'content', 'lineItemsSubtotalPrice', 'amount'])
export const isCartEmpty = state => isEmpty(getCartLineItems(state))

export const getCartDiscount = createSelector(
  getCartTotalPriceAmount,
  getCartLineItemsSubtotalPrice,
  (total, lineItemsAmount) => {
    return lineItemsAmount - total
  }
)
export const getCartLineItems = createSelector(
  state => get(state, ['cart', 'content', 'lineItems']),
  (lineItems) => {
    return reduce(groupBy(lineItems, x => x.product.id), (result, items) => {
      const item = { ...first(items) }
      item.quantity = sumBy(items, item => item.quantity)
      result.push(item)
      return result
    }, [])
  }
)
export const getCartCheckoutLink = createSelector(
  getCartCheckoutUrl,
  getCartCheckoutLabel,
  (checkoutUrl, copy, activeBundle) => {
    var url = checkoutUrl
    // This will add the discount code to the package if one is active
    if (activeBundle) {
      url += `&discount=${activeBundle.discount_code}`
    }
    return {
      type: 'externalLink',
      text: copy,
      url
    }
  }
)
export const isCartBusy = state => get(state, ['cart', 'busy'])

export default cartSlice.reducer
