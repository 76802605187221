import { useRef, useEffect } from 'react'
import detectIt from 'detect-it'
import gsap from 'gsap'
import remove from 'lodash/remove'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Scrollbar from 'smooth-scrollbar'
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll'
import { getStateStorage } from '../redux/middlewares/PauseableSessionStorage'

import inDOM from 'dom-helpers/canUseDOM'
import forEach from 'lodash/forEach'

gsap.registerPlugin(ScrollTrigger)

export var scrollbar

export const getPageYOffset = () => {
  return scrollbar ? scrollbar.offset.y : (window.pageYOffset || document.documentElement.scrollTop)
}

export const setPageYOffset = (y) => {
  if (scrollbar) {
    scrollbar.scrollTo(0, y, 0)
  }
}

const scrollCallbacks = []
export const useScrollListener = (callback) => {
  useEffect(() => {
    scrollCallbacks.push(callback)
    return () => {
      remove(scrollCallbacks, callback)
    }
  }, [callback])
}

// We need to set this up first so that the scroll triggers get the proxied functions
if (inDOM && detectIt.primaryInput !== 'touch') {
  // This will setup the scroll trigger to use the resolved y from the smooth scrollbar
  ScrollTrigger.scrollerProxy(document.body, {
    scrollTop (value) {
      if (arguments.length) {
        setPageYOffset(value)
      }
      return getPageYOffset()
    }
  })
}

export const useSmoothScrollbar = () => {
  const ref = useRef()

  useEffect(() => {
    if (ref.current && detectIt.primaryInput !== 'touch') {
      Scrollbar.use(OverscrollPlugin)
      scrollbar = Scrollbar.init(ref.current, { damping: 0.1, renderByPixels: true, alwaysShowTracks: false })
      scrollbar.updatePluginOptions('overscroll', { effect: 'bounce' })

      scrollbar.addListener(({ offset }) => {
        // Updates the scroll history
        const storage = getStateStorage()
        storage.save(window.history.state || {}, null, [offset.x, offset.y])

        // Updates the scroll position for the scroll triggers
        ScrollTrigger.update()
        forEach(scrollCallbacks, cb => cb(offset))
      })

      ScrollTrigger.refresh(true)

      return () => {
        scrollbar.destroy()
        scrollbar = null
      }
    }
  }, [])

  return { ref }
}
