/* global fetch */
import React, { useState, useRef, useEffect, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import get from 'lodash/get'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { isCurrentBreakpointMobile } from '../../../redux/slices/layout'
import { disableSticky, shouldAllowSticky } from '../../../redux/slices/shopProducts'
import BuyButton from './BuyButton'
import RichText from '../../RichText'
import ProductTable from './ProductTable'
import ResponsiveImage from '../../ResponsiveImage'
import { formatPrice } from '../../../helpers/format'
import { span } from '../../../style/span'
import { vw } from '../../../style/vw'
import theme from '../../../style/theme'
import useResizeObserver from '../../../hooks/useResizeObserver'
import { getPageYOffset } from '../../useSmoothScrollbar'
import afterFrame from '../../../helpers/afterFrame'
import offsetTop from '../../../helpers/offsetTop'
import getViewportSize from '../../../helpers/getViewportSize'
import SubscriptionWidget from './SubscriptionWidget'

const HEADER_OFFSET = 100

const Product = (props) => {
  const {
    title,
    label,
    gallery,
    product,
    quantity,
    description,
    product_content: productContent
  } = props
  const classes = useStyles()
  const containerRef = useRef()
  const stickyRef = useRef()
  const locals = useRef({})
  const isMobile = useSelector(isCurrentBreakpointMobile)
  const allowSticky = useSelector(shouldAllowSticky)
  const dispatch = useDispatch()

  useEffect(() => {
    const variantId = product.variants && product.variants[0] && product.variants[0].id.split('/').pop()
    setSelectedVariantId(variantId)
  }, [product])

  useEffect(() => {
    const fetchSellingPlans = async () => {
      try {
        const response = await fetch(`https://api-server.discoveredfoods.com/api/getSellingPlans?productId=${product.id}`)
        const data = await response.json()
        // console.log('Product Data -->', JSON.stringify(product, null, 2))
        // console.log('API Response: ', data)

        if (data?.data?.product?.sellingPlanGroups?.edges) {
          const plans = data.data.product.sellingPlanGroups.edges
            .map(group => group.node.sellingPlans.edges.map(plan => plan.node))
            .flat()
          // console.log('Fetched Selling Plans: ', plans)
          setSellingPlans(plans)
        }
      } catch (error) {
        console.error('Error fetching selling plans:', error)
      }
    }

    if (product && product.id) {
      fetchSellingPlans()
    }
  }, [product])

  const [selectedVariantId, setSelectedVariantId] = useState(null)
  const [prdQuantity, setQuantity] = useState(1)
  const [sellingPlanId, setSellingPlanId] = useState(null)
  const [sellingPlans, setSellingPlans] = useState([])

  const handleSubscriptionSelection = (sellingPlanId) => {
    setSellingPlanId(sellingPlanId)
  }

  const resetPosition = () => {
    if (allowSticky) {
      locals.current = {
        start: offsetTop(containerRef.current) - HEADER_OFFSET,
        end: offsetTop(containerRef.current) + containerRef.current.offsetHeight - stickyRef.current.offsetHeight - HEADER_OFFSET
      }
      const { start, end } = locals.current
      if (stickyRef.current && start) {
        const scrollY = getPageYOffset()
        if (scrollY < start) {
          stickyRef.current.style.transform = 'translate3d(0, 0, 0)'
        } else if (scrollY >= start && scrollY <= end) {
          stickyRef.current.style.transform = `translate3d(0,${scrollY - start}px,0)`
        } else if (scrollY > end) {
          const rangeOfMotion = Math.abs(start - end)
          stickyRef.current.style.transform = `translate3d(0,${rangeOfMotion}px,0)`
        }
      }
    } else {
      stickyRef.current.style.transform = 'translate3d(0, 0, 0)'
    }
  }

  const onResize = useCallback(() => {
    if (stickyRef.current) {
      resetPosition(allowSticky)
      const viewportSize = getViewportSize()
      const { height: viewportHeight } = viewportSize
      if (stickyRef.current.clientHeight + HEADER_OFFSET > viewportHeight) {
        dispatch(disableSticky())
      }
    }
  }, [allowSticky])

  useResizeObserver(containerRef, onResize, [onResize])

  useEffect(() => {
    onResize()
  }, [onResize])

  useEffect(() => {
    if (isMobile || !allowSticky) return
    var animationFrame
    const tick = () => {
      const { start, end } = locals.current
      if (stickyRef.current && start) {
        const scrollY = getPageYOffset()
        if (scrollY >= start && scrollY <= end) {
          stickyRef.current.style.transform = `translate3d(0,${scrollY - start}px,0)`
        }
      }
      afterFrame(() => {
        if (stickyRef.current) {
          locals.current = {
            start: offsetTop(containerRef.current) - HEADER_OFFSET,
            end: offsetTop(containerRef.current) + containerRef.current.offsetHeight - stickyRef.current.offsetHeight - HEADER_OFFSET
          }
        }
      })
      animationFrame = window.requestAnimationFrame(tick)
    }
    animationFrame = window.requestAnimationFrame(tick)
    return () => {
      window.cancelAnimationFrame(animationFrame)
    }
  }, [isMobile, allowSticky])

  const price = get(product, ['variants', 0, 'price', 'amount'])
  const comparePrice = get(product, ['variants', 0, 'comparePrice', 'amount'])
  return (
    <article className={classes.product}>
      <div className={classes.cols} ref={containerRef}>
        <div className={cn(classes.col, classes.galleryCol)}>
          <div className={classes.gallery}>
            {gallery.map((galleryItem, i) => {
              const {
                image,
                orientation,
                size
              } = galleryItem
              return (
                <div key={i} className={cn(classes.galleryItem, size, orientation)}>
                  <ResponsiveImage {...image} />
                </div>
              )
            })}
          </div>
        </div>
        <div className={cn(classes.col, classes.detailsCol)}>
          <div className={classes.detailsContainer} ref={stickyRef}>
            <span className={classes.detailsLabel}>{label}</span>
            <h3 className={classes.detailsTitle}>
              {title}
            </h3>
            <div className={classes.detailsPrice}>
              {comparePrice && <span className={cn(classes.priceFigure, classes.comparePrice)}>{formatPrice(comparePrice)}</span>}
              <span className={cn(classes.priceFigure, classes.actualPrice)}>{formatPrice(price)}</span>
              <span className={classes.detailsQuantity}>{quantity}</span>
            </div>
            {description && description.text && <RichText className={classes.detailsDescription} content={description.text} />}
            {productContent && (
              <div className={classes.tableWrapper}>
                <ProductTable tableContent={productContent} />
              </div>
            )}
            <SubscriptionWidget
              onSelectionChange={handleSubscriptionSelection}
              price={price}
              sellingPlans={sellingPlans}
              variantId={selectedVariantId}
            />
            {product && (
              <div className={classes.detailsCtas}>
                <BuyButton
                  product={product}
                  variantId={selectedVariantId}
                  quantity={prdQuantity}
                  setQuantity={setQuantity}
                  sellingPlanId={sellingPlanId}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </article>
  )
}

const useStyles = createUseStyles({
  product: {
    borderBottom: `1px solid ${theme.colors.border}`,
    marginBottom: span(1),
    paddingBottom: vw(100),
    '&:last-child': {
      marginBottom: 'none',
      borderBottom: 'none'
    },
    [theme.breakpoints.up('md')]: {
      padding: [0, span(1, 'md'), span(1, 'md')],
      marginBottom: span(1, 'md'),
      '&:last-child': {
        marginBottom: 'none',
        borderBottom: 'none'
      }
    }
  },
  cols: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  col: {
    [theme.breakpoints.up('md')]: {
      width: '50%'
    }
  },
  detailsCol: {
    [theme.breakpoints.down('md')]: {
      margin: [0, span(1)]
    },
    [theme.breakpoints.up('md')]: {
      margin: [0, span(1, 'md'), 0, span(2, 'md')]
    }
  },
  detailsLabel: {
    display: 'block',
    textTransform: 'uppercase',
    fontFamily: theme.fonts.body,
    fontWeight: theme.fonts.bodyFontWeight,
    marginBottom: 10,
    fontSize: vw(12),
    [theme.breakpoints.up('md')]: {
      marginBottom: 20,
      fontSize: vw(12, 'desktop')
    }
  },
  detailsTitle: {
    extend: [theme.global[theme.headingsSelector], theme.global.h3],
    whiteSpace: 'pre-line',
    [theme.breakpoints.up('md')]: {
      marginBottom: 20
    }
  },
  detailsPrice: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      marginBottom: 20
    }
  },
  priceFigure: {
    extend: [theme.global[theme.headingsSelector], theme.global.h6],
    lineHeight: 1,
    marginRight: 10,
    '&:last-child': {
      marginRight: 0
    },
    [theme.breakpoints.up('md')]: {
      fontSize: vw(16, 'desktop'),
      marginBottom: 8
    }
  },
  comparePrice: {
    textDecoration: 'line-through'
  },
  detailsDescription: {
    fontSize: vw(18),
    marginBottom: 30,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(18, 'desktop'),
      marginBottom: 60
    }
  },
  detailsContainer: {
    [theme.breakpoints.up('md')]: {
      paddingBottom: 20
    }
  },
  tableWrapper: {
    marginBottom: 30,
    [theme.breakpoints.up('md')]: {
      marginBottom: 60
    }
  },
  galleryCol: {
    [theme.breakpoints.down('md')]: {
      height: '75vw',
      overflowX: 'scroll',
      position: 'relative',
      marginBottom: 48,
      /* Hide scrollbar */
      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    [theme.breakpoints.up('md')]: {
      flexShrink: 0
    }
  },
  gallery: {
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      display: 'flex',
      padding: [0, span(1)]
    },
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: span(1, 'md'),
      padding: 0
    }
  },
  galleryItem: {
    [theme.breakpoints.down('md')]: {
      width: span(7.5),
      marginRight: span(0.5),
      '&>div': {
        height: '100%'
      },
      '&:last-child': {
        marginRight: 0
      },
      '& picture:before': {
        paddingTop: 0
      },
      '& img': {
      }
    },
    '&.full-width': {
      [theme.breakpoints.up('md')]: {
        gridColumn: 'span 2'
      }
    }
  }
}, { name: 'Product' })

export default Product
