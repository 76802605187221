import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { sliceMarginStyles, span, sliceMarginSpan } from '../../style/span'
import RichText from '../RichText'
import theme from '../../style/theme'
import { vw } from '../../style/vw'
import ColorContext from '../ColorContext'

const RecipeIngredientsSlice = ({ className, slice }) => {
  const { title, ingredients } = slice
  const { borderColor } = useContext(ColorContext)
  const classes = useStyles({ borderColor })

  return (
    <section className={classes.section}>
      <div className={classes.lhs}>
        <h4>{title}</h4>
      </div>
      <div className={classes.rhs}>
        {ingredients && <RichText content={ingredients.text} />}
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    extend: [sliceMarginStyles],
    borderColor: ({ borderColor }) => borderColor,
    borderBottom: '1px solid',
    marginTop: span(-sliceMarginSpan),
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      marginTop: span(-sliceMarginSpan, 'md')
    },
    position: 'relative'
  },
  lhs: {
    padding: [vw(70), span(1)],
    borderColor: ({ borderColor }) => borderColor,
    borderBottom: [1, 'solid'],
    position: 'relative',
    '&:after': {
      content: '""',
      display: 'none',
      position: 'absolute',
      right: -1,
      top: vw(-48),
      height: vw(48),
      width: 1,
      backgroundColor: theme.colors.white,
      [theme.breakpoints.up('md')]: {
        top: vw(-48, 'desktop'),
        height: vw(48, 'desktop')
      }
    },
    [theme.breakpoints.up('md')]: {
      borderBottom: 'none',
      borderRight: [1, 'solid'],
      width: span(13, 'md'),
      padding: [vw(100, 'desktop'), span(2, 'md'), vw(100, 'desktop'), span(3, 'md')],
      '&:after': {
        display: 'block'
      }
    }
  },
  rhs: {
    padding: [vw(70), span(1)],
    [theme.breakpoints.up('md')]: {
      width: span(13, 'md'),
      padding: [vw(100, 'desktop'), span(3, 'md'), vw(100, 'desktop'), span(2, 'md')]
    },
    '& p': {
      marginBottom: '0',
      '&:last-child': {
        marginBottom: '1.4em'
      }
    },
    '& h1, h2, h3, h4, h5, h6': {
      '&:not(:first-child)': {
        marginTop: '1.4em'
      },
      [theme.breakpoints.up('md')]: {
        '&:not(:first-child)': {
          marginTop: '1.4em'
        }
      }
    }
  }
}, { name: 'RecipeIngredientsSlice' })

export default RecipeIngredientsSlice
