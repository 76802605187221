import React, { useState, useRef, useEffect, useCallback, useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { sliceMarginStyles, sliceMarginSpan, span } from '../../style/span'
import RichText from '../RichText'
import theme from '../../style/theme'
import { vw } from '../../style/vw'
import { ReactComponent as CaretIcon } from '../../images/caret.svg'
import gsap from 'gsap'
import { useHoverCursor } from '../Cursor'
import ColorContext from '../ColorContext'

const AccordionItem = ({ title, copy, index, borderColor }) => {
  const classes = useStyles({ borderColor })
  const [open, setOpen] = useState(false)
  const copyRef = useRef()
  const caretRef = useRef()
  const buttonRef = useRef()

  useHoverCursor(buttonRef)

  useEffect(() => {
    if (copyRef.current) {
      gsap.to(copyRef.current, { height: open ? 'auto' : 0, duration: 0.4, ease: 'quad.inOut' })
      gsap.to(caretRef.current, { rotate: open ? 0 : '180deg', duration: 0.4, ease: 'quad.inOut' })
    }
  }, [open])

  const toggleOpen = useCallback(() => {
    setOpen(value => !value)
  }, [])

  return (
    <li className={classes.item}>
      <button className={classes.title} onClick={toggleOpen} ref={buttonRef}>
        <h5>{index + 1}. {title}</h5>
        <CaretIcon className={classes.caret} ref={caretRef} />
      </button>
      <div className={classes.copyContainer} ref={copyRef}>
        <RichText content={copy.text} className={classes.copy} />
      </div>
    </li>
  )
}

const AccordionSlice = ({ className, slice }) => {
  const { borderColor } = useContext(ColorContext)
  const { items } = slice
  const classes = useStyles({ borderColor })

  return (
    <section className={classes.section}>
      <ol className={classes.accordion}>
        {items.map(({ title, copy }, index) => (
          <AccordionItem key={index} title={title} copy={copy} index={index} borderColor={borderColor} />
        ))}
      </ol>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    extend: [sliceMarginStyles],
    marginTop: span(sliceMarginSpan),
    [theme.breakpoints.up('md')]: {
      marginTop: span(sliceMarginSpan, 'md')
    },
    '&:after': {
      content: '""',
      clear: 'both',
      display: 'table'
    }
  },
  accordion: {
    padding: 0,
    listStyle: 'none',
    margin: [0, span(1)],
    [theme.breakpoints.up('md')]: {
      margin: [0, span(3, 'md')],
      width: span(16, 'md')
    }
  },
  item: {
    margin: [vw(24), 0],
    borderColor: ({ borderColor }) => borderColor,
    borderBottom: [1, 'solid'],
    [theme.breakpoints.up('md')]: {
      margin: [vw(50, 'desktop'), 0]
    },
    '&:first-child': {
      marginTop: 0
    }
  },
  title: {
    textAlign: 'left',
    color: 'currentColor',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: vw(24),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(50, 'desktop')
    },
    '& > *': {
      margin: 0
    }
  },
  caret: {
    flexShrink: 0,
    marginLeft: vw(16),
    width: 17,
    height: 9,
    display: 'block',
    transform: 'rotate(180deg)',
    [theme.breakpoints.up('md')]: {
      marginLeft: vw(16, 'desktop'),
      width: vw(17, 'desktop'),
      height: vw(9, 'desktop')
    }
  },
  copyContainer: {
    height: 0,
    overflow: 'hidden'
  },
  copy: {
    paddingBottom: vw(24),
    [theme.breakpoints.up('md')]: {
      paddingBottom: vw(50, 'desktop')
    }
  }
}, { name: 'AccordionSlice' })

export default AccordionSlice
