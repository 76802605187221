import React, { useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import forEach from 'lodash/forEach'
import theme from '../style/theme'
import remove from 'lodash/remove'
import gsap from 'gsap'
import { getPageYOffset } from './useSmoothScrollbar'
import { vw } from '../style/vw'
import detectIt from 'detect-it'
import useThrottle from '../hooks/useThrottle'
import useWindowResize from '../hooks/useWindowResize'
import { lerp } from '../helpers/math'

const stickies = []
var cursorElement

const CURSOR_ENABLED = true

const animateCursor = (cursor, state) => {
  state.animateVelocity = false
  gsap.to(cursor, {
    scale: state.stick || state.hover || state.drag ? 1.5 : 1,
    fill: state.stick || state.hover ? 'white' : 'transparent',
    duration: 0.5,
    onComplete: () => {
      state.animateVelocity = !state.stick && !state.hover && !state.drag
    }
  })
}

export const useStickyCursor = (ref, usePageOffset) => {
  useEffect(() => {
    if (CURSOR_ENABLED && detectIt.primaryInput !== 'touch' && ref.current) {
      const bounds = ref.current.getBoundingClientRect()
      const sticky = {
        el: ref.current,
        x: bounds.left + bounds.width / 2,
        y: (usePageOffset ? (bounds.top + getPageYOffset()) : bounds.top) + bounds.height / 2,
        usePageOffset
      }
      stickies.push(sticky)
      return () => {
        remove(stickies, item => item.el === ref.current)
      }
    }
  }, [ref])
}

export const useHoverCursor = (ref, enabled = true) => {
  useEffect(() => {
    if (CURSOR_ENABLED && detectIt.primaryInput !== 'touch' && ref.current && enabled) {
      const enter = e => {
        state.hover = true
        state.hoverElement = ref.current
        animateCursor(cursorElement, state)
      }
      const leave = e => {
        state.hover = false
        state.hoverElement = null
        animateCursor(cursorElement, state)
      }
      ref.current.addEventListener('mouseenter', enter)
      ref.current.addEventListener('mouseleave', leave)
      return () => {
        if (ref.current) {
          ref.current.removeEventListener('mouseenter', enter)
          ref.current.removeEventListener('mouseleave', leave)
          if (state.hoverElement === ref.current) {
            leave()
          }
        }
      }
    }
  }, [ref, enabled])
}

export const useDragCursor = (ref) => {
  useEffect(() => {
    if (CURSOR_ENABLED && detectIt.primaryInput !== 'touch' && ref.current) {
      const enter = e => {
        state.drag = true
        animateCursor(cursorElement, state)
      }
      const leave = e => {
        state.drag = false
        animateCursor(cursorElement, state)
      }
      ref.current.addEventListener('mouseenter', enter)
      ref.current.addEventListener('mouseleave', leave)
      return () => {
        ref.current.removeEventListener('mouseenter', enter)
        ref.current.removeEventListener('mouseleave', leave)
      }
    }
  }, [ref])
}

const EASE = 0.15

const data = {
  mouse: {
    x: 0,
    y: 0
  },
  current: {
    x: 0,
    y: 0
  },
  last: {
    x: 0,
    y: 0
  },
  ease: EASE,
  dist: 100,
  fx: {
    diff: 0,
    acc: 0,
    velocity: 0,
    scale: 1
  }
}

const state = {
  stick: false,
  stickElement: null,
  animateVelocity: true,
  hover: false,
  drag: false,
  hoverElement: null
}

export const clearCursorState = () => {
  state.hover = false
  state.drag = false
  state.stick = false
}

function Cursor () {
  const classes = useStyles()
  const ref = useRef()
  const circleRef = useRef()

  useEffect(() => {
    if (CURSOR_ENABLED && detectIt.primaryInput !== 'touch' && ref.current) {
      cursorElement = circleRef.current
      gsap.set(ref.current, { display: 'block' })
    }
  }, [])

  useEffect(() => {
    if (CURSOR_ENABLED && detectIt.primaryInput !== 'touch') {
      const updateMousePosition = (evnt) => {
        const e = evnt.detail && evnt.detail.pageX ? evnt.detail : evnt
        data.mouse.x = e.pageX
        data.mouse.y = e.pageY - (window.pageYOffset || document.documentElement.scrollTop)

        data.current.x = e.pageX
        data.current.y = e.pageY - (window.pageYOffset || document.documentElement.scrollTop)
      }
      window.addEventListener('mousemove', updateMousePosition, { passive: true })
      window.addEventListener('dragover', updateMousePosition, { passive: true })
      window.addEventListener('flickity-dragMove', updateMousePosition, { passive: true })
      return () => {
        window.removeEventListener('mousemove', updateMousePosition)
        window.removeEventListener('dragover', updateMousePosition)
        window.removeEventListener('flickity-dragMove', updateMousePosition)
      }
    }
  }, [])

  const checkSticky = (circleRef, target) => {
    const d = {
      x: target.x - data.mouse.x,
      y: target.y - data.mouse.y - (target.usePageOffset ? getPageYOffset() : 0)
    }

    const a = Math.atan2(d.x, d.y)
    const h = Math.sqrt(d.x * d.x + d.y * d.y)

    if (h < data.dist) {
      if (!state.stick) {
        state.stick = true
        state.stickElement = target
        data.ease = 0.075
        animateCursor(circleRef.current, state)
      }
      data.current.x = target.x - Math.sin(a) * h / 2.5
      data.current.y = (target.y - Math.cos(a) * h / 2.5) - (target.usePageOffset ? getPageYOffset() : 0)
    } else if (h > data.dist && state.stick && target === state.stickElement) {
      state.stick = false
      data.ease = EASE
      animateCursor(circleRef.current, state)
    }
  }

  useEffect(() => {
    if (CURSOR_ENABLED && detectIt.primaryInput !== 'touch') {
      const track = () => {
        if (ref.current) {
          forEach(stickies, el => checkSticky(circleRef, el))
          data.last.x = lerp(data.last.x, data.current.x, data.ease)
          data.last.y = lerp(data.last.y, data.current.y, data.ease)

          data.fx.diff = data.current.x - data.last.x
          data.fx.acc = data.fx.diff / window.innerWidth
          data.fx.velocity = +data.fx.acc
          data.fx.scale = 1 - Math.abs(data.fx.velocity * 5)

          ref.current.style.transform = `translate3d(${data.last.x}px, ${data.last.y}px, 0)`
          if (state.animateVelocity) {
            circleRef.current.style.transform = `scale(${data.fx.scale})`
          }
        }
      }
      gsap.ticker.add(track)
      return () => {
        gsap.ticker.remove(track)
      }
    }
  }, [])

  useWindowResize(useThrottle((event) => {
    if (CURSOR_ENABLED && detectIt.primaryInput !== 'touch') {
      forEach(stickies, sticky => {
        const bounds = sticky.el.getBoundingClientRect()
        sticky.x = bounds.left + bounds.width / 2
        sticky.y = (sticky.usePageOffset ? (bounds.top + getPageYOffset()) : bounds.top) + bounds.height / 2
      })
    }
  }, 500))

  if (!CURSOR_ENABLED) return null

  return (
    <div className={cn(classes.cursor, { active: state.stick })} ref={ref}>
      <svg viewBox='0 0 100 100' width='100%' ref={circleRef}>
        <circle cx='50' cy='50' r='48' strokeWidth='1' stroke='currentColor' />
      </svg>
    </div>
  )
}

const useStyles = createUseStyles({
  cursor: {
    fontSize: vw(25),
    position: 'fixed',
    top: '-1em',
    left: '-1em',
    borderRadius: '50%',
    pointerEvents: 'none',
    zIndex: theme.zIndex.cursor,
    mixBlendMode: 'difference',
    color: theme.colors.white,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      fontSize: vw(25, 'desktop')
    },
    '& svg': {
      width: '2em',
      height: '2em'
    }
  }
}, { name: 'Cursor' })

export default Cursor
