import React, { useRef, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import ResponsiveImage from '../ResponsiveImage'
import { span, sliceMarginStyles } from '../../style/span'
import { vw } from '../../style/vw'
import theme from '../../style/theme'
import SplitWordsAnimation from '../SplitWordsAnimation'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import compassData from '../../animation/compass.json'
import useReversingLottieAnimation from '../../hooks/useReversingLottieAnimation'

const HomepageHeroSlice = ({
  className,
  slice: { title, image }
}) => {
  const classes = useStyles()
  const imageRef = useRef()
  const sectionRef = useRef()
  const iconRef = useReversingLottieAnimation(compassData, true, 0.6)

  useEffect(() => {
    if (iconRef.current) {
      const tl = gsap.timeline()
      const scrollTrigger = ScrollTrigger.create({
        trigger: sectionRef.current,
        pin: false,
        start: 'top top',
        end: 'bottom top',
        scrub: 2,
        animation: tl
      })
      tl.to(iconRef.current.children[0], { rotate: '360deg' })
      return () => {
        tl.kill()
        scrollTrigger.kill()
      }
    }
  }, [])

  useEffect(() => {
    gsap.to(imageRef.current, { y: 0, duration: 1.8, ease: 'expo.out' })
    gsap.to(imageRef.current.children, { y: 0, duration: 1.8, ease: 'expo.out' })
    gsap.to(imageRef.current.children, { scale: 1, duration: 1.4, ease: 'sine.out' })
  }, [])
  return (
    <section className={cn(className, classes.wrapper)} ref={sectionRef}>
      <div className={classes.titleWrapper}>
        <SplitWordsAnimation>
          <h1 className={classes.title}>
            {title}
            <span className={classes.scrollImage} ref={iconRef} />
          </h1>
        </SplitWordsAnimation>
      </div>
      <div className={classes.imageContainer} ref={imageRef}>
        <ResponsiveImage className={classes.image} {...image} disableLazy />
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  wrapper: {
    extend: [sliceMarginStyles]
  },
  titleWrapper: {
    position: 'relative',
    padding: [vw(160), span(1), vw(40)],
    [theme.breakpoints.up('md')]: {
      padding: [vw(160, 'desktop'), span(3, 'md'), vw(40, 'desktop'), span(8, 'md')]
    }
  },
  title: {
    margin: 0,
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.up('md')]: {
      fontSize: vw(146, 'desktop')
    }
  },
  imageContainer: {
    transform: 'translate(0, 100%)',
    overflow: 'hidden',
    clear: 'both',
    [theme.breakpoints.up('md')]: {
      marginRight: span(3, 'md')
    }
  },
  image: {
    transform: 'translate(0, -100%) scale(1.1)',
    '& picture::before': {
      display: 'block',
      content: '""',
      paddingTop: '106%',
      [theme.breakpoints.up('md')]: {
        paddingTop: '64%'
      }
    }
  },
  scrollImage: {
    display: 'inline-block',
    width: vw(40),
    height: vw(40),
    margin: [0, vw(6), 0],
    float: 'left',
    '& svg *': {
      fill: 'currentColor'
    },
    [theme.breakpoints.up('md')]: {
      width: vw(120, 'desktop'),
      height: vw(120, 'desktop'),
      margin: [0, vw(8, 'desktop'), 0, 0]
    }
  }
}, { name: 'HomepageHeroSlice' })

export default HomepageHeroSlice
