import theme from './theme'
import round from '../helpers/round'

export const span = (col, breakpoint = 'min') => {
  const ratio = col / theme.grid.values[breakpoint]

  return `calc(${round(ratio * 100, 4)}vw - ${round(ratio, 6)} * ${theme.scrollbarWidthVar})`
}

export const spanWithoutCalc = (col, breakpoint = 'min') => {
  const ratio = col / theme.grid.values[breakpoint]

  return `${round(ratio * 100, 4)}vw - ${round(ratio, 6)} * ${theme.scrollbarWidthVar}`
}

export const spanPx = (col, breakpoint = 'min') => {
  const ratio = col / theme.grid.values[breakpoint]
  return (round(ratio * window.innerWidth, 4))
}

export const sliceMarginSpan = 3

export const sliceMarginStyles = {
  marginBottom: span(sliceMarginSpan),
  [theme.breakpoints.up('md')]: {
    marginBottom: span(sliceMarginSpan, 'md')
  }
}
