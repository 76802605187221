/* eslint-env browser */
import { ActionType } from 'redux-promise-middleware'
import { rehydrated } from '../actions'
import { pageContentActionCreator } from '../slices/content/page'
import { getPageTitle } from '../slices/content'
import inDOM from 'dom-helpers/canUseDOM'

export default store => next => action => {
  if (!inDOM) return next(action)

  window.dataLayer = window.dataLayer || []

  const ret = next(action)
  switch (action.type) {
    case `${pageContentActionCreator}_${ActionType.Fulfilled}`:
    case rehydrated.toString(): {
      const title = getPageTitle(store.getState())
      window.dataLayer.push({
        event: 'pageview',
        page: window.location.pathname,
        title
      })
      break
    }
    // no default
  }

  return ret
}
