import React from 'react'

export const sanitize = text => {
  return text && text.replace ? text.replace(/\n/g, '<br/>') : text
}

const Heading = ({ tag = 'h3', text, ...rest }) => {
  const Tag = tag
  return <Tag {...rest} dangerouslySetInnerHTML={{ __html: sanitize(text) }} />
}

export default Heading
