import layout from './layout'
import content from './content'
import config from './config'
import cookie from './cookie'
import cart from './cart'
import shopProducts from './shopProducts'

export default {
  config,
  layout,
  content,
  cookie,
  cart,
  shopProducts
}
