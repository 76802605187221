import React, { forwardRef, useRef, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { enableSticky } from '../../../redux/slices/shopProducts'
import useResizeObserver from '../../../hooks/useResizeObserver'
import { createUseStyles } from 'react-jss'
import map from 'lodash/map'
import cn from 'classnames'
import Product from './Product'
import { sliceMarginStyles } from '../../../style/span'
import theme from '../../../style/theme'

const ShopProducts = forwardRef(({ className, slice }, ref) => {
  const classes = useStyles()
  const { products } = slice
  const containerRef = useRef()
  const dispatch = useDispatch()

  const onResize = useCallback(() => {
    if (containerRef.current) {
      dispatch(enableSticky())
    }
  }, [])

  useEffect(() => {
    onResize()
  }, [onResize])

  useResizeObserver(containerRef, onResize, [onResize])

  return (
    <section className={cn(className, classes.section)} ref={containerRef}>
      {map(products, (product, i) => <Product key={product.id} index={i} {...product} />)}
    </section>
  )
})

const useStyles = createUseStyles({
  section: {
    extend: [sliceMarginStyles],
    borderBottom: `1px solid ${theme.colors.border}`
  }
}, { name: 'ShopProducts' })

export default ShopProducts
