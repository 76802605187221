import React, { forwardRef, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import RichText from '../RichText'
import { sliceMarginStyles, span } from '../../style/span'
import ResponsiveImage from '../ResponsiveImage'
import { LinkWithCursorHoverEffect } from '../Link'
import { resolveLink } from '../../helpers/resolveLink'
import theme from '../../style/theme'
import { vw } from '../../style/vw'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import forEach from 'lodash/forEach'
import detectIt from 'detect-it'

const TwoImageCopySlice = forwardRef(({ className, slice }, ref) => {
  const {
    title,
    copy,
    copy_alignment: copyAlignment,
    page_number: pageNumber,
    link,
    left_image: leftImage,
    right_image: rightImage
  } = slice
  const classes = useStyles()
  const leftImageRef = useRef()
  const rightImageRef = useRef()
  const sectionRef = useRef()

  const reverse = copyAlignment === 'right'

  useEffect(() => {
    if (leftImageRef.current) {
      const animations = [leftImageRef.current, rightImageRef.current].map(
        element => {
          const tl = gsap.timeline()
          const scrollTrigger = ScrollTrigger.create({
            trigger: element,
            start: 'top bottom',
            end: 'top 40%',
            scrub: detectIt.primaryInput === 'touch' ? 0.5 : 1.5,
            animation: tl
          })
          tl.fromTo(element, { rotate: 4 }, { rotate: 0 }, 0)
          return {
            tl,
            scrollTrigger
          }
        })
      return () => {
        forEach(animations, ({ tl, scrollTrigger }) => {
          tl.kill()
          scrollTrigger.kill()
        })
      }
    }
  }, [])

  return (
    <section className={cn(classes.section, className)} ref={sectionRef}>
      <div className={classes.wrapper}>
        {title && <h3 className={classes.title}>{title}</h3>}
        <div className={cn(classes.content, { reverse })}>
          <div className={classes.leftImageContainer}>
            <div className={cn(classes.copyContainer, { reverse, noCopy: !copy })}>
              {pageNumber && <span className={classes.pageNumber}>{pageNumber}</span>}
              {copy && <RichText content={copy.text} className={classes.copy} />}
              {link && <LinkWithCursorHoverEffect link={resolveLink(link[0])} className={classes.link} />}
            </div>
            <ResponsiveImage {...(reverse ? rightImage : leftImage)} className={cn(classes.leftImage, { reverse })} aspect={560 / 720} ref={leftImageRef} />
          </div>
          <div className={classes.rightImageContainer}>
            <ResponsiveImage {...(reverse ? leftImage : rightImage)} className={cn(classes.rightImage, { reverse })} aspect={560 / 720} ref={rightImageRef} />
          </div>
        </div>
      </div>
    </section>
  )
})

const useStyles = createUseStyles({
  section: {
    extend: [sliceMarginStyles]
  },
  wrapper: {
    margin: [0, span(1)],
    [theme.breakpoints.up('md')]: {
      margin: [0, span(3, 'md'), 0, span(2, 'md')]
    }
  },
  content: {
    '&.reverse': {
      flexDirection: 'row-reverse'
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  },
  title: {
    marginBottom: vw(40),
    lineHeight: 1,
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(120, 'desktop'),
      maxWidth: span(16, 'md')
    }
  },
  imageContainer: {
    width: '100%',
    flexShrink: 0,
    [theme.breakpoints.up('md')]: {
      width: span(10, 'md')
    }
  },
  leftImageContainer: {
    composes: '$imageContainer',
    position: 'relative',
    marginBottom: vw(40),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0
    }
  },
  rightImageContainer: {
    composes: '$imageContainer'
  },
  leftImage: {
    [theme.breakpoints.down('md')]: {
      width: span(9),
      marginLeft: 'auto'
    },
    '&.reverse': {
      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
        marginRight: 'auto'
      }
    }
  },
  rightImage: {
    [theme.breakpoints.down('md')]: {
      width: span(9),
      marginRight: 'auto'
    },
    '&.reverse': {
      [theme.breakpoints.down('md')]: {
        marginLeft: 'auto',
        marginRight: 0
      }
    }
  },
  contentMargins: {
    marginBottom: vw(40),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(40, 'desktop')
    }
  },
  copyContainer: {
    width: '100%',
    marginBottom: vw(70),
    '&.noCopy': {
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    [theme.breakpoints.up('md')]: {
      minHeight: vw(80, 'desktop'),
      width: span(7, 'md'),
      marginBottom: vw(100, 'desktop')
    },
    '&.reverse': {
      [theme.breakpoints.up('md')]: {
        marginLeft: span(2, 'md')
      }
    }
  },
  copy: {
    composes: '$contentMargins'
  },
  link: {
    display: 'inline-block',
    textTransform: 'uppercase',
    textDecoration: 'none',
    position: 'relative',
    ...theme.link()
  },
  pageNumber: {
    position: 'absolute',
    top: 0,
    left: 0,
    fontFamily: theme.fonts.headings,
    fontWeight: 'bold',
    fontSize: vw(24),
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      fontSize: vw(24, 'desktop')
    }
  }
}, { name: 'TwoImageCopySlice' })

export default TwoImageCopySlice
