import { rehydrated } from '../actions'
import { setCookieAccepted, acceptCookie } from '../slices/cookie'

const KEY = 'discovered_cookiesAccepted_1'

export default store => next => action => {
  const ret = next(action)
  switch (action.type) {
    case rehydrated.toString():
      store.dispatch(setCookieAccepted(!!window.localStorage.getItem(KEY)))
      break
    case acceptCookie.toString(): {
      if (!window.localStorage.getItem(KEY)) {
        window.localStorage.setItem(KEY, true)
      }
      break
    }
  }
  return ret
}
