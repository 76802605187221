import React, { useCallback, useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import { createUseStyles } from 'react-jss'
import SplitText from 'gsap/SplitText'
import { useInView } from 'react-intersection-observer'
import composeRefs from '../helpers/composeRefs'
import wrapAll from '../helpers/wrapAll'
import unwrap from '../helpers/unwrap'
import useWebFontLoaded from '../hooks/useWebFontLoaded'
import forEach from 'lodash/forEach'

gsap.registerPlugin(SplitText)

function useSplitWordsAnimation ({ linesOnly, ready = true }) {
  const [inViewRef, inView] = useInView({ threshold: 0.5, triggerOnce: true })
  const [fontsReady, setFontsReady] = useState(false)
  const fontsReadyRef = useWebFontLoaded(useCallback(() => {
    setFontsReady(true)
  }, []))

  const locals = useRef({}).current

  const mountRef = useCallback((el) => {
    if (el) {
      if (!fontsReady || !ready) {
        return
      }
      locals.split = new SplitText(el, { type: 'chars,lines' })
      gsap.set(locals.split.lines, { overflow: 'hidden' })
      locals.targets = locals.split.lines.map(
        line => wrapAll(line.childNodes, document.createElement('div'))
      )
      if (!linesOnly) {
        gsap.set(locals.split.chars, { yPercent: 100, visibility: 'hidden', opacity: 0 })
      } else {
        gsap.set(locals.targets, { yPercent: 100, visibility: 'hidden', opacity: 0 })
      }
      gsap.set(el, { visibility: 'visible' })
      locals.el = el
    } else if (locals.split) {
      locals.targets.forEach(innerLine => {
        unwrap(innerLine)
      })
      locals.split.revert()
      delete locals.targets
      delete locals.split
      delete locals.el
    }
  }, [locals, fontsReady, ready])

  const inViewAndReady = inView && fontsReady && ready
  useEffect(() => {
    if (!locals.el) {
      return
    }

    if (inViewAndReady) {
      if (linesOnly) {
        gsap.to(
          locals.targets,
          { ease: 'expo.out', yPercent: 0, stagger: 0.06, duration: 1.2, opacity: 1, visibility: 'visible' }
        )
      } else {
        forEach(locals.targets, (target, i) => {
          gsap.to(
            target.children,
            { ease: 'expo.out', yPercent: 0, stagger: 0.06, duration: 1.2, opacity: 1, visibility: 'visible', delay: i * 0.1 }
          )
        })
      }
    }
  }, [inViewAndReady])

  return composeRefs(inViewRef, fontsReadyRef, mountRef)
}

const SplitWordsAnimation = ({ children, srOnlyContent, ready = true, linesOnly = false, screenReaderClone = true }) => {
  const classes = useStyles()
  const child = React.Children.only(children)
  const content = child.props.children
  const ref = useSplitWordsAnimation({ linesOnly, ready })
  return React.cloneElement(child, {}, (
    <>
      {screenReaderClone && <div className='srOnly'>{srOnlyContent || content}</div>}
      <div className={classes.splitWords} ref={ref} aria-hidden='true'>
        {content}
      </div>
    </>
  ))
}

const useStyles = createUseStyles({
  splitWords: {
    visibility: 'hidden',
    '& > div': {
      marginBottom: '-0.1em',
      '& > div': {
        paddingBottom: '0.1em'
      }
    }
  },
  srOnly: {
    composes: ['srOnly']
  }
})

export default SplitWordsAnimation
