import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import gsap from 'gsap'
import CSSPlugin from 'gsap/CSSPlugin'
import JssProvider from './style/JssProvider'
import { rehydrated } from './redux/actions'
import createStore from './redux/createStore'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import initLazySizes from './initLazySizes'

// XXX this shouldn’t be necessary, but the code in gsap-core.js incorrectly imports CSSPlugin by
// name (it is exported as `default`) so the automatic registration of CSSPlugin does not occur.
gsap.registerPlugin(CSSPlugin)

function getPreloadedState () {
  const preloadedStateEl = document.getElementById('redux-preinit')
  if (preloadedStateEl) {
    const preloadedState = JSON.parse(preloadedStateEl.innerText)
    preloadedStateEl.parentNode.removeChild(preloadedStateEl)
    return preloadedState
  }
}

const { store } = createStore({ preloadedState: getPreloadedState() })
const rootEl = document.getElementById('root')

const render = App => (
  <ReduxProvider store={store}>
    <JssProvider>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </JssProvider>
  </ReduxProvider>
)

ReactDOM.hydrate(render(App), rootEl, () => {
  // We don't need the static css any more once we have launched our application
  const style = document.getElementById('jss-preinit')
  if (style) {
    style.parentNode.removeChild(style)
  }
  initLazySizes()
  store.dispatch(rehydrated())
})

if (module.hot) {
  module.hot.accept('./components/App', function () {
    ReactDOM.render(render(App), rootEl)
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
