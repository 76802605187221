import get from 'lodash/get'
import map from 'lodash/map'
import last from 'lodash/last'
import find from 'lodash/find'
import keyBy from 'lodash/keyBy'
import filter from 'lodash/filter'
import defaults from 'lodash/defaults'
import { createSelector } from 'reselect'
import { getCurrentRoutePath, isPreviewRoute, getCurrentQueryString } from '../location'

export const isContentBusy = state => !!(get(state, ['content', 'page', 'busy']) || get(state, ['content', 'global', 'busy']))
export const getContentError = state => state.content.page.error || state.content.global.error
export const getPageContent = state => get(state, ['content', 'page', 'content'])
export const isContentError404 = state => get(getContentError(state), ['status']) === 404

export const getGlobalContent = state => get(state, ['content', 'global', 'content'])
export const getFooter = state => get(getGlobalContent(state), ['footer'])
export const isContentReadyOrError = state => !isContentBusy(state) && !!(
  (getPageContent(state) && getGlobalContent(state)) || getContentError(state)
)

export const getSiteTitle = state => get(getGlobalContent(state), ['title'])
export const getSocialLinks = state => get(getGlobalContent(state), ['socialLinks'])

export const getPageId = state => get(getPageContent(state), ['id'])
export const getPageSlug = state => get(getPageContent(state), ['slug'])
export const getPageType = state => get(getPageContent(state), ['type'])
export const getPageTitle = state => get(getPageContent(state), ['title'])
export const getPageSubTitle = state => get(getPageContent(state), ['subtitle'])
export const getPageBackgroundColor = state => get(getPageContent(state), ['color'])

export const getCartContent = state => get(getGlobalContent(state), ['cart'])
export const getAllowedPostcodes = state => get(getGlobalContent(state), ['allowed_postcodes'])
export const getCartTitle = state => get(getCartContent(state), ['title'])
export const getCartCheckoutLabel = state => get(getCartContent(state), ['checkout_label'])
export const getCartEmptyCopy = state => get(getCartContent(state), ['empty_cart_copy'])
export const getCartCouponCopy = state => get(getCartContent(state), ['coupon_copy'])
export const getGoToShopLink = state => get(getCartContent(state), ['go_to_shop_link'])
export const getCartBuyButtonLabels = createSelector(
  getCartContent,
  content => ({
    addToCartLabel: content.add_to_cart_label,
    addingToCartLabel: content.adding_to_cart_label,
    addedToCartLabel: content.added_to_cart_label,
    outOfStockLabel: content.out_of_stock_label || 'Out Of Stock'
  })
)
export const getProductDefaultsByHandle = createSelector(
  getGlobalContent,
  global => keyBy(get(global, ['product_defaults'], []), x => x.handle_key)
)

export const getPageMetaData = createSelector(
  getGlobalContent,
  getPageContent,
  getCurrentRoutePath,
  (global, page, path) => {
    const defaultCanonicalUrl = `${process.env.REACT_APP_CANONICAL_URL_BASE}${path}`
    return defaults(
      {},
      get(page, ['seo'], {}),
      {
        // these are the defaults for fields that aren't set above
        metaTitle: get(page, ['title']),
        siteName: get(global, ['title']),
        ogUrl: defaultCanonicalUrl,
        canonicalUrl: defaultCanonicalUrl
      }
    )
  }
)

export const isHomepage = createSelector(
  getPageSlug,
  (slug) => slug === 'home'
)

export const getPageHeadTitle = createSelector(
  getGlobalContent,
  getPageMetaData,
  getPageTitle,
  (global, meta, title = get(meta, ['title'])) => {
    const globalTitle = get(global, ['title'])
    if (!title) {
      return globalTitle
    }
    if (!globalTitle) {
      return title
    }
    return `${title} – ${globalTitle}`
  }
)

export const getPageSlices = createSelector(
  getPageContent,
  (page) => {
    return (page && page.slices) ? map(page.slices, (slice, index) => ({
      id: `${page.id}-${index}`,
      ...slice
    })) : undefined
  }
)

export const getPreviewMode = state => get(state, ['content', 'preview'])

export const isPreviewMode = createSelector(
  isPreviewRoute,
  getPreviewMode,
  (previewRoute, previewMode) => previewRoute || previewMode
)

export const getMenuItems = createSelector(
  getGlobalContent,
  getCurrentRoutePath,
  (global, path) => {
    const menu = get(global, ['menu'])
    if (!menu) return []
    return menu.map(item => ({
      ...item,
      selected: get(item, ['reference', 'slug']) === last(path.split('/'))
    }))
  }
)

export const getMenuImage = state => get(getGlobalContent(state), ['menu_background_image'])
export const getSubMenuItems = state => get(getGlobalContent(state), ['submenu'])
export const getAcknowledgement = state => get(getGlobalContent(state), ['acknowledgement'])
export const getFooterCopyright = state => get(getFooter(state), ['copyright'])
export const getFooterLinks = state => get(getFooter(state), ['links'])
export const getFooterPartners = state => get(getFooter(state), ['partners'])
export const getFooterPartnersLabel = state => get(getFooter(state), ['partners_label'])

export const isRehydrated = state => state.content.rehydrated

export const getAnchorPoints = createSelector(
  getPageSlices,
  (slices) => {
    return filter(slices, x => x.type === 'anchor_point')
  }
)

export const getProducts = state => get(getGlobalContent(state), ['products'])

export const GetPageListSlice = createSelector(
  getPageSlices,
  (slices) => find(slices, x => x.type === 'page_list_slice')
)

export const getPages = state => get(state, ['content', 'pages', 'content', 'pages'])
export const getPagesTotal = state => get(state, ['content', 'pages', 'content', 'total'])
export const getPagesIndex = state => get(state, ['content', 'pages', 'content', 'page'])
export const isPagesBusy = state => !!(get(state, ['content', 'pages', 'busy']))
export const isPagesLoaded = state => get(state, ['content', 'pages', 'content'])

export const getPagesQueryStringArguments = (state) => {
  const qs = getCurrentQueryString(state)
  const page = parseInt(get(qs, ['page'], 0))
  return { page }
}

export const getCookieBannerCopy = state => get(getGlobalContent(state), ['cookie_banner_copy'])
