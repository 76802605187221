import React, { useState, useCallback, useContext } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import ResponsiveImage from '../ResponsiveImage'
import { sliceMarginStyles, span } from '../../style/span'
import theme from '../../style/theme'
import { vw } from '../../style/vw'
import SplitWordsAnimation from '../SplitWordsAnimation'
import get from 'lodash/get'
import BadgeElementsBlock from '../RichText/BadgeElementsBlock'
import ColorContext from '../ColorContext'
import { useSelector } from 'react-redux'
import { getPageSlices } from '../../redux/slices/content'
import last from 'lodash/last'

const PageHero = ({ className, slice }) => {
  const { title, copy, image, badges } = slice
  const slices = useSelector(getPageSlices)
  const { borderColor } = useContext(ColorContext)
  const badge = get(badges, [0])
  const classes = useStyles({ color: get(image, ['palette', 'dominant', 'title']), borderColor })
  const [ready, setReady] = useState(!image)
  const onImageLoaded = useCallback(() => { setReady(true) }, [])

  return (
    <section className={cn(classes.section, { withoutImage: !image, withoutMargin: last(slices) === slice })}>
      {image && <ResponsiveImage {...image} className={classes.image} onImageLoaded={onImageLoaded} parallax={theme.parallax} />}
      {title && (
        <SplitWordsAnimation ready={ready}>
          <h1 className={classes.title}>
            {title}
            {badge && <BadgeElementsBlock className={classes.icon} node={badge} />}
            {/* <svg className={classes.icon} width={100} height={100} viewBox='0 0 100 100'><rect stroke={5} width={100} height={100} x={0} y={0} /></svg> */}
          </h1>
        </SplitWordsAnimation>
      )}
      {copy && <p className={classes.copy}>{copy}</p>}
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    extend: [sliceMarginStyles],
    position: 'relative',
    padding: [0, span(1)],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100vh',
    color: ({ color }) => color || 'currentColor',
    borderColor: ({ borderColor }) => borderColor,
    [theme.breakpoints.up('md')]: {
      padding: [0, span(3, 'md')]
    },
    '&.withoutImage': {
      minHeight: 'auto',
      marginBottom: 0,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      padding: [vw(100), span(1), 0],
      [theme.breakpoints.up('md')]: {
        padding: [0, span(3, 'md')]
      }
    },
    '&.withoutMargin': {
      marginBottom: 0
    }
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(51,51,51,0.3)'
    }
  },
  title: {
    position: 'relative',
    margin: [0, 0, vw(40)],
    width: span(11),
    fontSize: vw(50),
    [theme.breakpoints.up('md')]: {
      margin: [vw(230, 'desktop'), 0, vw(150, 'desktop')],
      fontSize: vw(160, 'desktop'),
      width: span(16.4, 'md')
    },
    '& > *': {
      margin: 0
    }
  },
  copy: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      width: span(6, 'md'),
      fontSize: vw(18, 'desktop'),
      lineHeight: 1.6,
      margin: [0, 0, vw(100, 'desktop'), span(12, 'md')]
    }
  },
  icon: {
    float: 'left',
    padding: ['0.03em', 0, '0.2em'],
    '& path': {
      fill: 'currentColor'
    }
  }
}, { name: 'PageHero' })

export default PageHero
