import inDOM from 'dom-helpers/canUseDOM'
import { createSlice } from '@reduxjs/toolkit'
import { rehydrated, routeContent } from '../actions'
import { current, keys } from '../../style/breakpoints'

const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    breakpoint: current(),
    breakpointSet: inDOM,
    menuOpen: false,
    cartOpen: false
  },
  reducers: {
    breakpointChanged: (state, action) => {
      state.breakpoint = action.payload
      state.breakpointSet = true
    },
    openMenu: (state, action) => {
      state.menuOpen = true
    },
    closeMenu: (state, action) => {
      state.menuOpen = false
    },
    toggleCartDialog: (state, action) => {
      state.cartOpen = !state.cartOpen
    },
    openCartDialog: (state, action) => {
      state.cartOpen = true
    },
    closeCartDialog: (state, action) => {
      state.cartOpen = false
    }
  },
  extraReducers: {
    [rehydrated]: (state, action) => {
      state.breakpointSet = true
    },
    [routeContent]: (state, action) => {
      state.menuOpen = false
      state.cartOpen = false
    }
  }
})

export const {
  breakpointChanged,
  openMenu,
  closeMenu,
  toggleCartDialog,
  openCartDialog,
  closeCartDialog
} = layoutSlice.actions

export const getCurrentBreakpoint = state => state.layout.breakpoint
export const isCurrentBreakpointAtLeast = (state, breakpoint) => (
  keys.indexOf(getCurrentBreakpoint(state)) >= keys.indexOf(breakpoint)
)
export const isBreakpointSet = state => state.layout.breakpointSet
export const isCurrentBreakpointMobile = state => !isCurrentBreakpointAtLeast(state, 'md')

export const isMenuOpen = state => state.layout.menuOpen

export const isCartDialogOpen = state => state.layout.cartOpen

export default layoutSlice.reducer
