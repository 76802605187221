import React from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { getCartCheckoutLink } from '../../redux/slices/cart'
import { getCartCheckoutLabel, getGoToShopLink } from '../../redux/slices/content'
import Button from '../Button'
import PostcodeValidator from './PostcodeValidator'
import { resolveLink } from '../../helpers/resolveLink'

const CartFooter = (props) => {
  const classes = useStyles()
  const checkoutLink = useSelector(getCartCheckoutLink)
  const checkoutLabel = useSelector(getCartCheckoutLabel)
  const goToShopLink = useSelector(getGoToShopLink)
  const {
    postcodeValid,
    setPostcodeValid,
    cartIsEmpty
  } = props

  return (
    <footer className={classes.footer}>
      <div className={classes.postCodeValidatorWrapper}>
        <PostcodeValidator
          isValid={postcodeValid}
          setValid={setPostcodeValid}
        />
      </div>
      {cartIsEmpty ? (
        <Button
          className={classes.checkoutButton}
          link={resolveLink(goToShopLink[0])}
          label={goToShopLink[0].text}
        />
      ) : (
        <Button
          className={classes.checkoutButton}
          link={resolveLink(checkoutLink)}
          label={checkoutLabel}
          disabled={!postcodeValid}
        />
      )}
    </footer>
  )
}

const useStyles = createUseStyles({
  footer: {
    flex: '0 0 auto'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    '& p': {

    }
  },
  postCodeValidatorWrapper: {
    marginBottom: 20
  },
  checkoutButton: {
    display: 'block',
    width: '100%'
  }
}, { name: 'CartFooter' })

export default CartFooter
