import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { Helmet } from 'react-helmet-async'
import theme from '../style/theme'
import { useSelector } from 'react-redux'
import { getPageMetaData, getPageHeadTitle } from '../redux/slices/content'
import Page from './Page'
import Cursor from './Cursor'
import MenuDialog from './MenuDialog'
import CartDialog from './Cart/CartDialog'

function App () {
  const classes = useStyles() /* eslint-disable-line */
  const meta = useSelector(getPageMetaData)
  const headTitle = useSelector(getPageHeadTitle)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=SqM297'
    script.type = 'text/javascript'
    document.body.appendChild(script)
  }, [])

  useEffect(() => {
    const isFormSubmitted = window.localStorage.getItem('klaviyoFormSubmitted')
    // console.log('Is form submitte  d:', isFormSubmitted)
    if (isFormSubmitted === null) {
      setTimeout(() => {
        // console.log('5 Seconds Passed. Opening form.')
        window._klOnsite = window._klOnsite || []
        // console.log('Klaviyo --> ' + window._klOnsite)
        window._klOnsite.push(['openForm', 'XGPMhs'])
        let attempts = 0
        const checkAndAttachButton = setInterval(() => {
          const submitButton = document.querySelector('button.go952291206')
          if (submitButton) {
            console.log('Submit button found, adding event listener.')
            submitButton.addEventListener('click', () => {
              // console.log('Button clicked. Setting local storage for submission.')
              window.localStorage.setItem('klaviyoFormSubmitted', 'true')
            })
            clearInterval(checkAndAttachButton)
          } else {
            attempts++
            // console.log('Attempt', attempts++, ': Submit button not found.')
          }
          if (attempts > 10) { // Stop trying after a certain number of attempts
            clearInterval(checkAndAttachButton)
            // console.log('Stopped looking for the button after 10 attempts.')
          }
        }, 1000)
      }, 5000)
    } else {
      // console.log('Form already submitted, not showing popup.')
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{headTitle}</title>
        {meta && (
          <>
            <meta name='title' content={meta.metaTitle} />
            {meta.metaDescription && <meta name='description' content={meta.metaDescription} />}
            {meta.metaKeywords && <meta name='keywords' content={meta.metaKeywords} />}
            {meta.ogUrl && <meta property='og:url' content={meta.ogUrl} />}
            <meta property='og:site_name' content={meta.siteName} />
            <meta property='og:title' content={meta.metaTitle} />
            {meta.metaDescription && <meta property='og:description' content={meta.metaDescription} />}
            {meta.metaImage && [
              <meta property='og:image' content={meta.metaImage.url} key={0} />,
              <meta property='og:image:width' content={meta.metaImage.width} key={1} />,
              <meta property='og:image:height' content={meta.metaImage.height} key={2} />
            ]}
            {meta.metaImage && <meta name='twitter:card' content={meta.twitterCardType} />}
            {meta.siteName && <meta name='twitter:site' content={meta.siteName} />}
            <meta name='twitter:title' content={meta.metaTitle} />
            {meta.metaDescription && <meta name='twitter:description' content={meta.metaDescription} />}
            {meta.metaImage && <meta name='twitter:image' content={meta.metaImage.url} />}
            <link rel='canonical' href={meta.canonicalUrl} />
          </>
        )}
      </Helmet>
      <Page />
      <Cursor />
      <MenuDialog />
      <CartDialog />
    </>
  )
}

const useStyles = createUseStyles({
  '@global': {
    ...theme.global,
    '#root': {
      backgroundColor: theme.colors.backgroundColors.default
    },
    '.CartDialog-panel': {
      display: 'none'
    }
  }
}, { name: 'App' })

export default App
