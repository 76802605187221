import * as breakpoints from './breakpoints'
import * as fonts from './fonts'
import find from 'lodash/find'
import color from 'color'
import { vw } from './vw'
import detectIt from 'detect-it'

const parallax = {
  ratio: 0.8,
  scale: 1.2
}

const colors = {
  black: '#000',
  text: '#000',
  white: '#fff',
  textSecondary: '#D9E1CC',
  border: 'rgba(47, 66, 64, 0.25)',
  background: '#E5E5E5',
  primary: '#38524F',
  error: '#8B2201',
  success: '#018B40',
  backgroundColors: {
    green: {
      background: '#031F1C',
      foreground: '#D9E1CC',
      borderColor: '#2F4240'
    },
    lightGreen: {
      background: '#D9E1CC',
      foreground: '#031F1C',
      borderColor: 'rgba(47, 66, 64, 0.25)'
    },
    white: {
      background: '#F4F2ED',
      foreground: '#38524F',
      borderColor: 'rgba(47, 66, 64, 0.25)'
    },
    default: {
      background: '#EBEAE4',
      foreground: '#38524F',
      borderColor: 'rgba(47, 66, 64, 0.25)'
    }
  }
}

const grid = {
  values: {
    min: 14,
    md: 26
  }
}

const headingsSelector = 'h1,h2,h3,h4,h5,h6'
const globalStyles = {
  '@font-face': fonts.faces,
  '*, *::before, *::after': {
    boxSizing: 'border-box',
    '-webkit-tap-highlight-color': 'transparent'
  },
  ':root': {
    '--scrollbar-width': '0px'
  },
  html: {
    lineHeight: 1.6
  },
  body: {
    ...(
      process.env.NODE_ENV === 'development'
        ? require('./grid').generateGridStyles(grid.values)
        : undefined
    ),
    backgroundColor: colors.white,
    color: colors.text,
    fontFamily: fonts.body,
    fontWeight: fonts.bodyFontWeight,
    fontSize: 18,
    height: '100vh',
    width: '100%',
    '-webkitFontSmoothing': 'antialiased',
    '-mozOsxFontSmoothing': 'grayscale',
    [breakpoints.up('lg')]: {
      fontSize: vw(18, 'desktop')
    }
  },
  [headingsSelector]: {
    fontFamily: fonts.headings,
    fontWeight: fonts.headingsFontWeight,
    lineHeight: 0.8,
    margin: [0, 0, '0.6em']
  },
  h1: {
    fontSize: vw(54),
    lineHeight: 1,
    [breakpoints.up('md')]: {
      fontSize: vw(120, 'desktop'),
      letterSpacing: '-0.01em'
    }
  },
  h2: {
    fontSize: vw(42),
    letterSpacing: '0.005em',
    lineHeight: 0.76,
    [breakpoints.up('md')]: {
      lineHeight: 1,
      letterSpacing: '-0.01em',
      fontSize: vw(110, 'desktop')
    }
  },
  h3: {
    fontSize: vw(36),
    lineHeight: 1.3,
    [breakpoints.up('md')]: {
      lineHeight: 1,
      fontSize: vw(72, 'desktop')
    }
  },
  h4: {
    fontSize: vw(32),
    lineHeight: 1.2,
    [breakpoints.up('md')]: {
      lineHeight: 1.3,
      fontSize: vw(36, 'desktop')
    }
  },
  h5: {
    fontSize: vw(22),
    lineHeight: 1.2,
    letterSpacing: '0.02em',
    margin: [0, 0, '1.2em'],
    [breakpoints.up('md')]: {
      lineHeight: 1.2,
      fontSize: vw(24, 'desktop'),
      margin: [0, 0, '1em']
    }
  },
  h6: {
    fontSize: vw(18),
    lineHeight: 1.2,
    margin: [0, 0, '1.6em'],
    [breakpoints.up('md')]: {
      lineHeight: 1.4,
      fontSize: vw(16, 'desktop')
    }
  },
  p: {
    margin: [0, 0, '1.4em']
  },
  '::placeholder': {
    color: colors.primary,
    opacity: 0.6
  },
  '::selection': {
    backgroundColor: color(colors.backgroundColors.green.foreground).hex()
  },
  button: {
    userSelect: 'none',
    cursor: 'pointer',
    appearance: 'none',
    outline: 'none',
    border: 'none',
    background: 'none',
    padding: 0,
    margin: 0
  },
  '.srOnly': {
    border: '0 !important',
    clip: 'rect(1px, 1px, 1px, 1px) !important',
    clipPath: 'inset(50%) !important',
    height: '1px !important',
    margin: '-1px !important',
    overflow: 'hidden !important',
    padding: '0 !important',
    position: 'absolute !important',
    width: '1px !important',
    whiteSpace: 'nowrap !important'
  },
  a: {
    color: 'currentColor',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  '.flickity-button': {
    display: 'none'
  },
  // For modern browsers
  // 1. The space content is one way to avoid an Opera bug when the
  //    contenteditable attribute is included anywhere else in the document.
  //    Otherwise it causes space to appear at the top and bottom of elements
  //    that are clearfixed.
  // 2. The use of `table` rather than `block` is only necessary if using
  //    `:before` to contain the top-margins of child elements.
  '.clearFix': {
    '&::before,&::after': {
      content: '" "', // 1
      display: 'table' // 2
    },
    '&::after': {
      clear: 'both'
    }
  },
  'section, footer': {
    zIndex: 2,
    position: 'relative'
  }
}

const noiseAnimation = {
  '0%': {
    transform: 'translate3d(0,9rem,0)'
  },
  '10%': {
    transform: 'translate3d(-1rem,-4rem,0)'
  },
  '20%': {
    transform: 'translate3d(-8rem,2rem,0)'
  },
  '30%': {
    transform: 'translate3d(9rem,-9rem,0)'
  },
  '40%': {
    transform: 'translate3d(-2rem,7rem,0)'
  },
  '50%': {
    transform: 'translate3d(-9rem,-4rem,0)'
  },
  '60%': {
    transform: 'translate3d(2rem,6rem,0)'
  },
  '70%': {
    transform: 'translate3d(7rem,-8rem,0)'
  },
  '80%': {
    transform: 'translate3d(-9rem,1rem,0)'
  },
  '90%': {
    transform: 'translate3d(6rem,-5rem,0)'
  },
  to: {
    transform: 'translate3d(-7rem,0,0)'
  }
}

export default {
  headingsSelector,
  global: globalStyles,
  breakpoints,
  grid,
  colors,
  fonts,
  parallax,
  noiseAnimation,
  zIndex: {
    // Keep all zIndexes here in one place so it’s easy to see what order things are in,
    noise: 1, // we want the noise below the section content but above the background color
    section: 2,
    cookieBanner: 101,
    cart: 102,
    header: 100,
    menu: 99,
    sidebar: 98,
    pageLoadingBar: 102,
    cursor: 1000
  },
  scrollbarWidthVar: 'var(--scrollbar-width)',
  getForegroundColor: (backgroundColor) => {
    const color = find(colors.backgroundColors, c => c.background === backgroundColor)
    if (color) return color.foreground
    return colors.backgroundColors.default.foreground
  },
  getBorderColor: (backgroundColor) => {
    const color = find(colors.backgroundColors, c => c.background === backgroundColor)
    if (color) return color.borderColor
    return 'currentColor'
  },
  link: (height = 1, offset = 0) => ({
    ...(detectIt.primaryInput === 'touch' ? {
      overflow: 'hidden',
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: offset,
        left: '0',
        right: '0',
        height: height,
        backgroundColor: 'currentColor'
      }
    } : {
      overflow: 'hidden',
      position: 'relative',
      '&:before, &:after': {
        transition: 'transform 0.8s ease-in-out',
        content: '""',
        position: 'absolute',
        bottom: offset,
        left: '0',
        right: '0',
        height: height,
        backgroundColor: 'currentColor'
      },
      '&:before': {
        opacity: 1,
        transform: 'translateX(0%)'
      },
      '&:after': {
        opacity: 1,
        transform: 'translateX(-300%)'
      },
      '&:hover, &.selected': {
        '&:after': {
          transform: 'translateX(0)',
          opacity: 1
        },
        '&:before': {
          transform: 'translateX(300%)',
          opacity: 1
        }
      }
    })
  })
}
