import React, { useRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { resolveLink } from '../helpers/resolveLink'
import theme from '../style/theme'
import { vw } from '../style/vw'
import Link from './Link'
import { useHoverCursor } from './Cursor'

const Button = ({ className, link, label, disabled = false }) => {
  const classes = useStyles()
  const buttonLink = resolveLink(link)
  const buttonRef = useRef()

  useHoverCursor(buttonRef, !disabled)

  return (
    <Link
      className={cn(className, classes.button, { disabled: disabled })}
      link={buttonLink}
      disabled={disabled}
      ref={buttonRef}
    >
      <span className={classes.buttonText}>{label}</span>
    </Link>
  )
}

const useStyles = createUseStyles({
  button: {
    display: 'inline-block',
    minWidth: 96,
    textDecoration: 'none',
    padding: 0,
    margin: 0,
    overflow: 'hidden',
    position: 'relative',
    cursor: 'pointer',
    border: [1, 'solid', theme.colors.primary],
    transition: 'opacity 0.3s ease-out',
    '&.disabled': {
      opacity: 0.2,
      cursor: 'not-allowed'
    }
  },
  buttonText: {
    textTransform: 'uppercase',
    display: 'block',
    lineHeight: 'calc(22/18)',
    textAlign: 'center',
    padding: [16],
    fontSize: vw(18),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(18, 'desktop')
    }
  }
}, { name: 'Button' })

export default Button
