import { createSlice } from '@reduxjs/toolkit'

const shopProductsSlice = createSlice({
  name: 'shopProducts',
  initialState: {
    allowSticky: true
  },
  reducers: {
    enableSticky: (state, action) => {
      state.allowSticky = true
    },
    disableSticky: (state, action) => {
      state.allowSticky = false
    }
  }
})

export const { enableSticky, disableSticky } = shopProductsSlice.actions

export const shouldAllowSticky = state => state.shopProducts.allowSticky

export default shopProductsSlice.reducer
