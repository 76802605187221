import React, { forwardRef, useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { useSelector, useDispatch } from 'react-redux'

import { vw } from '../style/vw'
import { span } from '../style/span'
import theme from '../style/theme'
import { quart, expo } from '../style/eases'
import { isCookieAccepted, acceptCookie } from '../redux/slices/cookie'
import { getCookieBannerCopy } from '../redux/slices/content'
import { ReactComponent as CloseIcon } from '../images/close.svg'
import { useHoverCursor } from './Cursor'

const CookiesBanner = forwardRef((props, ref) => {
  const classes = useStyles()
  const cookiesAccepted = useSelector(isCookieAccepted)
  const dispatch = useDispatch()
  const buttonRef = useRef()
  const show = cookiesAccepted === false
  const copy = useSelector(getCookieBannerCopy)

  useHoverCursor(buttonRef)

  const handleAccept = useCallback(() => {
    dispatch(acceptCookie())
  }, [])

  return (
    <div className={cn(classes.wrapper, { show })}>
      <div>{copy}</div>
      <button className={classes.button} onClick={handleAccept} aria-label='Accept Cookie' ref={buttonRef}>
        <CloseIcon className={classes.closeIcon} />
      </button>
    </div>
  )
})

const useStyles = createUseStyles({
  wrapper: {
    position: 'fixed',
    zIndex: theme.zIndex.cookieBanner,
    top: '100%',
    left: span(1),
    right: span(1),
    background: '#38524F',
    color: theme.colors.white,
    fontSize: vw(14),
    padding: [vw(8), vw(16)],
    visibility: 'hidden',
    transition: `visibility 1s, transform 1s ${quart.inOut}`,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      right: span(2, 'md'),
      width: 'auto',
      left: 'auto',
      maxWidth: span(16, 'md'),
      alignItems: 'center',
      fontSize: vw(14, 'desktop'),
      padding: [vw(12, 'desktop'), vw(24, 'desktop')]
    },
    '&.show': {
      visibility: 'inherit',
      transitionTimingFunction: expo.out,
      transitionDelay: '0.45s',
      transform: 'translate3d(0,-130%,0)',
      [theme.breakpoints.up('md')]: {
        transform: 'translate3d(0,-170%,0)'
      }
    }
  },
  text: {
    '& p': {
      display: 'inline'
    },
    '& em': {
      fontFamily: theme.fonts.austin,
      textTransform: 'none',
      fontStyle: 'normal',
      fontSize: '1.1em',
      letterSpacing: 1
    }
  },
  button: {
    flexShrink: 0,
    userSelect: 'none',
    cursor: 'pointer',
    appearance: 'none',
    outline: 'none',
    border: 'none',
    background: 'none',
    display: 'block',
    color: 'currentColor',
    marginLeft: vw(16),
    [theme.breakpoints.up('md')]: {
      marginLeft: vw(32, 'desktop')
    }
  },
  closeIcon: {
    width: 24,
    height: 24,
    [theme.breakpoints.up('md')]: {
      width: vw(24, 'desktop'),
      height: vw(24, 'desktop')
    }
  }
}, { name: 'CookiesBanner' })

export default CookiesBanner
