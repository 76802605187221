import React, { useMemo, useCallback, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import cn from 'classnames'
import range from 'lodash/range'
import {
  getPages,
  getPagesTotal,
  isPagesBusy,
  isPagesLoaded,
  getPagesQueryStringArguments
} from '../../redux/slices/content'
import { pagesLoadMoreActionCreator } from '../../redux/slices/content/pages'

import { PAGE_SIZE } from '../../redux/middlewares/content/pages'
import List from '../List'
import Tile from '../List/Tile'
import { resolveInternalLink } from '../../helpers/resolveLink'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import { vw } from '../../style/vw'
import { span, sliceMarginSpan } from '../../style/span'
import ColorContext from '../ColorContext'

const PageListSlice = ({ className }) => {
  const borderColor = useContext(ColorContext)
  const classes = useStyles({ borderColor })
  const pages = useSelector(getPages)
  const total = useSelector(getPagesTotal)
  const loaded = useSelector(isPagesLoaded)
  const { page: index } = useSelector(getPagesQueryStringArguments)
  const busy = useSelector(isPagesBusy)
  const dispatch = useDispatch()
  const loadMore = useCallback(() => {
    dispatch(pagesLoadMoreActionCreator())
  }, [])

  const pageTiles = useMemo(() => {
    const expectedTiles = PAGE_SIZE * (index + 1)
    var tiles = []
    if (pages) {
      tiles = pages.map((page, i) => {
        const { id, title, image, subtitle } = page
        return {
          id, title, image, subtitle, borderColor, link: resolveInternalLink(page)
        }
      })
    }
    // if the pages have not loaded or it is busy getting the pages, then show the placeholders
    if (busy || !loaded) {
      const currentPageLength = (pages ? pages.length : 0)
      tiles = [
        ...tiles,
        ...range(expectedTiles - currentPageLength).map((x, i) => ({ placeholder: true }))
      ]
    }
    return tiles
  }, [index, pages])

  const hasMore = !pages || total > pages.length

  return (
    <List>
      {pageTiles.map(({ id, title, image, subtitle, link, borderColor, placeholder }, i) => (
        <Tile
          key={id || i}
          id={id}
          title={title}
          image={image}
          subtitle={subtitle}
          link={link}
          borderColor={borderColor}
          placeholder={placeholder}
          className={cn({ [classes.lastTile]: hasMore && i === pageTiles.length - 1 })}
        />
      ))}
      {hasMore && (
        <div className={classes.footer}>
          <button disabled={busy} className={classes.link} onClick={loadMore}>Load More</button>
        </div>
      )}
    </List>
  )
}

const useStyles = createUseStyles({
  footer: {
    textAlign: 'center',
    borderBottom: [1, 'solid'],
    borderColor: ({ borderColor }) => borderColor
  },
  link: {
    margin: [span(1), span(1), span(sliceMarginSpan, 'md')],
    display: 'inline-block',
    textTransform: 'uppercase',
    textDecoration: 'none',
    position: 'relative',
    padding: [vw(8), 0],
    ...theme.link(),
    [theme.breakpoints.up('md')]: {
      padding: [vw(8, 'desktop'), 0],
      margin: [span(1, 'md'), span(1, 'md'), span(sliceMarginSpan, 'md')]
    }
  },
  lastTile: {
    borderBottom: 'none'
  }
})

export default PageListSlice
