import React from 'react'
import { useSelector } from 'react-redux'
import { getProducts } from '../../redux/slices/content'
import Slider from '../Slider'
import Tile from '../Slider/Tile'
import { resolveLink } from '../../helpers/resolveLink'
import get from 'lodash/get'

const ProductSlider = ({ className, slice }) => {
  const { title, link } = slice
  const products = useSelector(getProducts)

  if (!products) return null

  return (
    <Slider title={title} link={resolveLink(get(link, [0]))}>
      {products.map(({ image, title, link, coming_soon: comingSoon }, i) => (
        <Tile key={i} image={image} title={title} subTitle={comingSoon && 'Coming Soon'} link={resolveLink(get(link, [0]))} />
      ))}
    </Slider>
  )
}

export default ProductSlider
