import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import { getMenuImage } from '../redux/slices/content'
import ResponsiveImage from './ResponsiveImage'
import Menu from './Menu'
import theme from '../style/theme'
import useBodyScrollLock from '../hooks/useBodyScrollLock'
import { isMenuOpen } from '../redux/slices/layout'
import { vw } from '../style/vw'
import { span } from '../style/span'
import { quart } from '../style/eases'
import { useInView } from 'react-intersection-observer'

function MenuDialog ({ showPartners }) {
  const classes = useStyles()
  const backgroundImage = useSelector(getMenuImage)
  const open = useSelector(isMenuOpen)
  const ref = useBodyScrollLock(open)
  const [inViewRef, inView] = useInView({ threshold: 0.5, triggerOnce: true })

  return (
    <div className={cn(classes.container, { open })} ref={inViewRef}>
      <ResponsiveImage {...backgroundImage} className={classes.backgroundImage} />
      <div className={classes.innerContainer} ref={ref}>
        <div className={classes.menuContainer}>
          <Menu reverse animate={inView} />
        </div>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    color: theme.colors.white,
    backgroundColor: theme.colors.black,
    transform: 'translate(0, -100%)',
    transition: `transform 1s ${quart.out}`,
    zIndex: theme.zIndex.menu,
    '&.open': {
      transform: 'translate(0, 0)'
    }
  },
  innerContainer: {
    overflow: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    [theme.breakpoints.up('md')]: {
      padding: 0,
      display: 'flex',
      alignItems: 'center'
    }
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    pointerEvents: 'none'
  },
  menuContainer: {
    margin: [vw(120), span(1), vw(20)],
    [theme.breakpoints.up('md')]: {
      margin: [vw(40, 'desktop'), [span(3, 'md')], 0],
      flexGrow: 1
    }
  }
}, { name: 'MenuDialog' })

export default MenuDialog
