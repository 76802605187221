import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { span } from '../../style/span'
import theme from '../../style/theme'
import ResponsiveImage from '../ResponsiveImage'
import RichText from '../RichText'
import { LinkWithCursorHoverEffect } from '../Link'
import { vw } from '../../style/vw'
import Placeholder from '../Placeholder'
import isEmpty from 'lodash/isEmpty'
import detectIt from 'detect-it'
import ColorContext from '../ColorContext'

const IMAGE_ASPECT = 618 / 450

const Tile = (props) => {
  const { className, image, title, copy, link, showLink, children, placeholder, subtitle } = props
  const { borderColor } = useContext(ColorContext)
  const classes = useStyles({ borderColor })

  if (placeholder) {
    return (
      <article className={cn(classes.tile, className)}>
        <Placeholder className={classes.image} type='image' aspect={IMAGE_ASPECT} />
        <div className={classes.content}>
          <Placeholder className={cn(classes.title, classes.placeholderText)} width='80%' />
          <div className={classes.copy}>
            <Placeholder className={classes.placeholderText} type='multiline' width='100%' lines={2} />
          </div>
        </div>
      </article>
    )
  }

  return (
    <article className={cn(classes.tile, className)}>
      <LinkWithCursorHoverEffect className={cn(classes.image, { withLink: !isEmpty(link) })} nonLinkTag='div' link={link}>
        <ResponsiveImage {...image} aspect={618 / 450} />
      </LinkWithCursorHoverEffect>
      <div className={classes.content}>
        <LinkWithCursorHoverEffect nonLinkTag='span' link={link} className={classes.title}>
          <h3 className={classes.titleText}>{title}</h3>
        </LinkWithCursorHoverEffect>
        {subtitle && <span className={classes.subtitle}>{subtitle}</span>}
        {copy && <RichText content={copy.text} className={classes.copy} />}
        {showLink && link && <LinkWithCursorHoverEffect className={classes.link} link={link} />}
        {children}
      </div>
    </article>
  )
}

const useStyles = createUseStyles({
  tile: {
    padding: [vw(40), span(1)],
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: 'inherit',
    borderColor: ({ borderColor }) => borderColor,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      padding: span(1, 'md')
    },
    '&:first-child': {
      paddingTop: 0
    }
  },
  image: {
    flexShrink: 0,
    flexGrow: 0,
    display: 'block',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: span(11, 'md')
    },
    ...(detectIt.primaryInput === 'touch' ? {} : {
      '&.withLink': {
        '& img': {
          transition: 'transform 1.6s ease-in-out'
        },
        '&:hover img': {
          transform: 'scale(1.1)'
        }
      }
    })
  },
  content: {
    padding: [vw(40), 0, 0],
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    display: 'flex',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: [vw(24, 'desktop'), span(2, 'md')]
    }
  },
  title: {
    textDecoration: 'none',
    fontSize: vw(36),
    marginBottom: vw(16),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(72, 'desktop'),
      marginBottom: vw(24, 'desktop')
    }
  },
  titleText: {
    margin: 0
  },
  link: {
    ...theme.link(),
    textDecoration: 'none',
    textTransform: 'uppercase'
  },
  copy: {
    width: '100%',
    marginBottom: vw(30),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(30, 'desktop')
    }
  },
  placeholderText: {
    marginBottom: vw(16),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(30, 'desktop')
    }
  },
  subtitle: {
    fontSize: vw(34),
    fontFamily: theme.fonts.script,
    textAlign: 'center',
    textTransform: 'lowercase',
    transform: 'rotate(-4.5deg)',
    [theme.breakpoints.up('md')]: {
      fontSize: vw(34, 'desktop')
    }
  }
}, { name: 'Tile' })

export default Tile
